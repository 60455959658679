import React from 'react'
import './CheckBox.scss'

export default function CheckBox(props) {
    return (
        <>
        <label style={{cursor:'pointer'}}>
        <input type={props.type} defaultChecked={props.checked}  onClick={props.onClick} onChange={props.onChange} name="radio"  />
        {props.children}
        </label>
        
        </>
    )
}