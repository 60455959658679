let en= {
home : "Home",
about : "About",
partnerResources : "Partner Resources",
blog : "Blog",
drivers : "Drivers",
restaurants : "Restaurants",
serviceShop : "Service Shop",
letsGetStarted : "Let's get started",
becomeADriver : "Become a driver",
termsAndConditions : "Terms & Conditions",
readTandC : "Read T&C",
disclaimer : "Disclaimer",
next : "Next",
back : "Back",
personalDetails : "Personal Details",
professionalDetails:"Professional Details",
personalDetailsInfo : "Please fill in the required information below correctly",
typeOfPartner : "Type of partner",
typeOfPartneHints : "e.g Driver, Restuarant or Shop Owner",
chooseType : "Choose Type",
fullName : "Full Name"  ,
fullNameHints : "e.g John Doe",
fullNamePlaceholder : "Enter your full name",
emailAddress : "Email Address",
emailAddressHints : "e.g johndoe@example.com",
emailPlaceholder : "Enter you email address",
phoneNumber : "Phone Number",
phoneNumberHints:"e.g. +856 18738271837",
phoneNumberPlaceholder : "Enter your phone number",
currentAddress : "Current Address",
currentAddressHints : "e.g Flat, Street, City, Country",
currentAddressPlaceholder:"Enter your current address",
passportPhoto : "Passport Photo",
dragAndDrop : "Drag & Drop files here",
nricPlaceholder : "NRIC/Work permit (Front & Back)",
dlPlaceholder : "Driver License (Front & Back)",
uplaod : "Upload",
dashboard : "Dashboard",
onlineEnquiry : "Online Enquiry",
payments : "Payments",
superAdmin : "Super Admin",
login : "Login",
logout : "Logout",
appuserrequest:"App User Requests",
partnerRequests : "Partner Requests",
appliedOn : "AppliedOn",
searchDriver : "Search Driver",
addNewDriver : "Add New Driver",
shortlisted : "Shortlisted",
inReview : "In Review",
approved : "Approved",
approve : "Approve",
cancel : "Cancel",
disable : "Disable",
delete:"Delete",
edit : "Edit",
moveToReview : "Move to review",
assignManager : "Assign manager",
accountManagers : "Account manager",
promote : "Promote",
name:"Name",
phone:'Phone',
email:"Email",
appliedon:"Applied On",
searchname:"Search Name",
assignManagerWarn:"Please assign manager first then promote.",
driverDelete:"Driver deleted Successfully.",
moveToInReview:"Driver moved to In Review Successfully.",
contactdetails:"Contact Details",
managerAssigned:"Manager Assigned ",
documentUploaded:"Documents Uploaded",
use:"Use",
driverLoginCredential :"as driver’s login credentials.",
driverLicense:"Driver License",
nricWorkPermit: "NRIC/Work permit",
partner:'Partner',
database:'Database',
requests:"Requests",
prev:"Prev",
first:"First",
last:"Last",
step:'Step',
hugeleap:'This a huge leap of trust that is extended from both sides',
just:"JUST",
signupprocess:"We are excited to learn of your interest in driving with us!  Fill in this form and we will reach out to you to help with the sign-up process",
completeonboarding:"This form is for completing your onboarding online as a driver.",
documentsRequired:"If you are uncertain about the documents required, then please read the list of documents via this link",
duringThisPeriod:" During this period, you may expect a delay in your onboarding process while we verify your documents. This ensures that the experience for driver-partners and customers on Gojek remains optimal as we seek to better regulate supply and demand in the market.We appreciate your patience during this period. ",
agreeTandC:"I agree to the terms & conditions",
passportPicError:"Please upload atleast passport photo.",
editDriver:"Edit Driver",
addAdmin:"Add Admin",
addManager:"Add Manager",
admins:"Admins",
accManager:"Acc Manager",
addNewAdmin:"Add New Admin",
addNewManager:"Add New Manager",
adminAddSuccess:"Admin Added Successfully",
managerAddSuccess:"Manager Added Successfully",
chooseImage:"Choose Image",
save:"Save",
yourProfile:"Your Profile",
contactDetails:"Contact Details",
assignedDrivers:"Assigned Drivers",
editManagerProfile:"Edit Manager Profile",
editAdminProfile:"Edit Admin Profile",
selectTandC:"Please select terms & conditions.",
welcomeToJust:"Welcome to JUST",
startyourjourney:"Start your journey with us now.",
promotedSuccess:"Driver Promoted SuccessFully.",
coreValues:"We Are Proud Of Our Core Values",
rideDeliverPay:"You ride, deliver and we pay you",
slideMessage2:"Partner with us to service the masses! Become a driver and earn with the best in the business",
learnMore:"Learn More",
documentWarn:"Please Upload all the Required Documents",
orders:"Orders",
order:"Order Id :-",
justbuy:"Just Buy",
justsend:"Just Send",
useremail:"User Email",
status:"Status",
orderstatus:"Order Status :-",
amount:"Amount",
ordersdetails:"Order Details",
pickndrop:"Pickup & Drop Locations :-",
packagedetails:"Package Details",
totalpackage:"Total Packages",
totalpayable:"Total Payable Amount",
tiprider:"Tip Rider",
promocode:"Promo Code",
deliveredby:"Delivered By",
sender:"Sender ↑",
tmode:"Transaction Mode",
recipient:"Reciepient ↓",
products:"Products",
brand:"Brand",
productdetails:"Product Details",
pleaseenterotp:"Please Enter OTP",
codesent:"A code has been sent to ",
didntgetcode:"Didn't get this code?",
resendit:"Resend it",
clear:"Clear",
verify:"Verify",
OnHold:"OnHold",
partnerDatabse:"Partner Database",
paymentsDatabase:"Payments Database",
ordersDatabase:"Order Database",
productsDatabase:"Products Database",
partnerDatabase:"Partner Database",
Notifications:"Notifications",
Clearallnotifications:"Clear all notifications",
SuperAdminProfile:"Super Admin Profile",

};
export default en;