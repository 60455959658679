import React, {useState } from 'react'
import request from '../../assets/images/panel/request.png';
import pickup from '../../assets/images/panel/pickup1.png';
import drop from '../../assets/images/panel/drop2.png';
import { Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import './map.scss'

const MapContainer =(props)=> {

  const[showInfoWindow,setShowInfoWindow]=useState(false)
    const[selectedPlacename,setPlaceName]=useState('')
    const[location,setLocation]=useState('')
    const[activeMarker,setInfowindowPosition]=useState({})
    // console.log(props.driverdata)
    let driverData = props.mappage==="products"? props.driverdata:props.driverdata.package_details
    const triangleCoords = [
      {
        lat: driverData.pickup_location&&driverData.pickup_location.latitude ? driverData.pickup_location.latitude : 28.633074,
        lng: driverData.pickup_location&&driverData.pickup_location.longitude ? driverData.pickup_location.longitude : 77.219543
      },
      {
        lat: driverData.pickup_location&&driverData.drop_location.latitude ? driverData.drop_location.latitude : 28.633074,
        lng: driverData.pickup_location&&driverData.drop_location.longitude ? driverData.drop_location.longitude : 77.219543
      }
    ];
    const containerStyle = {
      position: "absolute",
      width: "100%",
      height: "60%",
      marginLeft:"-2vw",
      marginTop:-4,
      /* box-shadow: rgba(0, 0, 0, 0.14) 0.6px 0.6px 10px; */
      /* border-radius: 14px; */
      padding: "2%",
      background: "#f8f8f8",
    }

    const style = {
      width: '100%',
      height: '72%',
      position: "relative",
      boxShadow: "0.6px 0.6px 10px rgba(0, 0, 0, 0.14)",
      borderRadius: "14px",
      outline:"none"
    }

    function handleMapReady(mapProps, map) {
      console.log(map)
      calculateAndDisplayRoute(map);
    }
  
    const calculateAndDisplayRoute=(map)=>{
      const directionsService = new window.google.maps.DirectionsService();
      const directionsDisplay = new window.google.maps.DirectionsRenderer();
      directionsDisplay.setMap(map);
  
  
      const waypoints = triangleCoords.map(item =>{
        return{
          location: {lat: item.lat, lng:item.lng},
          stopover: true
        }
      })
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;
  
      directionsService.route({
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          // directionsDisplay.setDirections(response);
          new window.google.maps.DirectionsRenderer({
            map: map,
            directions: response,
            suppressMarkers: true,
          });
          var leg = response.routes[0].legs[0];
          const start=new window.google.maps.Marker({
            position: leg.start_location,
            map: map,
            icon: markers[0].icon,
            title: "Pickup Location",
          });
          start.addListener('click', function() {
            setShowInfoWindow(true)
            setPlaceName(markers[0].name)
            setLocation("Pickup location")
            setInfowindowPosition(markers[0].position)
          });

         const end=new window.google.maps.Marker({
            position: leg.end_location,
            map: map,
            icon: markers[1].icon,
            title: "Drop location",
          });
          end.addListener('click', function() {
            setShowInfoWindow(true)
            setPlaceName(markers[1].name)
            setLocation("Drop location")
            setInfowindowPosition(leg.end_location)
          });
     
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      });
    }

    const onMapClicked = () => {
      setShowInfoWindow(false)
    };

    let markers=driverData.pickup_location&&driverData.pickup_location.address?[
      {
      id:'key1',
      title:driverData.pickup_location.address,
      name:driverData.pickup_location.address,
      position:{
        lat: driverData.pickup_location.latitude,
        lng: driverData.pickup_location.longitude 
               },
        icon:{
                url: pickup,
                origin: new window.google.maps.Point(0,0),
                anchor: new window.google.maps.Point(12,12),
                scaledSize: new window.google.maps.Size(40,40)
              },
        visible:true
    },
    {
      id:'key2',
      title:driverData.drop_location.address,
      name:driverData.drop_location.address,
      position:{
        lat: driverData.drop_location.latitude,
        lng: driverData.drop_location.longitude 
      },
      icon:{
              url: drop,
              origin: new window.google.maps.Point(0,0),
              anchor: new window.google.maps.Point(12,12),
              scaledSize: new window.google.maps.Size(40,40)
            },
    visible:true
    }
    ]:[]

    return (
     props.mappage==="products"?
     <Map google={window.google}
     zoom={10}
     containerStyle={
       containerStyle
     }
     onClick={()=>onMapClicked()}
     style={style}
     initialCenter={{
       lat: driverData.location&&driverData.location.latitude ? driverData.location.latitude : 28.633074,
       lng: driverData.location&&driverData.location.longitude ? driverData.location.longitude : 77.219543
     }}
   >
    { driverData.location?
        <Marker
        title={driverData.location&&driverData.location.address?"Seller Address":"No Data"}
        name={driverData.location&&driverData.location.address?driverData.location.address:""}
        position={
         {lat: driverData.location&&driverData.location.latitude ? driverData.location.latitude : 28.633074,
         lng: driverData.location&&driverData.location.longitude ? driverData.location.longitude : 77.219543
       }}
        onClick={()=>setShowInfoWindow(true)}
        opacity={20}
        zIndex={200}
        visible={true}
        />:''}
        
         { driverData.location?
         <InfoWindow
          position={
           {lat: driverData.location&&driverData.location.latitude ? driverData.location.latitude : 28.633074,
             lng:driverData.location&&driverData.location.longitude ? driverData.location.longitude : 77.219543
            }}
        onClose={()=>setShowInfoWindow(false)}
        visible={driverData.location&&showInfoWindow}>
        <div>
          <h2>{"Seller Location"}</h2>
          <div style={{display:"flex",alignItems:"center"}}>
          <img style={{width:50,height:50,marginRight:10,borderRadius:10}} alt="" src={props.driverdata.created_by&&props.driverdata.created_by.profile_image_url?props.driverdata.created_by.profile_image_url:request}></img>
          <div>
          <p ><lable style={{fontWeight:"bold"}}>Product Id :- </lable>{props.driverdata.id}</p>
          <p ><lable style={{fontWeight:"bold"}}>Seller :- </lable>{props.driverdata.created_by.full_name}</p>
          </div>
          </div>
          <div style={{display:"flex",marginTop:12}}>
          <img style={{width:50,height:50,marginRight:10,borderRadius:10}} alt="" src={props.driverdata.images[0]&&props.driverdata.images[0].image_url?props.driverdata.images[0].image_url:request}></img>
          <p ><lable style={{fontWeight:"bold"}}>Product Name :- </lable>{props.driverdata.subtitle ? props.driverdata.subtitle:'No Data'}</p>
          </div>
          <p>{driverData.location.address}</p>
        </div>
      </InfoWindow>:''
    }
   </Map>
     :
     <Map google={window.google}
     zoom={10}
     containerStyle={
       containerStyle
     }
     onClick={()=>onMapClicked()}
     style={style}
     initialCenter={{
       lat: driverData.pickup_location.latitude ? driverData.pickup_location.latitude : 28.633074,
       lng: driverData.pickup_location.longitude ? driverData.pickup_location.longitude : 77.219543
     }}
     onReady={handleMapReady}
   >
     <InfoWindow
       position={activeMarker}
       onClose={()=>setShowInfoWindow(false)}
       visible={showInfoWindow}>
       <div>
         <h2>{location}</h2>
         <div style={{display:"flex"}}>
         <img style={{width:50,marginRight:10}} alt="" src={props.delevery_details && props.delevery_details.profile_image ? props.delevery_details.profile_image : request}></img>
         <p ><lable style={{fontWeight:"bold"}}>Order No. :- </lable>{props.driverdata.order_id}</p>
         </div>
         <p><lable style={{fontWeight:"bold"}}>Date : </lable>{props.driverdata.package_details.pickup_datetime}</p>
         <p><lable style={{fontWeight:"bold"}}>Slot : </lable>{props.driverdata.package_details.slot}</p>
         <p>{selectedPlacename}</p>
       </div>
     </InfoWindow>
   </Map>
    );
  }


export default GoogleApiWrapper({
  apiKey: ("AIzaSyCRoMv9EmUvT8kSsOznL5HzztQ3wqJl9k0")
})(MapContainer)

