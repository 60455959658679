import React from 'react'
import './Searchbar.scss'
import translate  from '../../utils/locale/language'

const Searchbar = ({keyword,setKeyword}) => {
  console.log(keyword)
  return (
    <div className="searchbar"  >
      <input className="search-name" placeholder={translate("searchname")} value={keyword}  onChange={(e) => setKeyword(e.target.value)}/>
      <i className="fa fa-search"></i>
    </div>
  )
}

export default Searchbar