import React from 'react'
import Lottie from 'react-lottie';
import notFound from '../../assets/lottie/notFound.json';
import './lottie.scss'
export default function NotFound(props)
{
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: notFound,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
return(
 <div className="lottieFile"><Lottie  options={defaultOptions} height={300} width={250} /></div>   
)
}