let lo={
home : "ເຮືອນ",
onlineEnquiry : "ການສອບຖາມທາງອິນເຕີເນັດ",
name : "ຊື່",
just:"ພຽງແຕ່",
contactdetails:"ຕິດຕໍ່ ລາຍລະອຽດ",
managerAssigned:"ຜູ້​ຈັດ​ການ ມອບ ໝາຍ",
about : "ກ່ຽວກັບ",
partnerResources : "ຊັບພະຍາກອນຄູ່ຮ່ວມງານ",
blog : "ບລັອກ",
drivers : "ຄົນຂັບລົດ",
restaurants : "ຮ້ານອາຫານ",
serviceShop : "ຮ້ານບໍລິການ",
letsGetStarted : "ໃຫ້ເລີ່ມຕົ້ນ",
becomeADriver : "ກາຍເປັນຄົນຂັບລົດ",
coreValues:"ພວກເຮົາພູມໃຈໃນຄຸນຄ່າຫຼັກຂອງພວກເຮົາ",
rideDeliverPay:"ທ່ານຂີ່, ຈັດສົ່ງແລະພວກເຮົາຈ່າຍເງິນໃຫ້ທ່ານ",
slideMessage2:"ຮ່ວມມືກັບພວກເຮົາເພື່ອຮັບໃຊ້ມວນຊົນ! ກາຍເປັນຜູ້ຂັບຂີ່ແລະມີລາຍໄດ້ດີທີ່ສຸດໃນທຸລະກິດ",
learnMore:"ຮຽນຮູ້ເພີ່ມເຕີມ",
termsAndConditions : "ຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂ",
readTandC : "ອ່ານ T&C",
disclaimer : "ປະຕິເສດ",
next : "ຕໍ່ໄປ",
back : "ກັບໄປ",
personalDetails : "ຂໍ້​ມູນ​ສ່ວນ​ຕົວ",
professionalDetails:"ລາຍລະອຽດດ້ານວິຊາຊີບ",
personalDetailsInfo : "ກະລຸນາຕື່ມຂໍ້ມູນທີ່ຕ້ອງການຢູ່ດ້ານລຸ່ມຢ່າງຖືກຕ້ອງ",
typeOfPartner : "ປະເພດຂອງຄູ່ຮ່ວມງານ",
typeOfPartneHints : "ຕົວຢ່າງ: ຄົນຂັບ, ການພັກຜ່ອນຫຼືເຈົ້າຂອງຮ້ານ",
chooseType : "ເລືອກປະເພດ",
fullName : "ຊື່​ເຕັມ"  ,
fullNameHints : "ເຊັ່ນ: John Doe",
fullNamePlaceholder : "ໃສ່ຊື່ເຕັມຂອງທ່ານ",
emailAddress : "ທີ່​ຢູ່​ອີ​ເມວ",
emailAddressHints : "ຕົວຢ່າງ johndoe@example.com",
emailPlaceholder : "ກະລຸນາໃສ່ທີ່ຢູ່ອີເມວຂອງທ່ານ",
phoneNumber : "ເບີ​ໂທລະ​ສັບ",
phoneNumberHints:"ຕົວຢ່າງ +856 18738271837",
phoneNumberPlaceholder : "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງທ່ານ",
currentAddress : "ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ",
currentAddressHints : "ຕົວຢ່າງ Flat, Street, City, Country",
currentAddressPlaceholder:"ໃສ່ທີ່ຢູ່ປະຈຸບັນຂອງທ່ານ",
passportPhoto : "ຮູບພາບ ໜັງ ສືຜ່ານແດນ",
OnHold: "ລໍຖ້າ",
dashboard :"ແຜງໜ້າປັດ",
payments:"ການຈ່າຍເງິນ",
orders:"ຄໍາສັ່ງ",
superAdmin:"ຜູ້ບໍລິຫານສູງສຸດ",
products:"ຜະ​ລິດ​ຕະ​ພັນ",
logout:"ອອກ​ຈາກ​ລະ​ບົບ",
appuserrequest:"ຄໍາ​ຮ້ອງ​ສະ​ຫມັກ​ຜູ້​ໃຊ້​",
dragAndDrop:"ລາກ ແລະວາງໄຟລ໌ທີ່ນີ້",
nricPlaceholder:"ບໍ່ມີ/ໃບອະນຸຍາດເຮັດວຽກ (ໜ້າ ແລະ ຫຼັງ)",
dlPlaceholder:"ໃບຂັບຂີ່ (ໜ້າ ແລະ ຫຼັງ)",
uplaod :"ອັບໂຫຼດ",
login : "ເຂົ້າ​ສູ່​ລະ​ບົບ",
partnerRequests:"ການຮ້ອງຂໍຄູ່ຮ່ວມງານ",
appliedOn:"ນຳໃຊ້ແລ້ວ",
searchname:"ຊອກຫາຊື່",
assignManagerWarn:"ກະລຸນາມອບໝາຍຜູ້ຈັດການກ່ອນ ຈາກນັ້ນສົ່ງເສີມ.",
driverDelete:"ລຶບໄດເວີສຳເລັດແລ້ວ.",
moveToInReview:"ຍ້າຍຄົນຂັບໄປຢູ່ໃນການກວດສອບສຳເລັດແລ້ວ.",
documentUploaded:"ອັບໂຫຼດເອກະສານ",
use:"ໃຊ້",
driverLoginCredential:"ເປັນຂໍ້ມູນການເຂົ້າສູ່ລະບົບຂອງຜູ້ຂັບຂີ່.",
driverLicense:"ໃບຂັບຂີ່",
nricWorkPermit:"ບໍ່ມີ/ໃບອະນຸຍາດເຮັດວຽກ",
partner:"ຄູ່ຮ່ວມງານ",
database:"ຖານຂໍ້ມູນ",
requests:"ການຮ້ອງຂໍ",
prev:"ກ່ອນໜ້າ",
first:"ທໍາອິດ",
last:"ສຸດທ້າຍ",
step:"ບາດກ້າວ",
hugeleap:"ນີ້​ແມ່ນ​ການ​ກ້າວ​ກະ​ໂດດ​ຢ່າງ​ໃຫຍ່​ຂອງ​ຄວາມ​ໄວ້​ວາງ​ໃຈ​ທີ່​ຂະ​ຫຍາຍ​ອອກ​ຈາກ​ທັງ​ສອງ​ຝ່າຍ",
signupprocess:"ພວກເຮົາຕື່ນເຕັ້ນທີ່ຈະຮຽນຮູ້ຄວາມສົນໃຈຂອງທ່ານໃນການຂັບລົດກັບພວກເຮົາ! ຕື່ມຂໍ້ມູນໃສ່ໃນແບບຟອມນີ້ ແລະພວກເຮົາຈະເອື້ອມອອກໄປຫາທ່ານເພື່ອຊ່ວຍໃນຂັ້ນຕອນການລົງທະບຽນ",
completeonboarding:"ແບບຟອມນີ້ແມ່ນສໍາລັບການສໍາເລັດການ onboarding ຂອງທ່ານອອນໄລນ໌ເປັນຜູ້ຂັບຂີ່.",
documentsRequired:"ຖ້າ​ຫາກ​ວ່າ​ທ່ານ​ບໍ່​ແນ່​ນອນ​ກ່ຽວ​ກັບ​ເອ​ກະ​ສານ​ທີ່​ຕ້ອງ​ການ​, ກະ​ລຸ​ນາ​ອ່ານ​ລາຍ​ຊື່​ຂອງ​ເອ​ກະ​ສານ​ໂດຍ​ຜ່ານ​ການ​ເຊື່ອມ​ຕໍ່​ນີ້",
duringThisPeriod:"ໃນລະຫວ່າງໄລຍະເວລານີ້, ທ່ານອາດຄາດຫວັງວ່າຈະມີການຊັກຊ້າໃນຂະບວນການເລີ່ມຕົ້ນຂອງທ່ານໃນຂະນະທີ່ພວກເຮົາກວດສອບເອກະສານຂອງທ່ານ. ນີ້ຮັບປະກັນວ່າປະສົບການສໍາລັບຄູ່ຮ່ວມຂັບລົດແລະລູກຄ້າໃນ Gojek ຍັງຄົງເປັນທີ່ດີທີ່ສຸດຍ້ອນວ່າພວກເຮົາຊອກຫາການຄວບຄຸມການສະຫນອງແລະຄວາມຕ້ອງການໃນຕະຫຼາດທີ່ດີກວ່າ. ພວກເຮົາຂໍຂອບໃຈກັບຄວາມອົດທົນຂອງທ່ານໃນລະຫວ່າງໄລຍະເວລານີ້.",
agreeTandC:"ຂ້ອຍຕົກລົງເຫັນດີກັບຂໍ້ກໍານົດ & ເງື່ອນໄຂ",
passportPicError:"ກະລຸນາອັບໂຫຼດຮູບໜັງສືຜ່ານແດນຢ່າງໜ້ອຍ.",
editDriver:"ແກ້ໄຂໄດເວີ",
addAdmin:"ເພີ່ມຜູ້ເບິ່ງແຍງລະບົບ",
addManager:"ຜູ້ຈັດການໂຄສະນາ",
admins:"ຜູ້ບໍລິຫານ",
accManager:"ຜູ້ຈັດການບັນຊີ",
addNewAdmin:"ເພີ່ມຜູ້ເບິ່ງແຍງລະບົບໃໝ່",
addNewManager:"ເພີ່ມຜູ້ຈັດການໃຫມ່",
adminAddSuccess:"ເພີ່ມຜູ້ເບິ່ງແຍງລະບົບສຳເລັດແລ້ວ",
managerAddSuccess:"ເພີ່ມຜູ້ຈັດການສຳເລັດແລ້ວ",
chooseImage:"ເລືອກຮູບພາບ",
save:"ບັນທຶກ",
yourProfile:"ໂປຣໄຟລ໌ຂອງເຈົ້າ",
assignedDrivers:"ຄົນຂັບລົດທີ່ໄດ້ຮັບມອບໝາຍ",
editManagerProfile:"ແກ້ໄຂໂປຣໄຟລ໌ຜູ້ຈັດການ",
editAdminProfile:"ແກ້ໄຂໂປຣໄຟລ໌ຜູ້ເບິ່ງແຍງລະບົບ",
selectTandC:"ກະລຸນາເລືອກເງື່ອນໄຂ ແລະເງື່ອນໄຂ.",
welcomeToJust:"ຍິນດີຕ້ອນຮັບພຽງແຕ່",
startyourjourney:"ເລີ່ມການເດີນທາງຂອງເຈົ້າກັບພວກເຮົາດຽວນີ້.",
promotedSuccess:"ຜູ້ຂັບຂີ່ໄດ້ຮັບການສົ່ງເສີມຢ່າງສໍາເລັດຜົນ.",
documentWarn:"ກະລຸນາອັບໂຫລດເອກະສານທີ່ຕ້ອງການທັງໝົດ",
order:"ໝາຍເລກການສັ່ງຊື້ :-",
justbuy:"ພຽງແຕ່ຊື້",
justsend:"ພຽງແຕ່ສົ່ງ",
useremail:"ອີເມວຜູ້ໃຊ້",
status:"ສະຖານະ",
orderstatus:"ສະ​ຖາ​ນະ​ການ​ຄໍາ​ສັ່ງ​: -",
amount:"ຈໍາ​ນວນ",
ordersdetails:"ລາຍລະອຽດການສັ່ງຊື້",
pickndrop:"ສະຖານທີ່ຮັບ-ສົ່ງ :-",
packagedetails:"ລາຍລະອຽດແພັກເກດ",
totalpackage:"ແພັກເກດທັງໝົດ",
totalpayable:"ຈຳນວນທີ່ຕ້ອງຈ່າຍທັງໝົດ",
tiprider:"ທິບປິເດີ",
promocode:"ລະຫັດໂປຣໂມຊັນ",
deliveredby:"ຈັດສົ່ງໂດຍ",
sender:"ຜູ້ສົ່ງ",
tmode:"ຮູບແບບການເຮັດທຸລະກໍາ",
recipient:"ຜູ້ຮັບ ↓",
brand:"ຍີ່ຫໍ້",
productdetails:"ລາຍລະອຽດຜະລິດຕະພັນ",
pleaseenterotp:"ກະລຸນາໃສ່ ອທປ",
codesent:"ລະຫັດຖືກສົ່ງໄປຫາ",
didntgetcode:"ບໍ່ໄດ້ຮັບລະຫັດນີ້ບໍ?",
resendit:"ສົ່ງຄືນ",
clear:"ຈະແຈ້ງ",
verify:"ກວດ​ສອບ",
email:"ອີເມວ",
phone:"ໂທລະສັບ",
appliedon:"ນຳໃຊ້ແລ້ວ",
shortlisted:"ລາຍຊື່ຄັດເລືອກ",
inReview:"ໃນການທົບທວນຄືນ",
approved:"ອະນຸມັດ",
contactDetails:"ຕິດຕໍ່ ລາຍລະອຽດ",
partnerDatabse:"ຖານຂໍ້ມູນຄູ່ຮ່ວມງານ",
paymentsDatabase:"ຖານຂໍ້ມູນການຈ່າຍເງິນ",
ordersDatabase:"ຖານຂໍ້ມູນຄໍາສັ່ງ",
productsDatabase:"ຖານຂໍ້ມູນຜະລິດຕະພັນ",
partnerDatabase:"ຖານຂໍ້ມູນຄູ່ຮ່ວມງານ",
Notifications:"ການແຈ້ງເຕືອນ",
Clearallnotifications:"ລຶບລ້າງການແຈ້ງເຕືອນທັງໝົດ",
SuperAdminProfile:"ໂປຣໄຟລ໌ຜູ້ເບິ່ງແຍງຂັ້ນສູງ",
edit : "ແກ້ໄຂ",
delete:"ລຶບ",
disable : "ປິດໃຊ້ງານ",
cancel:"ຍົກເລີກ",
approve : "ອະນຸມັດ",

  };
  export default lo;