import React from 'react'
import './ProcessCard.scss'

const ProcessCard = ({ src, alt, title }) => {
    return (
        <div className='process-card'>
            <div className='process-img'>
                <img style={{ width: 200, height: 171 }} className='p-img' src={src} alt={alt} />
            </div>
            <p className='fill'>{title}</p>
            <p className='info'>Provide us with your personal and driving eperience. This would help us alot</p>
        </div>
    )
}

export default ProcessCard