
import React from "react";
import "./JustCard.scss";
export default function JustCard() {
  return (
    <div className="justBox">
      <div className="flexColumn">
       <div className="just">JUST</div>
        <div className="text_value_center">  
        <p className="title_login">Welcome Back</p>
     
        </div>
          {/* <p className="subTitle">Descriptions</p> */}
      </div>
    </div>
  );
}
