import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getToken = () => {
    // console.log(cookies.get('token'));
    // return cookies.get('token') || null;
    return sessionStorage.getItem('token') || null;
    
  }

  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    cookies.remove("token")
  }

  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    // let date = new Date();
    // cookies.set('token', token);
   
  }

  export const setDriverSelection = (id) => {
    sessionStorage.setItem('id',id);
  }

  export const getDriverSelection = () => {
    return parseInt(sessionStorage.getItem('id'));
  }

  export const setPageSelection=(page_no)=>{
     sessionStorage.setItem('page_no',page_no);
  }

  export const getPageSelection=()=>{
    return parseInt(sessionStorage.getItem('page_no'));
  }

  export const removeDriverSession = () => {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('page_no');
  }

  export const removeUserKeySession = () => {
    sessionStorage.removeItem('key');
    sessionStorage.removeItem('chefToken');
    
    sessionStorage.removeItem('role');
    // sessionStorage.removeItem('user');
  }
   
  // set the token and user from the session storage
  export const setUserKeySession = (key) => {
    sessionStorage.setItem('key', key);
   
  }

    export const setFirebaseDeviceToken = (token) => {
    sessionStorage.setItem('devicetoken', token);   
  }
  export const getDeviceToken = () => {
    return sessionStorage.getItem('devicetoken') || null;
    
  }

