import React,{ useState, useEffect} from 'react'
import {  Switch, Route } from "react-router-dom";
import Admin from '../screen/panel/admin/Admin';
import Enquiry from '../screen/panel/enquiry/Enquiry';
import EnquiryDetails from '../screen/panel/enquiry/component/enquirydetails/EnquiryDetails';
import Driver from '../screen/panel/driver/Driver';
import AddDriver from '../screen/panel/driver/component/adddriver/AddDriver';
import BecomeADriver from '../screen/panel/driver/component/becomeadriver/BecomeADriver';
import DriverDocuments from '../screen/panel/driver/component/driverdocuments/DriverDocuments';
import DriverDetails from '../screen/panel/driver/component/driverdetails/DriverDetails';
import SuperAdmin from '../screen/panel/superadmin/SuperAdmin';
import SuperAdminProfile from '../screen/panel/superAdminProfile/superAdminProfile';
import Terms from '../screen/panel/driver/component/terms/Terms'
import Notifications from '../screen/panel/notification/notification';
import Orders from '../screen/panel/orders/orders';
import OrderDetails from '../screen/panel/orders/components/orderDetail';
import Payments from '../screen/panel/payment/Payment';
import Products from '../screen/panel/products/products';
import ProductDetails from '../screen/panel/products/components/productdetail';

export default function Panel(){
  window.scrollTo(0,0)
const Panel = [
    {
      path: "/dashboard",
      exact:true,
      render:()=><Admin/>
    },
    {
      path: "/enquiry",
      exact:true,
      render:()=><Enquiry/>
    },
    {
      path: "/superadmin",
      exact:true,
      render:()=><SuperAdmin/>
    },
    {
      path: "/superadminprofile",
      exact:true,
      render:()=><SuperAdminProfile/>
    },
    {
      path: "/enquiry/details",
      exact:true,
      render:()=><EnquiryDetails/>
    },
    {
      path: "/drivers",
      exact:true,
      render:()=><Driver/>
    },

    {
      path: "/drivers/becomeadriver",
      exact:true,
      render:()=><BecomeADriver />
    },
   
    {
      path: "/drivers/adddriver",
      exact:true,
      render:()=><AddDriver />
    },
    {
      path: "/drivers/driverdocuments",
      exact:true,
      render:()=>< DriverDocuments />
    },
    {
      path: "/drivers/details",
      exact:true,
      render:()=><DriverDetails/>
    },
    {
      path: "/drivers/terms",
      exact:true,
      render:()=><Terms/>
    },
    {
      path: "/superadmin/details",
      exact:true,
      render:()=><SuperAdminProfile/>
    },
    {
      path: localStorage.getItem("navOption")+"/notifications",
      exact:true,
      render:()=><Notifications />
    },
    {
      path: "/orders",
      exact:true,
      render:()=><Orders/>
    },
    {
      path: "/orders/details",
      exact:true,
      render:()=><OrderDetails/>
    },
    {
      path: "/payments",
      exact:true,
      render:()=><Payments/>
    },
    {
      path: "/products",
      exact:true,
      render:()=><Products/>
    },
    {
      path: "/products/details",
      exact:true,
      render:()=><ProductDetails/>
    },
  ];


  return(
      <Switch>
      {
        Panel.map((route, index) =>(
        <Route key={index} path={route.path}  exact render={route.render} Notifications="tsting" />
      ))}
    </Switch>
  )

}

