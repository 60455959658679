import React from 'react'
import './Footer.scss'

const Footer = (props) => {
    return (
        <div className='footer' style={props.style}>
            <div className='footer-container'>
                <aside className='aside'>
                    <p className='just'>Just</p>
                    <p>Download our app:</p>
                    <div style={{ width: 50, display: 'flex', justifyContent: 'space-between' }}>
                        <img src={require('../../assets/images/apple.png')} alt='' />
                        <img src={require('../../assets/images/android.png')} alt='' />
                    </div>
                </aside>
                <section className="list">
                    <ul className='list-title'>
                        <label>Company </label>
                        <li> About</li>
                        <li>Partners</li>
                        <li>Blog</li>
                    </ul>
                    <ul className='list-title'>
                        <label>Links </label>
                        <li>Join us</li>
                        <li>Contact Us</li>
                    </ul>
                    <ul className='list-title'>
                        <label>Policy </label>
                        <li>Terms of service</li>
                        <li>Privacy</li>
                    </ul>
                </section>
            </div>
            <div className="footer-line">
                <hr style={{ border: "1px solid rgba(255,255,255,0.13)" }} />
                <div className='rights'>
                    <img style={{ width: 17, height: 16 }} src={require('../../assets/images/c.png')} alt='' />
                    <p> 2020 All rights reserved. Just.pvt.ltd</p>  </div>
            </div>

        </div>
    )
}
export default Footer