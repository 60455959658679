import React, { useState } from "react";
import JustCard from '../../components/justcard/JustCard'
import { forgot } from '../../utils/apis/services/api';
import { withRouter } from "react-router-dom";
import ForgotCard from "./components/forgotcard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Forgot(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const handleForgot = async (state) => {
        setLoading(true);
        let { username} = state;
        let userData = {email: username.toLowerCase(),"is_superadmin": true };
        let result = await forgot (userData)
        if(result.data.ResponseCode === 200 && result.data.ErrorCode === null)
        {
            setLoading(false);
            toast("Reset Password Link Sent to your Email");        }
        else {
            setError(result.data.ResponseMessage);
            setLoading(false)
        }
      };
    return (
     
       <div className="flexRow">
        <JustCard />
        <ForgotCard callBack={handleForgot} error={error} loading={loading} />
        <ToastContainer/>
       </div>
      );

}
export default withRouter(Forgot);