import React from 'react'
import '../details/Details.scss'
import { Field, ErrorMessage, Formik, Form } from 'formik';
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom';
import Footer from '../../../../components/footer/Footer';

const DetailsMain = () => {
  const history = useHistory()
  const initialValues = {
    mname: '',
    memail: '',
    mnumber: '',
    maddress: '',
  }

  const validationSchema = Yup.object({
    mname: Yup.string().required(' '),
    memail: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Invalid email address").required(' '),
    mnumber: Yup.number().typeError("Please enter valid number").required(" "),
    maddress: Yup.string().min(8, "Mininum 8 characters").matches(/^[ A-Za-z0-9_./,-]*$/,"Specail characters are not allowed").required(" "),
  })

  const onSubmit = values => {
    console.log("data", values);
    history.push('/onboarding/professional')
  }

  return (
    <>
     <div className='header'>
          <p className='just'>Just</p>
          <div className='header-nav'>Become a partner</div>
        </div>
        
      <div className='details'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>

          {({ errors, touched }) => {
            return <Form className='form'>
              <p className='details-label'>Personal Details</p>
              <p className="details-title">Please fill in the required information below correctly</p>
              <div className={touched.mname && errors.mname ? "input-field field-line" : "input-field "}>
                <label htmlFor="mname" className='input-label'>Full Name
         <span className='eg'>e.g. John Doe</span></label>
                <Field autoComplete="off" className={touched.mname && errors.mname ? "input line" : "input "}
                  type='text'
                  id="mname"
                  name='mname'
                  placeholder='Enter you full name'
                />
                <ErrorMessage name='mname' />

              </div>
              <div className={touched.memail && errors.memail ? "input-field field-line" : "input-field "}>
                <label htmlFor="memail" className='input-label'>Email Address
                 <span className='eg'>e.g. johndoe@example.com</span></label>
                <Field autoComplete="off" className={touched.memail && errors.memail ? "input line" : "input "}
                  type='text'
                  id="memail"
                  name='memail'
                  placeholder='Enter you email address'
                />
                <ErrorMessage name='memail' />
              </div>
              <div className={touched.mnumber && errors.mnumber ? "input-field field-line" : "input-field "}>
                <label htmlFor="number" className='input-label'>Phone Number
                 <span className='eg'>e.g. +856 18738271837</span>
                </label>
                <Field autoComplete="off" className={touched.mnumber && errors.mnumber ? "input line" : "input "}
                  type='tel'
                  id="mnumber"
                  name='mnumber'
                  placeholder='Enter you phone number'
                />
                <ErrorMessage name='mnumber' />
              </div>
              <div className={touched.maddress && errors.maddress ? "input-field field-line" : "input-field "}>
                <label htmlFor="maddress" className='input-label'>Current Address
        <span className='eg'>e.g. Flat, Street, City, County</span>
                </label>
                
                <Field
                  autoComplete="off"
                  className={touched.maddress && errors.maddress ? "input line" : "input "}
                  type='text'
                  id="maddress"
                  name='maddress'
                  placeholder='Enter you current address' />
                <ErrorMessage name='maddress' />

              </div>
              <div className='form-button'>
                <button className='back' onClick={() => history.push('/onboarding/details')} type="button"  >Back</button>
                <button type="submit" className='next'>Next</button>
              </div>
            </Form>
          }}
        </Formik>
      </div>
      <Footer/>
    </>
  )
}

export default DetailsMain