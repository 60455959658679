import React, { useState } from "react";
import TextField from "../../../components/textfield/TextField";
import { withRouter} from "react-router-dom/cjs/react-router-dom.min";
import "../login.scss";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom'
import  translate from '../../../../src/utils/locale/language'
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';

function ForgotCard({ callBack, error, loading  }) {
   // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [state, setState] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = event => {
    event.preventDefault(); 
    callBack(state);
  };
  
  const history = useHistory();

  return (
    <div
      className="login_card_container">
      <div className="login_sub_container">

           <div style={{display:'flex',cursor:'pointer'}} onClick={() => history.push("/login")}>
           <KeyboardBackspaceSharpIcon className='terms-titles' style={{marginTop:21,marginRight:10}}/>
           <p className='terms-titles'style={{width:"35%",textAlign:"left"}} >{translate('back')}</p>
           </div>

        <h2 style={{ textAlign: "center", color: "#03A8EE", fontSize: 30 }}>
          {"Forgot Password"}
        </h2>
        <p
          style={{ color: "grey", textAlign: "center" }}
          className="have_account_text"
        >
         Please provide your registered Email Address
        </p>
        <div style={{ marginTop: 52 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              id={1}
              type="email"
              label="username"
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="username"
              value={state.username}
              error={
                state.username.length > 0 &&
                !re.test(state.username) &&
                "Email Error"
              }
              required={true}
            />

            {error && (
              <div>
                <p className="details_error" style={{ textAlign: "center" }}>
                  {error}
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
            </div>

            {/* <div className="login">
                <SmallButton title="Login" callBack={()=>callBack(username,password)}/>
                </div> */}
            { loading ?
              <div style={{ textAlign: "center" }}>
                <Loader
                  type="Bars"
                  color="#03A8EE"
                  height={100}
                  width={100}
                  timeout={100000000} 
                />
              </div>: <div className="button_center">
                <input className="mt-5 mr-5" type="submit" value="Send Link" />
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ForgotCard);
