import React, { useState, useEffect} from 'react';
import './Header.scss'
import request from '../../../assets/images/panel/request.png';
import notification from '../../../assets/images/panel/bell-ring.png'
import { userProfile,notificationList} from '../../../utils/apis/services/api';
import {setLocale,getCurrentLocale} from '../../../utils/locale/language'
import { NavLink} from "react-router-dom";
import ContentLoader from 'react-content-loader';
import firebase from "../../../firebase"
import { toast } from 'react-toastify';


const Header = ({ title,ncount}) => {
  console.log(title)
    const [admindata,setAdminData]=useState()
    const [showshimmer,setShimmer]=useState(true)
    const [count,setNotificationCount]=useState(0)

    const Admin=async()=>{
        let result = await userProfile();
        setAdminData(result.data.ResponseData !==null ? result.data.ResponseData : '')
    }
    
    const getNotificationList=async()=>{
    let result=await notificationList(1)
    setNotificationCount(result.data.ResponseData!==null ? result.data.ResponseData.unread_count : 0)
    }

    const changeLanguage = (lan) => {
        setLocale(lan);
        window.localStorage.setItem("lang", lan);
        window.location.reload()
    };

    let loadshimmer = <ContentLoader
    speed={2}
    width={75}
    height={75}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="profile"
  >
    <rect x="5" y="5" rx="8" radius="8" width="65" height="65" /> 

  </ContentLoader>

    useEffect(() => {
        Admin();
        getNotificationList()
        let lang=window.localStorage.getItem('lang')
        setLocale(lang)
      }, [])

      useEffect(() => {
        const messaging = firebase.messaging()
        if (firebase.messaging.isSupported()) {
          messaging.onMessage(async(payload) => {
            let result=await notificationList(1)
             setNotificationCount(result.data.ResponseData!==null ? result.data.ResponseData.unread_count : 0)
            toast.dismiss()
            toast.info(<div>
              <p style={{fontWeight:"bold"}}>{payload.notification.title}</p>
            <p>{payload.notification.body}</p>
            </div>)
          });
        };
      });
    

    return (
     <>

      <div>
        <div className='Admin-header'>
        <div>
        <p className='dashboard-title'>{title}</p>
        </div>
            <div className='admin-icon'>
            {(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?''
            :<span style={{display: "flex",flexDirection: "row",marginRight: 20,}}>
                <p onClick={() => changeLanguage("lo")}
                  className={getCurrentLocale() === "lo"? "lang_selected": "lang_notselected"}>
                  LAO
                </p>
                <span className="lang_divider" />
                <p onClick={() => changeLanguage("en")}
                  className={getCurrentLocale() === "en"? "lang_selected": "lang_notselected"} >
                  EN
                </p>
              </span>}
                <NavLink activeClassName="active" className='panel-list' to={localStorage.getItem("navOption")+"/notifications"}>
                <img src={notification} alt='bell' className='notification'  />
                 {ncount!==("undefined"&&undefined)?<p className={ncount>0?"notificationcount":'notificationcount0'}>{ncount>0&&ncount<31?ncount:ncount>30?"30+":''}</p>
                  :<p className={count>0?"notificationcount":'notificationcount0'}>{count>0&&count<31?count:count>30?"30+":''}</p>}
                </NavLink>
        

                <NavLink activeClassName="active" className='panel-list' to="/superadminprofile" >
                {showshimmer&&!admindata ? loadshimmer: ()=>setShimmer(false)}
                {admindata ? <img src={admindata!==null ? admindata.profile_image:request}  className="profile" alt='profile' />:''}
                </NavLink>
            </div>
        </div>
        </div>
    </>
    )
}

export default Header