import React, { useState } from "react";
import TextField from "../../../components/textfield/TextField";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "../login.scss";
import Loader from "react-loader-spinner";

function ResetPasswordCard({ callBack, error, loading  }) {
   // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [state, setState] = useState({ username: "", password: "",confirmpassword:"" });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = event => {
    event.preventDefault(); 
    callBack(state);
  };
  

  return (
    <div
      className="login_card_container">
      <div className="login_sub_container">
        <h2 style={{ textAlign: "center", color: "#03A8EE", fontSize: 30 }}>
          {"Welcome To The JUST Family"}
        </h2>
        <p
          style={{ color: "grey", textAlign: "center" }}
          className="have_account_text"
        >
         Please reset your password
        </p>
        <div style={{ marginTop: 52 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              id={1}
              type="email"
              label="username"
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="username"
              value={state.username}
              error={
                state.username.length > 0 &&
                !re.test(state.username) &&
                "Email Error"
              }
              required={true}
            />

            
            <TextField
              id={2}
              type="password"
              label={"password"}
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="password"
              value={state.password}
              required={true}
            />

            
            <TextField
              id={2}
              type="password"
              label={"confirm password"}
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="confirmpassword"
              value={state.confirmpassword}
              required={true}
              error={state.password!==state.confirmpassword &&"password does not match"}
            />

            {error && (
              <div>
                <p className="details_error" style={{ textAlign: "center" }}>
                  {error}
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
            </div>

            { loading ?
              <div style={{ textAlign: "center" }}>
                <Loader
                  type="Bars"
                  color="#03A8EE"
                  height={100}
                  width={100}
                  timeout={100000000} 
                />
              </div>: <div className="button_center">
                <input className="mt-5 mr-5" type="submit" value="Continue" />
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ResetPasswordCard);
