import React from 'react'
import './Home.scss'
import ServiceCard from './component/servicecard/ServiceCard'
import ArticalCard from './component/articlecard/ArticleCard'
import Customers from './component/customers/Customers'
import Footer from '../../components/footer/Footer'
import Slide from './component/slide/Slide'
import stand from '../../assets/images/stand.jpg'
import Button from '../../components/button/Button'
import Navbar from '../../components/navbar/Navbar'
import  translate from '../../utils/locale/language'

const Home = () => {
    
    return (
        <div className='home'>
            <Navbar />
            <Slide />
            <div className='core-values' >
                <section className='cv-section'>
                    <hr />
                    <summary>{translate('coreValues')}</summary>
                    <p className="cv-about">You are only 3 easy steps away from earning and partnering with Just.
                    Just follow the steps and you are good to go.
              Morbi maximus ligula a purus porttitor faucibus.</p>
                    <Button style={{ width: 153 }} title='Know More' />
                </section>
                <figure className='cv-figure'>
                    <figcaption className='cv-figure-title'>What we stand for </figcaption>
                    <img style={{ width: "100%", height: "88%" }} src={stand} alt="img" />
                </figure>
            </div>
            <ul className='status'>
                <li>
                    <p className='number'>1,1203</p>
                    <p className='title'>Delivery Personal</p>
                </li>
                <li>
                    <p className='number'>13</p>
                    <p className='title'>Location cities</p>
                </li>
                <li>
                    <p className='number'>21</p>
                    <p className='title'>Offices in Laos</p>
                </li>
                <li>
                    <p className='number'>20,283</p>
                    <p className='title'>Orders Everyday</p>
                </li>
            </ul>
            <ServiceCard />
            <ArticalCard />
            <Customers />
            <section className='newsletter'>
                <p className='bold'>Join our Newsletter</p>

                <p className='summary'>Know the latest happening in JUST.
                           All the offers and juice discounts provided to you first.
                        We only bother your once or twice a month.</p>
                <div className='join-button'>Join Newsletter</div>
            </section>
            <Footer />
        </div>
    )
}

export default Home