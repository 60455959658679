import React, { useEffect } from 'react'
import Loader from "react-loader-spinner";
import queryString from "query-string";
import { useHistory } from 'react-router-dom';
const LoaderScreen = (props) => {
  const history = useHistory()
  useEffect(() => {
    reset()
 // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

 
  const reset = () => {
    console.log("loaderscreen")
    let params = queryString.parse(props.location.search);
    const { token } = params;
    console.log(params);
    setTimeout(() => {
      history.push({
        pathname: "/resetpassword",
        state: { "token": token }
      });
    }, 2000);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "center",
        flex: 1,
        position: "absolute",
        left: "50%",
        top: "40%",
      }}
    >
      <div>
        <Loader
          type="Bars"
          color="#03A8EE"
          height={100}
          width={100}
          timeout={100000000} //3 secs
        />
      </div>
    </div>
  );
}
export default LoaderScreen