import React, { useState, useEffect } from "react";
import "./SuperAdmin.scss";
import Header from "../header/Header";
import Button from "../../../components/button/Button";
import List from "../../../components/list/List";
import { useHistory } from "react-router-dom";
import Titlebar from "../../../components/titlebar/Titlebar";
import {
  searchAdmin,
  getAdmins,
  getManagers,
} from "../../../utils/apis/services/api";
import Pagination from "react-js-pagination";
import SuperAdminform from "../superadmin/components/superAdminFroms/superAdminForm";
import ContentLoader from "react-content-loader";
import translate from "../../../utils/locale/language";
import { setDriverSelection,getDriverSelection,setPageSelection,getPageSelection} from "../../../../src/utils/common"

const SuperAdmin = () => {
  const history = useHistory();
  const [list, setList] = useState(1);
  const [title, setTitle] = useState("Database");
  const [managerList, setManagers] = useState([]);
  const [adminList, setAdmins] = useState([]);
  const [requestListTmpManager, setRequestListTmpManager] = useState([]);
  const [requestListTmpAdmin, setRequestListTmpAdmin] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  var [activePage, setPage] = useState(1);
  const [adminModal, setAdminModal] = useState(false);
  const [managerModal, setMangerModal] = useState(false);

  const getManagersList = async (page_number) => {
    setPageSelection(page_number)
    setLoading(true);
    let result = await getManagers(getPageSelection());
    setLoading(false);
    setCount(
      result.data.ResponseData.results ? result.data.ResponseData.count : ""
    );
    setManagers(result ? result.data.ResponseData.results : "");
    setRequestListTmpManager(result ? result.data.ResponseData : "");
  };

  const getAdminsList = async (page_number) => {
    setPageSelection(page_number)
    setLoading(true);
    let result = await getAdmins(getPageSelection());
    setLoading(false);
    setCount(
      result.data.ResponseData.results ? result.data.ResponseData.count : ""
    );
    setAdmins(result ? result.data.ResponseData.results : "");
    setRequestListTmpAdmin(result ? result.data.ResponseData : "");
  };

  const updateInput = async (input) => {
    console.log(input);
    setInput(input);
    let role = 2;
    if (list === 1) {
      role = 2;
    } else if (list === 2) {
      role = 3;
    }
    var result = await searchAdmin(input, role);
    if (role === 3) {
      if (input.length >= 1) {
        setCount(
          result.data.ResponseData.results ? result.data.ResponseData.count : ""
        );
        setManagers(
          result.data.ResponseData ? result.data.ResponseData.results : ""
        );
      } else if (
        result.data.ResponseCode === 200 &&
        result.data.ErrorCode === 404
      ) {
        setManagers(requestListTmpManager.results);
        setCount(requestListTmpManager.count);
      } else {
        setManagers(requestListTmpManager.results);
        setCount(requestListTmpManager.count);
      }
    } else if (role === 2) {
      if (input.length >= 1) {
        setCount(
          result.data.ResponseData.results ? result.data.ResponseData.count : ""
        );
        setAdmins(
          result.data.ResponseData ? result.data.ResponseData.results : ""
        );
      } else if (
        result.data.ResponseCode === 200 &&
        result.data.ErrorCode === 404
      ) {
        setAdmins(requestListTmpAdmin.results);
        setCount(requestListTmpAdmin.count);
      } else {
        setAdmins(requestListTmpAdmin.results);
        setCount(requestListTmpAdmin.count);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setPage(pageNumber);
    if (list === 1) {
      getAdminsList(pageNumber);
    
    } else if(list === 2){
      getManagersList(pageNumber);
    }
  };

  // useEffect(() => {
  //   getManagersList(1);
  //   getAdminsList(1);
  // }, []);

  // useEffect(() => {
  //   getManagersList(1);
  //   getAdminsList(1);
  // }, [history.location.state]);

  const handleRequestData = (data)=>{
    console.log(data)
    switch (data){
      case 1 :
        getAdminsList(getPageSelection());
        break;
      case 2:
        getManagersList(getPageSelection());
        break;   
  }
}

useEffect(()=>{
  const sideTab=localStorage.getItem("navOption");
  if(sideTab!=="/superadmin"){
      setDriverSelection(1)
      setPageSelection(1)
      setList(1)
      setPage(1)
      getAdminsList(1);
  }
  // getAdminsList(1);
  // setPageSelection(1);

},[])

  useEffect(() => {
    if(getPageSelection()){
      setPage(getPageSelection())
    }else{
      setPageSelection(1)
      setList(1)
      getAdminsList(1);
    }
    if(getDriverSelection()){
      setList(getDriverSelection())
      handleRequestData(parseInt(getDriverSelection()))  
    }else{
      setList(1)
      setDriverSelection(1)
    }
    setList(getDriverSelection())
  }, [])


  


  const SuperAdmin = () => {
    setTitle(translate("database"));
    setList(1);
    setDriverSelection(1);
    setInput("");
    getAdminsList(1);
    setPage(1)
  };
  const managers = () => {
    setTitle(translate("database"));
    setList(2);
    setDriverSelection(2);
    setInput("");
    getManagersList(1);
    setPage(1)
  };

  const superAdminList = (selected) => {
    switch (selected) {
      case 1:
        return (
          <List
            from="admin"
            data={adminList}
            onClick={() => history.push("/superadmin/details")}
          />
        );
      case 2:
        return (
          <List
            from="admin"
            data={managerList}
            onClick={() => history.push("/superadmin/managerdetails")}
          />
        );
      default:
        return (
          <List
            from="admin"
            data={adminList}
            onClick={() => history.push("/superadmin/details")}
          />
        );
    }
  };

  let loadshimmer = (
    <ContentLoader
      speed={2}
      width={"1000px"}
      height={450}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ marginLeft: -29, marginTop: -25 }}
    >
      <rect x="51" y="45" rx="3" ry="3" width="1006" height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width="997" height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width="997" height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="997" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width="997" height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="1006" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );

  return (
    <div className="drivers">
      <Header title={translate("superAdmin")} />
      <div className="drivers-header">
        <p className="partner-database">
          {translate("partnerDatabase")}
        </p>
        <div style={{ display: "flex" }}>
          <Button
            title={translate("addAdmin")}
            onClick={() => setAdminModal(true)}
            style={{ width: 150, height: 35 }}
          />
          <Button
            title={translate("addManager")}
            onClick={() => setMangerModal(true)}
            style={{ marginLeft: 15, width: 150, height: 35 }}
          />
        </div>
      </div>
      <Titlebar
        onClick1={SuperAdmin}
        onClick2={managers}
        input={input}
        onClick4={updateInput}
        active={list}
        title1={translate("admins")}
        title2={translate("accManager")}
      />
      <SuperAdminform
        Heading={translate("addNewAdmin")}
        onClick1={"admin"}
        show={adminModal}
        closeModal={() => setAdminModal(false)}
      />
      <SuperAdminform
        Heading={translate("addNewManager")}
        settab={managers}
        onClick1={"manager"}
        show={managerModal}
        adressfield={true}
        closeModal={() => setMangerModal(false)}
      />

      {!loading ? (
        <div>
          <section className="shortlisted">{superAdminList(list)}</section>
          {count > 10 ? (
            <Pagination
              firstPageText={
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              }
              prevPageText={
                <i class="fa fa-angle-double-left" aria-hidden="true"></i>
              }
              lastPageText={
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              }
              nextPageText={
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              }
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={count}
              onChange={handlePageChange}
              activeClass="active"
              activeLinkClass="active"
              hideDisabled
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <section className="shortlisted">{loadshimmer}</section>
      )}
    </div>
  );
};
export default SuperAdmin;
