import React from "react";
import "./TextField.scss";

export default class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      error: props.error || "",
      label: props.label || "Label"
    };
  }

  handleKeyPress(event) {
    if (event.which === 13) {
      this.setState({ value: this.props.predicted });
    }
  }

  render() {
    const { active, value, label } = this.state;
    const { predicted, locked,type } = this.props;
    const fieldClassName = `field ${(locked ? active : active || value) &&
      "active"} ${locked && !active && "locked"}`;

    return (
      <div className={fieldClassName} style={{marginTop:13,}}>
        {active &&
          value &&
          predicted &&
          predicted.includes(value) && <p className="predicted">{predicted}</p>}
        <input
        style={{background:this.props.new?"#E0E0E0":" #F2F2F2"}}
          id={1}
          type={type}
          
          value={this.props.value}
          placeholder={this.props.label}
          onChange={this.props.changeValue}
          onKeyPress={this.handleKeyPress.bind(this)}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => !locked && this.setState({ active: false })}
          name={this.props.name}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <label htmlFor={1} className={this.props.error && "error"}>
          {this.props.error || label}
        </label>
      </div>
    );
  }
}