import React, { useState, useEffect } from 'react';
import './notification.scss'
import deleteimg from '../../../assets/images/panel/delete.png'
import request from '../../../assets/images/panel/request.png';
import { notificationList ,ReadandDeleteNotification} from '../../../utils/apis/services/api';
import Header from '../header/Header';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import moment from 'moment'
import { toast} from 'react-toastify';
import Lottie from 'react-lottie';
import noNotification from '../../../assets/lottie/notification.json';
import firebase from "../../../firebase"
import Floatpopup from './components/floatingPopup/floating';
import gotoTopImage from '../../../assets/images/goToTop.svg';
import  translate from '../../../utils/locale/language';

const Notifications = ({ title }) => {

    const [loading, setLoading] = useState(true)
    const [pages, setPages] = useState(1)
    let   [cpage, setCpage] = useState(1)
    const [lazyloading, setLazyLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false)
    const [notificationsList, setNotificationList] = useState([])
    const [delindex, setDelIndex] = useState()
    const [delId, setDelId] = useState()
    const [ncount, setNotificationCount] = useState(0)
    const [newnotification, setNewNotification] = useState(0)
    const [refreshsate,setRefresh]=useState(false)
    const [showScroll, setShowScroll] = useState(false)

    const history = useHistory();
    const getNotificationList=async(pno)=>{
      setCpage(pno)
        if(pno<=pages){
      let result=await notificationList(pno)
      console.log(result)
      setLoading(false)
      setLazyLoading(false)
      let data=result.data.ResponseData!==null ? result.data.ResponseData.results :[]
      setNotificationCount(result.data.ResponseData!==null ? result.data.ResponseData.unread_count : 0)
      setNotificationList(prev=>[...prev ,...data])
      setPages(result.data.ResponseData!==null?Math.ceil(result.data.ResponseData.count/10):0)
        }
        else{
          setLazyLoading(false)
        }
    }

    useEffect(()=>{
        getNotificationList(1)
        
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

        useEffect(() => {
        const messaging = firebase.messaging()
        if (firebase.messaging.isSupported()) {
          messaging.onMessage(async(payload) => {
            console.log(payload)
            setNewNotification(true)
            let result1=await notificationList(1)
            setNotificationCount(result1.data.ResponseData!==null ? result1.data.ResponseData.unread_count : 0)
            toast.dismiss()
            toast.info(
            <div>
            <p style={{fontWeight:"bold"}}>{payload.notification.title}</p>
            <p>{payload.notification.body}</p>
            </div>)
          });
        };
      });

    window.onscroll = function () {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop
      const height =document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = winScroll / height
      
      var totalPageHeight = document.documentElement.scrollHeight;
      var scrollPoint = window.scrollY + window.innerHeight;
      
      if ((notificationsList.length>0&&scrolled+scrollPoint>= totalPageHeight)&&pages>0) {
        setLazyLoading(true)
        window.setTimeout(getNotificationList(cpage+1),30000) 
      }
    };

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: noNotification,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  
const notificationlist=notificationsList.map((notify,index)=>(
  <>
  <div>{console.log(notify,index)}</div>

        <div  key={index}  className={(delindex===index)&&(notify.id===delId)?"animate-delete notification-unread":notify.is_read?'notification-unread animate-top':!notify.is_read?'notification-read animate-top':''}>
           <div className="notification-inline" onClick={()=>readNotification(notify.id,notify.driver_details,notify.order_details,notify.product)}>
           <span>
                <img src={notify.user_details&&notify.user_details.profile_image_url?notify.user_details.profile_image_url:notify.driver_details&&notify.driver_details.profile_image_url ?notify.driver_details.profile_image_url:request} alt='bell' className='notification-profile' />
            </span>
            <p className="notification-message">{(notify.user_details?notify.user_details.full_name:notify.driver_details.full_name)+" "+notify.prefix}</p>
            <div>
            <p className="notification-time">{moment(notify.date_time).format('L')}</p>
            <p className="notification-time">{moment(notify.date_time).format('LTS')}</p>
            </div>
           </div>
<div onClick={() => deleteNotification(notify.id,index)} style={{margin:"48px -21px 0px -10px"}}>{delLoading&&(delindex===index) ?<Loader type="Bars" color="#03A8EE" height="30px" width="30px"></Loader>:<img style={{ width: 20, height: 20, marginTop: "25%" }} src={deleteimg} alt='' />}</div>
        </div>

      <div  onClick= {scrollTop} style={{ display: showScroll ? 'flex' : 'none',cursor:"pointer"}}>
      <img
        src={gotoTopImage}
        width="40px"
        height="40px"
        alt=""
        style={{ position: "fixed", bottom: 50, right: 20}}
      />
    </div>
    
</>


))    


const readNotification=async(nid,driverdetails,order_id,product_id)=>{
      let result=await ReadandDeleteNotification("1",nid)
      console.log(result,driverdetails)
      if(driverdetails){
          if((driverdetails.status==="A"||"S")&&order_id!==null)
          {
            history.push({ pathname: "/orders/details", state: { "OrderId": order_id} })
          }
          else if(driverdetails.Id==undefined){
   //////////////////////here i use hard coded value which i'll have to remove------------------------------------------------------////////////////////
            history.push({ pathname: "/products/details", state: { "productId": product_id} })
          }
          else{
            history.push({ pathname: "/enquiry/details", state: { "driverId": driverdetails.id, "status": driverdetails.status } })
          }
      }
      else{
        history.push({ pathname: "/orders/details", state: { "OrderId": order_id} })
      }
    }

    const deleteNotification=async(nid,index)=>{
      setDelLoading(true)
      setDelIndex(index)
      let oldlist=JSON.parse(JSON.stringify(notificationsList))
      let result1=await notificationList(1)
      setNotificationCount(result1.data.ResponseData!==null ? result1.data.ResponseData.unread_count : 0)
      toast.dismiss();
      setDelLoading(false)
      toast.success("Notification deleted successfully")
      setDelId(nid)
      setTimeout(function(){oldlist.splice(index,1)},1500)
      setTimeout(function(){setNotificationList(oldlist)},1500)
    }

    const refresh =()=>{
      setLoading(true)
      setNotificationList([])
       getNotificationList(1)
       setNewNotification(false)
       setRefresh(false)
    }

    const deleteAllNotifications=async()=>{
      setLoading(true)
      let result=await ReadandDeleteNotification("3",'')
      setLoading(false)
      setNotificationList([])
      getNotificationList(1)
      toast.dismiss();
      toast.success("Notifications cleared successfully")
    }

    return (
        <div className="notifications">
        <Header title= {translate("superAdmin")} ncount={ncount} />
        <div className='notification-header'>
        {newnotification&&ncount>0?<Floatpopup refreshsate={refreshsate} data={notificationsList} setrefresh={()=>setRefresh(true)} refresh={()=>refresh()}/>:""}
        <p className='notification-title'>{translate("Notifications")}</p>
        {newnotification&&ncount>0?<i class={refreshsate?"fa fa-refresh refreshrotate":"fa fa-refresh refresh animate-top"} onClick={()=>{setRefresh(true);setTimeout(function(){refresh()},1500)}} aria-hidden="true"></i>:''}
         { notificationsList.length>0? <p className='notificationclearall' onClick={()=>deleteAllNotifications()}>{translate("Clearallnotifications")}</p>:''}   
           {loading?<Loader style={{textAlign:"center"}} type="Bars" color="#03A8EE"></Loader>:
         notificationsList.length>0?<div>
           {notificationlist}
           {lazyloading?<Loader style={{textAlign:"center"}} type="Bars" color="#03A8EE"></Loader>:''}
        </div>:
         <div className=""><Lottie  options={defaultOptions} height={300} width={300} /></div>   
        }
        </div>
        </div>
      )
    
}

export default Notifications