import React from 'react'
import './Slide.scss'
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import m1 from '../../../../assets/images/banner.jpg'
import m2 from '../../../../assets/images/banner2.jpg'
import m3 from '../../../../assets/images/banner3.jpg'
import  translate from '../../../../utils/locale/language'

const Slide = () => {

  const images = [
    {
      original: m1,
    },
    {
      original: m2,
    },
    {
      original: m3,
    },
  ];

  const item = () => {
    return <div className='banner-container'>
      <p className="bn-heading">{translate('rideDeliverPay')}</p>
  <p className='bn-title'>{translate('slideMessage2')}</p>
      <div className='bn-more'>{translate('learnMore')}</div>
    </div>
  }

  return (

    <ImageGallery additionalClass="slider" autoPlay="true" items={images}
      slideDuration={800} slideInterval={6000}
      renderCustomControls={item}
      showPlayButton={false} showFullscreenButton={false} showNav={false}
      showBullets showThumbnails={false} showIndex={false} />
  )

}

export default Slide