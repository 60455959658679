import React from 'react'
import './FooterCard.scss'

const FooterCard = (props) => {
  return (
    <div className='footer-card' style={props.style} >
      <section className='footer-card-section' style={props.contentStyle} >
        <div>
          <summary style={props.font}>{props.heading}</summary>
          <p className="footer-card-about">{props.children}</p>
        </div>
        <div className="fc-b">Become Partner</div>
      </section>
      <img style={props.imgStyle} className='fc-img' src={props.src} alt="img" />
    </div>
  )
}

export default FooterCard
