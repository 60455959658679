import React,{useState} from 'react'
import './Terms.scss'
import CheckBox from '../../../../../components/checkbox/CheckBox'
import { useHistory } from 'react-router-dom'
import  translate from '../../../../../utils/locale/language'
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import Button from '../../../../../components/button/Button';
import { toast } from 'react-toastify';
import Footer from '../../../../../components/footer/Footer'

const Terms = () => {

    const history = useHistory();
    const [checkboxValue, setCheckboxValue]= useState(false);

    const handleChange = (event) => {
        console.log(event.target.checked, event.target.name);
        setCheckboxValue(event.target.checked)
      };

    const showAlert = () => {
        toast.dismiss();
       const popupData =  {
         title: "Terms & conditions",
         message: "Please select terms & conditions."}
        if(checkboxValue)
        {
          history.push('/drivers/adddriver')
        }
        else
        {
          toast.warning(popupData.message);
        }
       
      }

    return (
        
        <div className="terms animate-bottom">
            <section className='terms-heading animate-bottom'>
                <div >
                    <div style={{display:'flex',cursor:'pointer'}} onClick={() => history.push("/drivers/becomeadriver")}>
                    <KeyboardBackspaceSharpIcon className='terms-titles' style={{marginTop:25,marginRight:10}}/>
                    <p className='terms-titles'style={{width:"35%",textAlign:"left"}} >{translate('back')}</p>
                    </div>
                    <p className='terms-title'>Terms & Conditions</p>
                </div>
                <p>Please read and below terms and you can agree to them at the end of the page</p>
                
            </section>
            <section className='clause-1 animate-bottom'>
                <p className="clause">Clause 1</p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                   Morbi odio est, pretium et elit ac, congue egestas nibh.
                   Etiam vel aliquam massa, sit amet malesuada arcu. Mauris eget
                   turpis et eros convallis gravida quis quis odio. Proin metus elit,
                   venenatis non justo eget, varius commodo neque. Curabitur ultricies
                    lectus sit amet faucibus finibus. Aliquam elementum ornare massa at
                    venenatis. Pellentesque felis sapien, vulputate non sodales ac,
                     semper sed sapien. Duis dolor sem, condimentum quis magna vitae,
                     rhoncus cursus leo. Pellentesque ut nisi auctor, bibendum dolor et,
                     commodo nisl. Maecenas faucibus ac augue vitae facilisis.
                   Aliquam porttitor lorem non viverra tempus. Sed id diam leo.
                </p>
                <p>
                    Etiam molestie arcu a erat gravida, et pulvinar tortor sollicitudin.
                     Nunc at condimentum risus. Pellentesque habitant morbi tristique
                     senectus et netus et malesuada fames ac turpis egestas. Ut vitae odio
                     sit amet tellus blandit bibendum cursus quis elit. Aenean in tempor sem.
                     Cras vel tincidunt elit. Ut viverra, velit sed commodo interdum,
                     augue arcu tempor ipsum, a sagittis enim libero quis erat. Proin
                     suscipit ullamcorper leo ac malesuada.
          </p>
                <p>
                    Quisque tincidunt lacinia sem vel rutrum. Nulla nec diam lacinia, varius turpis interdum,
                     tempus ligula. Cras fringilla imperdiet odio id pulvinar. In blandit sed mauris sed venenatis.
                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis dapibus,
                    diam a volutpat tempor, velit enim fermentum nisl, ut fermentum sapien purus id mauris. Vestibulum eu
                    nulla nec ipsum sagittis dignissim.
          </p>
                <p>
                    Quisque nec diam eu lectus tincidunt fermentum. Sed tempus sapien eget dolor pretium,
                    ornare molestie elit suscipit. Aenean in nibh eu odio finibus dictum. Maecenas mauris lectus,
                    molestie nec tortor varius, pulvinar mollis ex. Morbi quis pulvinar metus. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nunc dictum eros hendrerit consectetur malesuada. Nunc ultrices purus eget
                    enim placerat, et mattis sapien vehicula. Cras id metus id lectus efficitur porta vitae eu nibh.
                    Maecenas auctor dignissim nunc id ullamcorper. Nam consectetur mauris quis ante dictum aliquet.
                    Aenean lobortis ex nisi, ac sagittis orci vulputate in. Vestibulum ante ipsum primis in faucibus orci luctus
             et ultrices posuere cubilia curae; Morbi et efficitur neque.</p>
            </section>
            <section className="clause-2">
                <p className="clause">Clause 2</p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi odio est, pretium et elit ac, congue egestas nibh.
                    Etiam vel aliquam massa, sit amet malesuada arcu. Mauris
                    eget turpis et eros convallis gravida quis quis odio.
                    Proin metus elit, venenatis non justo eget, varius commodo neque.
                    Curabitur ultricies lectus sit amet faucibus finibus. Aliquam
                    elementum ornare massa at venenatis. Pellentesque felis sapien,
                     vulputate non sodales ac, semper sed sapien. Duis dolor sem,
                     condimentum quis magna vitae, rhoncus cursus leo. Pellentesque
                     ut nisi auctor, bibendum dolor et, commodo nisl. Maecenas faucibus ac augue vitae facilisis.
                   Aliquam porttitor lorem non viverra tempus. Sed id diam leo.
            </p>
                <p>
                    Etiam molestie arcu a erat gravida, et pulvinar tortor sollicitudin.
                     Nunc at condimentum risus. Pellentesque habitant morbi tristique
                     senectus et netus et malesuada fames ac turpis egestas. Ut vitae
                      odio sit amet tellus blandit bibendum cursus quis elit. Aenean
                      in tempor sem. Cras vel tincidunt elit. Ut viverra, velit sed commodo
                      interdum, augue arcu tempor ipsum, a sagittis enim libero quis erat.
                      Proin suscipit ullamcorper leo ac malesuada.
                </p>
            </section>
            <div className='accepts' >
                <CheckBox onChange={handleChange} checked={false} type='checkbox' > 
                <span className='s' >I agree to the terms & conditions</span>
                </CheckBox>
                <div  onClick={() => showAlert()}> <Button disabled={checkboxValue} style={{ width: 104, height: 46,}}  title={translate('next')}  />
                </div>
            </div>
            
        </div>
        
    )
}

export default Terms