import React, { useState, useEffect } from "react";
import "./AddManager.scss";
import ReactModal from "react-modal";
import List from "../../../../../components/list/List";
import { getManagers } from "../../../../../utils/apis/services/api";
import translate from "../../../../../utils/locale/language";

const AddManager = (props) => {
  console.log(props);
  ReactModal.setAppElement("#root");
  const [managers, setMangers] = useState([]);
  const getManagersData = async () => {
    let result = await getManagers("");
    console.log(
      "result.ResponseData.results",
      result.data.ResponseData.results
    );
    setMangers(result.data.ResponseData.results);
  };
  useEffect(() => {
    getManagersData();
  }, []);
  return (
    <ReactModal
      isOpen={props.isOpen}
      className="addManagerModal"
      overlayClassName="Overlay"
      onRequestClose={props.onClick}
      id={props.id}
    >
      <section className="add-manager-container">
        <div className="add-manager-block">
          <span className="add-manager-title">
            {translate("accountManagers")}
          </span>
          <i
            className="fa fa-close"
            aria-hidden="true"
            onClick={props.onClick}
          ></i>
        </div>
        <List
          from={props.from}
          getDetails={props.getDetails}
          data={managers}
          id={props.id}
          isClose={props.isClose}
          onClick={props.onClick}
          isOpen={props.isOpen}
          status={"approved"}
          imgStyle={{ marginLeft: 0 }}
        ></List>
      </section>
    </ReactModal>
  );
};

export default AddManager;
