import React from 'react';
import { Switch, Router, useHistory, Route} from 'react-router-dom';
import Home from '../screen/home/Home';
import JustRestaurant from '../screen/justrestaurant/JustRestaurant';
import Onboarding from '../screen/onboarding/Onboarding';
import Faq from '../screen/faq/Faq';
import Dashboard from '../screen/panel/dashboard/dashboard';
import LoaderScreen from '../screen/loaderScreen/LoaderScreen';
import LoaderReset from '../screen/loaderScreen/resetloader';
import login from '../screen/login/login';
import Driver from '../screen/panel/driver/Driver';
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import SuperAdminProfile from '../screen/panel/superAdminProfile/superAdminProfile';
import forgot from '../screen/login/forgot';
import Resetpassword from '../screen/login/resetpassword';

export default function Routes() {
  const history = useHistory()
  
  return (
    <Router history={history}>
        
      <Switch >
      {/* <Route exact  path="/" component={Home} /> */}
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute path='/login' component={login} />
        <PublicRoute path='/forgotpassword' component={forgot} />
        <PublicRoute path="/onboarding" component={Onboarding} />
        <PrivateRoute path="/superadmin" component={Dashboard} />
        <PrivateRoute path="/superadminprofile" component={Dashboard} />
        <PublicRoute path="/restaurant" component={JustRestaurant} />
        <PublicRoute path="/faq" component={Faq} />
        <PublicRoute path="/professional/details" component={LoaderScreen} />
        <PublicRoute path="/professional/forgotpassword" method="reset" component={LoaderReset} />
        <PublicRoute path="/resetpassword"  component={Resetpassword} />
        <PrivateRoute path="/enquiry" component={Dashboard} />
        <PrivateRoute path="/orders" component={Dashboard} />
        <PrivateRoute path="/payments" component={Dashboard} />
        <PrivateRoute path="/products" component={Dashboard} />
        <PrivateRoute path="/orders/details" component={Dashboard} />
        <PrivateRoute path="/products/details" component={Dashboard} />
        <PrivateRoute path="/enquiry/details" component={Dashboard} />
        <PrivateRoute path="/drivers" component={Dashboard} />
        <PrivateRoute path="/drivers/details" component={Dashboard} />
        <PrivateRoute path="/superadmin/notifications" component={Dashboard} />
        <PrivateRoute path="/drivers/becomeadriver" component={Driver} />
        <PrivateRoute path="/superadmin/details" component={SuperAdminProfile} />
        <PrivateRoute path="/drivers/adddriver" component={Driver} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  );
}