import React, { useState, useEffect } from 'react';
import './orderDetail.scss'
import { useHistory } from 'react-router-dom';
import { getOrderDetails} from '../../../../utils/apis/services/api';
import request from '../../../../assets/images/panel/request.png';
import pickup from '../../../../assets/images/panel/pickup.png';
import drop from '../../../../assets/images/panel/drop.png';
import dashline from '../../../../assets/images/panel/ic_line.png';
import orderbox from '../../../../assets/images/panel/orderbox.png';
import 'react-toastify/dist/ReactToastify.css';
import ContentLoader from "react-content-loader"
import  translate from '../../../../utils/locale/language'
import Header from '../../header/Header';
import moment from 'moment'
import StepProgressBar from './progressBar/progressBar';
import Map from '../../../../components/map/map'

const OrderDetails = (props) => {
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState()
  const [showshimmer,setShimmer]=useState(true)

  let loadshimmer = <ContentLoader
            speed={2}
            width={75}
            height={75}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="35" cy="35" r="25" />
          </ContentLoader> 

let singlelineshimmer = <ContentLoader
speed={2}
width={200}
height={75}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
>
<rect x="48" y="8" rx="3" ry="3" width="302" height="10" /> 
<rect x="48" y="26" rx="3" ry="3" width="302" height="10" />
</ContentLoader> 

let multilineshimmer =<ContentLoader 
speed={2}
width={276}
height={124}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
{...props}
>
<rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
<rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
<rect x="0" y="56" rx="3" ry="3" width="152" height="10" /> 
<rect x="0" y="72" rx="3" ry="3" width="200" height="10" /> 
</ContentLoader>

let twolineshimmer =<ContentLoader 
speed={2}
width={276}
height={124}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
{...props}
>
<rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
<rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
<circle cx="20" cy="20" r="20" />
</ContentLoader>

  const history = useHistory();

  let order= history.location.state;

  const getorderDetails = async () => {
    setLoading(true);
    let result = await getOrderDetails(order.OrderId)
    console.log("result.ResponseData.results",result.data.ResponseData)
    setOrderData(result.data.ResponseData !==null ? result.data.ResponseData : '' )
    setLoading(false)
  }

  const animateBack=()=>{
    setTimeout(function(){history.goBack()},2000)
  }

  useEffect(()=>{
    getorderDetails()
    setShimmer(true)
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  },[])


  const packageDetails=(data)=>{
    return    (data&&<tr style={{marginBottom:15}}>
    {!loading?<td style={{textAlignLast:"right"}} >
       <p style={{margin:0,fontSize:18}}>{data.custom_packages_count>0? data.custom_packages_count+" Custom Packages":"0 Custom Packages"}</p>
       <div className="packages">
       {data.custom_packages_count>0?data.custom_package.map((item,index)=>
              <p style={{margin:"3px 0px 0px 0px",fontSize:17,color:"#2d89da ",marginRight:48}}>{item.package_detail.centimeter+"cm & "+item.package_detail.kilogram+"kg"}</p>
       ):''}
       </div>
       </td>:<td style={{textAlignLast:"right"}}>{singlelineshimmer}</td>}
    <td style={{textAlign:"-webkit-center"}}><span className="details_divider"></span></td>
    {!loading? <td  >
       <p style={{margin:0,fontSize:18}}>{data.standard_packages_count>0? data.standard_packages_count+" Standard Packages":"0 Standard Packages"}</p>
       <div className="packages">
       {data.standard_packages_count>0?data.standard_package.map((item,index)=>
        <p style={{margin:"3px 0px 0px 0px",fontSize:17,color:"#2d89da "}}>{item.package_detail.number_of_packages+" "+item.package_detail.type}</p>
       ):''}
       </div>
    </td>:<td>{singlelineshimmer}</td>}
  </tr>)
  }

  return (
    <>
        {/* <ToastContainer autoClose={3000}  /> */}
        <Header title={translate('ordersdetails')} back={()=>animateBack()}/>
        <div style={{marginBottom:"2%"}}>
        {/* <div>
        <Button onClick={() =>animateBack()} style={{ minwidth: 97, height: 40,float:"right" }} title={translate('back')} />
        </div> */}
      </div>
        {
      <div>
      {/* { !loading ? */}
         <div>
        <section className={"details-container"}>
        <table className="manager-table">
                <thead className="manager-head">
                  <tr>
                    <th>{translate('order')}</th>
                    <th>{translate('orderstatus') }</th>
                  </tr>
                </thead>
                <tbody className="manager-body">
                  <tr >
                 <td className='manager-data'  >
                 { orderData&&!loading  ? <table>
                        <tbody>
                          <tr>
                            <td><img src={orderbox} alt='' /></td>
                            <td>
                            <p style={{fontSize:18,fontWeight:"bold"}}className='m-name'>{"Order No. "+orderData.order_id}</p>
                            <p style={{fontSize:14,color:"darkgray"}} className='m-name'>{"( "+moment(orderData.created_at).format('LLL')+" )"}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>:twolineshimmer}
                    </td>

                    <td className='manager-data'  >
                 { orderData&&!loading  ? <table>
                        <tbody>
                          <tr>
                            <td>
                            <StepProgressBar percentage={orderData.status.order_status_percentage} ordered={moment(orderData.created_at).format('Do MMM')} delivered={moment(orderData.created_at).format('Do MMM')}/>
                            </td>
                          </tr>
                        </tbody>
                      </table>:singlelineshimmer}
                    </td>
                    </tr>
                    </tbody>
            </table>          
        </section>

        <section style={{marginTop:20,marginBottom:15}} className={"details-container"}>
              <table className="manager-table">
                <thead className="manager-head ">
                  <tr>
                    <th style={{width:"33%"}}>{translate('deliveredby')}</th>
                    <th style={{width:"33%"}}>{translate('contactdetails') }</th>
                    <th style={{width:"33%"}}>{translate('pickndrop')}</th>
                  </tr>
                </thead>
                <tbody className="manager-body">
                  <tr>
                 <td className='manager-data '  >
                 { orderData&&!loading  ? <table>
                        <tbody style={{ width: '30%' }}>
                          <tr>
                            <td>
                             {showshimmer ?loadshimmer:''}
                             <img className={showshimmer ?'d-shimmer-img':'m-img'} onLoad={()=>setShimmer(false)} src={orderData.delevery_details && orderData.delevery_details.profile_image ? orderData.delevery_details.profile_image : request } alt='' /></td>
                            <td style={{ width: 100,}} ><p className='m-name' style={{fontSize:22}}>{orderData.delevery_details?orderData.delevery_details.full_name:"Driver NotAssigned"}</p></td>
                          </tr>
                        </tbody>
                      </table>:twolineshimmer}
                    </td>
    
                    <td className='manager-data' >
                    { orderData&&!loading ?
                      <table style={{ width: '35%' }}>
                        <tbody>
                          <tr style={{ height: 30 }} >
                            <td><i style={{ fontSize: 18 }} className="fa fa-envelope-o" /></td>
                            <td> <span style={{ margin: "0 2%" }} >{orderData.delevery_details ? orderData.delevery_details.email :'No Data'}</span></td>
                          </tr>
                          <tr style={{ height: 30 }}  >
                            <td style={{ paddingLeft: '7px' }} > <i style={{ fontSize: 18 }} className="fa fa-mobile" /> </td>
                            <td> <span style={{ marginLeft: "3%" }} >{orderData.delevery_details?orderData.delevery_details.phone_number :"No Data"}</span> </td>
                          </tr>
                        </tbody>
                      </table>:
                        <table style={{ width: '35%' }}>
                        <tbody>
                          <tr style={{ height: 30 }} >
                           {singlelineshimmer}
                          </tr>
                          <tr style={{ height: 30 }}  >
                            {singlelineshimmer}
                          </tr>
                        </tbody>
                      </table>}
                    </td>
                      <td className='manager-data '>
                      {!loading&&orderData.package_details ?
                          <table style={{ width: '35%' }}>
                        <tbody>
                          <tr style={{ height: 30 }} >
                            <td><img src={pickup} alt=''></img></td>
                            <td>
                               <p style={{fontWeight:"bold",margin:"5px"}}>Pickup Location</p>
                               <p className="cropshort " style={{ margin: "0 2%" }} >{orderData.package_details.pickup_location ? orderData.package_details.pickup_location.address :'No Data'}</p>
                            </td>
                          </tr>
                          <tr>
                              {/* <td><p className="dashedline"></p></td> */}
                              <td><img src={dashline} alt=''></img></td>
                          </tr>
                          <tr style={{ height: 30 }}  >
                            <td ><td><img src={drop} alt=''></img></td> </td>
                            <td> 
                              <p style={{fontWeight:"bold",margin:"5px"}}>Drop Location</p>
                              <p className="cropshort " style={{ margin: "0 2%" }} >{orderData.package_details.drop_location?orderData.package_details.drop_location.address :"No Data"}</p> </td>
                          </tr>
                        </tbody>
                      </table>: 
                          <table style={{ width: '35%' }}>
                          <tbody>
                            <tr style={{ height: 30 }} >
                              <td><img src={pickup} alt=''></img></td>
                              <td>
                               {multilineshimmer}
                              </td>
                            </tr>
                            <tr>
                                {/* <td><p className="dashedline"></p></td> */}
                                <td><img src={dashline} alt=''></img></td>
                            </tr>
                            <tr style={{ height: 30 }}  >
                              <td ><td><img src={drop} alt=''></img></td> </td>
                              <td> 
                                {multilineshimmer}
                               </td>
                            </tr>
                          </tbody>
                        </table>
                      }
                      </td> 
                    {/* <td className='manager-data'  >
                    </td>                 */}
                  </tr>
                </tbody>
              </table>
            </section> 
            <section style={{marginTop:20,marginBottom:15}} className={"details-container"}>
               <section className="uploaded-document " style={{marginTop:"0%"}}>
               {!loading&&orderData.package_configuration&&<p style={{ fontSize: 22 ,color:"darkgray"}} >{translate('packagedetails')}</p>}
                {!loading&&orderData.package_configuration&&<div style={{display:"flex"}}>
                <i class="fa fa-shopping-bag" aria-hidden="true" style={{fontSize:16,alignSelf: "center",marginRight:"5px"}}></i>
                <p style={{ fontSize: 20, color: 'black' }} >{translate('totalpackage')}</p>
                {!loading?<p style={{ fontSize: 20, color: 'black' }} >{" : "+(orderData.package_configuration.standard_packages_count+orderData.package_configuration.custom_packages_count)}</p>:singlelineshimmer}  
                 </div>}
                <table className="document-table ">
                  <tbody className='document-body'>
                    {
                      packageDetails(!loading?orderData.package_configuration:'')
                    }
                    <tr>
                    <td>
                        <div style={{display:"inline-flex"}}><i class="fa fa-clock-o" aria-hidden="true" style={{fontSize:16,alignSelf: "center",marginRight:"5px"}}></i>
                        <p style={{fontSize:20,color: "black"}}>Pickup Time :-</p>
                        </div> 
                        {!loading?<p style={{ fontSize: 16, color: '#2d89da ',fontWeight:"bold" }} >{orderData.package_details.pickup_datetime}</p>:singlelineshimmer}
                    </td>
                    <td></td>
                    <td>
                    {!loading? <p style={{ fontSize: 18, color: '#2d89da ',float:"right",fontWeight:"bold" }} >{orderData.package_details.slot}</p>:singlelineshimmer}
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <div style={{display:"inline-flex"}}><i class="fa fa-money" aria-hidden="true" style={{fontSize:16,alignSelf: "center",marginRight:"5px"}}></i>
                        <p style={{fontSize:20,color: "black"}}>Payment :-</p>
                        </div>
                        {!loading?<p style={{ fontSize: 16, color: '#2d89da ',fontWeight:"bold" }} >{"By "+orderData.payment_method.slug}</p>:singlelineshimmer}
                    </td>
                    <td></td>
                    <td>
                    {!loading?  <p style={{ fontSize: 18, color: '#2d89da ',float:"right",fontWeight:"bold" }} >{orderData.payment_method.mode}</p>:singlelineshimmer}
                    </td>
                    </tr>
                    <tr>
                        <td>
                        <div className="pricedetails">
                         <div>
                         <p style={{ fontSize: 18, color:"darkgray",fontWeight:"bold" }} >{translate("tiprider")}</p>
                          <div style={{display:"flex"}}>
                          {!loading&&orderData.price_details.tip?<p style={{ fontSize: 18, color:"gray"}} >{"₭ "}</p>:''}
                          {!loading?<p style={{ marginLeft:"0.3em",fontSize: 18, color:"gray",fontWeight:"bold"}}>{orderData.price_details.tip?orderData.price_details.tip:"No Tip"}</p>:singlelineshimmer}
                          </div>
                         </div>
                        <p className="details_divider"></p>
                        </div>
                        </td>
                        <td>
                        <div className="pricedetails">
                         <div>
                        <p style={{ fontSize: 18, color:"#5FB344",marginLeft:2,fontWeight:"bold"}} >{translate("promocode")}</p>
                        {!loading? <p style={{ fontSize: 18, color:'#5FB344'}} >{orderData.price_details.coupons?"₭ "+orderData.price_details.coupons:"No Promocode"}</p>:singlelineshimmer}
                        </div>
                        <p className="details_divider"></p>
                        </div>
                        </td>
                        <td>
                         <p style={{ fontSize:21,color:'black',fontWeight:"bold"}} >{translate("totalpayable")}</p>
                         {!loading?<p style={{ fontSize:21, color:'black'}} >{"₭ "+orderData.price_details.final_amount}</p>:singlelineshimmer}
                        </td>
                    </tr>
                  </tbody>
                </table>
               </section>
            </section>

            <section style={{marginTop:20,marginBottom:15}}>
              {!loading&&<Map driverdata={orderData}/>}
            </section>
         </div>
      </div>
      }
    </>
  )
}

export default OrderDetails