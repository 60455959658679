import React from "react";
import './Navbar.scss'
import { NavLink,useHistory } from 'react-router-dom'
import translate from "../../utils/locale/language"
import { setLocale, getCurrentLocale } from "../../utils/locale/language";
const Navbar = () => {
    const history = useHistory()
    const changeLanguage = (lan) => {
        setLocale(lan);
        window.localStorage.setItem("lang", lan);
        history.push(history.location.pathname);
    };
    return (
        <div className="navbar">
            <div className='nav-title' >
            {translate('just')}
            </div>
            <ul className='nav-item'>
            <span style={{display: "flex",flexDirection: "row",marginRight: 20,}}>
                <p onClick={() => changeLanguage("lo")}
                  className={getCurrentLocale() === "lo"? "lang_selected": "lang_notselected"}>
                  LAO
                </p>
                <span className="lang_divider" />
                <p onClick={() => changeLanguage("en")}
                  className={getCurrentLocale() === "en"? "lang_selected": "lang_notselected"} >
                  EN
                </p>
              </span>
                <li>
                    <NavLink exact to="/" className='nav-link ' activeClassName='link-active'>
                       {translate('home')}  <hr className='active-border' />
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to='/about' className='nav-link' activeClassName='link-active' >
                    {translate('about')} <hr className='active-border' />
                    </NavLink>
                </li>
                <li >
                    <NavLink exact to='/onboarding' className='nav-link ' activeClassName='link-active'>
                    {translate('partnerResources')}   <hr className='active-border' />
                    </NavLink>
                    <ul className='dropdown-menu'  >
                        <li><NavLink to='/onboarding' className='menu-link '>{translate('drivers')}</NavLink></li>
                        <li><NavLink to='/onboarding' className='menu-link'>{translate('restaurants')}</NavLink></li>
                        <li><NavLink to='/onboarding' className='menu-link'>{translate('serviceShop')}</NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink exact to='/blog' className='nav-link ' style={{ width: 38 }} activeClassName='link-active'>
                    {translate('blog')}  <hr className='active-border' />
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navbar