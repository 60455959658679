import React, { useState,useEffect } from "react";
import LoginCard from '../login/components/LoginCard';
import JustCard from '../../components/justcard/JustCard'
import { login,registerNotificationToken } from '../../utils/apis/services/api';
import { withRouter } from "react-router-dom";
import { setUserSession } from "../../utils/common";

function Login(props) {

    useEffect(()=>{
        localStorage.removeItem("navOption")
        },[])

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const encode = (txt) => {
        let result = btoa(txt);
        return result;
      };
    const handleLogin = async (state,nature) => {
        setLoading(true);
        let { username, password } = state;
        let passwordInBase64 = btoa(password);
        let userData = { username_or_email: username.toLowerCase(), password: passwordInBase64,  "is_superadmin": true };
        
        if (nature && username !== "") {
            localStorage.username = username
            localStorage.password = password
            localStorage.rememberme = nature
        }
        else{
           localStorage.removeItem("username")
           localStorage.removeItem("password")
           localStorage.rememberme=nature
        }
        let result = await login (userData)
        if(result.data.ResponseCode === 200 && result.data.ErrorCode === null)
        {
            setUserSession(encode(result.data.ResponseData.key));
            let registertoken=await registerNotificationToken()
            setLoading(false);
            props.history.push('/dashboard')
        }
        else {
            setError(result.data.ResponseMessage);
            setLoading(false)
        }
      };
    return (
     
       <div className="flexRow">
        <JustCard />
        <LoginCard callBack={handleLogin} error={error} loading={loading} />
       </div>
      );

}
export default withRouter(Login);