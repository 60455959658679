import React, { useState } from "react";
import TextField from "../../../components/textfield/TextField";
import { withRouter, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../login.scss";
import Loader from "react-loader-spinner";

function LoginCard({ callBack, error, loading  }) {
   // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [state, setState] = useState({ username: "", password: ""});
  const [nature, setNature] = useState(localStorage.getItem("rememberme"));
  const history = useHistory()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = event => {
    event.preventDefault(); 
    callBack(state,nature);
  };
  

  return (
    <div
      className="login_card_container">
      <div className="login_sub_container">
        <h2 style={{ textAlign: "center", color: "#03A8EE", fontSize: 30 }}>
          {"Login"}
        </h2>
        {/* <p
          style={{ color: "grey", textAlign: "center" }}
          className="have_account_text"
        >
          Don't have an account? 
          <span style={{ color: "#03A8EE" }}>Create your account</span>
        </p> */}
        <div style={{ marginTop: 52 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              id={1}
              type="email"
              label="username"
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="username"
              value={state.username}
              error={
                state.username.length > 0 &&
                !re.test(state.username) &&
                "Email Error"
              }
              required={true}
            />

            <TextField
              id={2}
              type="password"
              label={"password"}
              predicted="California"
              locked={false}
              active={false}
              changeValue={handleChange}
              name="password"
              value={state.password}
              required={true}
            />

            {error && (
              <div>
                <p className="details_error" style={{ textAlign: "center" }}>
                  {error}
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className={nature ? "no_remember" : "remember"}
                  onClick={() => setNature(!nature)}>
                </div>
                <p style={{ color: "#BCBCBC", marginLeft: 20,width:148 }}className="have_account_text"> Remember Me</p>
              </div>
              <p onClick={()=>history.push('/forgotpassword')} style={{ cursor:"pointer",color: "#03A8EE" ,width:148}} className="have_account_text">
              Forgot Password?
              </p>
            </div>

            {/* <div className="login">
                <SmallButton title="Login" callBack={()=>callBack(username,password)}/>
                </div> */}
            { loading ?
              <div style={{ textAlign: "center" }}>
                <Loader
                  type="Bars"
                  color="#03A8EE"
                  height={100}
                  width={100}
                  timeout={100000000} 
                />
              </div>: <div className="button_center">
                <input className="mt-5 mr-5" type="submit" value="Login" />
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}
// const useFormInput = initialValue => {
//     console.log("sa;dlkas;ldlk;asdk")
//     const [value, setValue] = useState(initialValue);

//     const handleChange = e => {
//       setValue(e.target.value);
//     }
//     return {
//       value,
//       onChange: handleChange
//     }
//   }
export default withRouter(LoginCard);
