import React, { useEffect, useState } from 'react'
import Loader from "react-loader-spinner";
import queryString from "query-string";
import { useHistory } from 'react-router-dom';
import {checkDriverDocument} from '../../utils/apis/services/api'
const LoaderScreen = (props) => {
  // alert("props",props)
  const [loader,setLoading]=useState(false)
  const history = useHistory()
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true)
    console.log("loaderscreen")
    let params = queryString.parse(props.location.search);
    const { driver_id } = params;
    console.log(params);
    let result =await checkDriverDocument(driver_id)
    console.log(result)
    if(result.data.ResponseCode === 200 && result.data.ErrorCode === null)
    {
        setTimeout(() => {
          history.push({
            pathname: "/onboarding/professional",
            state: { "driverId": driver_id }
          });
        }, 2000);
    }
    else {
        setLoading(false)
        alert("This link is not more valid you already Uploaded the Document")
        history.push({ pathname: "/"});
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "center",
        flex: 1,
        position: "absolute",
        left: "50%",
        top: "40%",
      }}
    >
      <div>
      {
        loader ?
        <Loader
        type="Bars"
        color="#03A8EE"
        height={100}
        width={100}
        // timeout={100000000} //3 secs
      /> : 
      <div>
        <p style={{textAlign:"center",margin:"auto",fontSize:"x-large"}}>This link has expired or not more valid</p>
      </div>
      }
      </div>
    </div>
  );
}
export default LoaderScreen