import React, { useState, useEffect } from 'react';
import './Driver.scss'
import Header from '../header/Header';
import Button from '../../../components/button/Button';
import List from '../../../components/list/List';
import { useHistory } from 'react-router-dom';
import Titlebar from '../../../components/titlebar/Titlebar';
import { searchdriver, getDrivers ,getDriverssearch} from '../../../utils/apis/services/api';
import Pagination from "react-js-pagination";
import ContentLoader from "react-content-loader"
import  translate from '../../../utils/locale/language'
import { setDriverSelection,getDriverSelection,setPageSelection,getPageSelection,removeDriverSession} from "../../../../src/utils/common"

const Driver = (props) => {
  let [list, setList] = useState(1);
  const [title, setTitle] = useState(translate('database'));
  const [approvedDrivers, setApprovedDrivers] = useState([])
  const [OnHoldDrivers, setOnHoldDrivers] = useState([])
  const [sortlistedRequest, setSortlistedRequest] = useState([])
  const [requestListTmp, setRequestListTmp] = useState([]);
  const [requestListTmpApproved, setRequestListTmpApproved] = useState([]);
  const [requestListTmpOnHold, setRequestListTmpOnHold] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(1)
  var [activePage, setPage] = useState(1)


  const getSortlistedRequest = async (page_number) => {
    setPageSelection(page_number)
    setLoading(true)
    let is_promoted = true;
    let status="S"
    let result = await getDrivers(is_promoted, getPageSelection(),status);
    setLoading(false)
    setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
    setSortlistedRequest(result ? result.data.ResponseData.results : '')
    setRequestListTmp(result ? result.data.ResponseData : '')
  }

  const getApprovedDrivers = async (page_number)=>{
    setPageSelection(page_number)
    setLoading(true)
    let is_promoted = true;
    let status="A"
    let result = await getDrivers(is_promoted, getPageSelection(),status); 
    setLoading(false)
    setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
    setApprovedDrivers(result ? result.data.ResponseData.results : '')
    setRequestListTmpApproved(result ? result.data.ResponseData : '')
   }

   const getOnHoldDrivers = async (page_number,input)=>{
     console.log(input)
    setPageSelection(page_number)
    setLoading(true)
    let is_promoted = "";
    let status="H"
    let is_deactivated=true;
    let result = await getDrivers(is_promoted, getPageSelection(),status,is_deactivated,input); 
    setLoading(false)
    setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
    setOnHoldDrivers(result ? result.data.ResponseData.results : '')
    setRequestListTmpOnHold(result ? result.data.ResponseData : '')
   }

   const getOnHoldDriverssearch = async (input)=>{
    console.log(input)
   setLoading(true)
   let is_promoted = "";
   let status="H"
   let is_deactivated=true;
   let result = await getDriverssearch(is_promoted,status,is_deactivated,input); 
   setLoading(false)
   setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
   setOnHoldDrivers(result ? result.data.ResponseData.results : '')
   setRequestListTmpOnHold(result ? result.data.ResponseData : '')
  }

  const updateInput = async (input) => {
    console.log(input)
    setInput(input);
    let is_promoted=true;
    let status
    if(list===1||list===2){
       status = "S";
    }
      else if(list===3){
         status = "A";
      }

      else if(list===4){
        status = "H";
     } 
  var result = await searchdriver(input, is_promoted,status);
  if(status==="S")
  {
    if (input.length >= 1) {
      setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
      setSortlistedRequest(result.data.ResponseData ? result.data.ResponseData.results : '');
    }
    else if (result.data.ResponseCode === 200 && result.data.ErrorCode === 404) {
      setSortlistedRequest(requestListTmp.results)
      setCount(requestListTmp.count)
    }
    else {
      setSortlistedRequest(requestListTmp.results)
      setCount(requestListTmp.count)
    }
  }
  else if(status==="A")
  {
    if (input.length >= 1) {
      setCount(result.data.ResponseData.results ? result.data.ResponseData.count : '')
      setApprovedDrivers(result.data.ResponseData ? result.data.ResponseData.results : '');
    }
    else if (result.data.ResponseCode === 200 && result.data.ErrorCode === 404) {
      setApprovedDrivers(requestListTmpApproved.results)
      setCount(requestListTmpApproved.count)
    }
    else {
      setSortlistedRequest(requestListTmpApproved.results)
      setCount(requestListTmpApproved.count)
    }
  }
else {
  getOnHoldDriverssearch(input)
}
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    if(list===1||list===2){
      getSortlistedRequest(pageNumber)
   }else if(list==4){
    getOnHoldDrivers(pageNumber)
   }
    else{
      getApprovedDrivers(pageNumber)
    }
  }

  const handleRequestData = (data)=>{
    switch (data){
      case 1 :
      case 2:
        getSortlistedRequest(getPageSelection());
        break;
      case 3:
        getApprovedDrivers(getPageSelection());
        break;
      case 4:
        getOnHoldDrivers(getPageSelection());
        break;
      
  }
}

useEffect(()=>{
  const sideTab=localStorage.getItem("navOption");
  if(sideTab!=="/drivers"){
      setDriverSelection(1)
      setPageSelection(1)
      setList(1)
      setPage(1)
      getSortlistedRequest(1);
  }
},[])
  
  useEffect(() => {
   
    if(getPageSelection()){
      setPage(getPageSelection())
    }else{
      setPageSelection(1)
      setList(1)
      getSortlistedRequest(1);
    }
    if(getDriverSelection()){
      setList(getDriverSelection())
      handleRequestData(
      parseInt(getDriverSelection()))     
    }else{
      setList(1)
      setDriverSelection(1)
    }
    setList(getDriverSelection())
  }, [])



  const shortlisted = () => {
    setTitle(title);
    setList(1)
    setInput('')
    getSortlistedRequest(1);
    setDriverSelection(1);
    setPage(1)
  }
  const InReview = () => {
    setTitle(translate('requests'));
    setList(2)
    setInput('')
    getSortlistedRequest(1);
    setDriverSelection(2);
    setPage(1)
  }

  const OnHold = () => {
    setTitle(translate('requests'));
    setList(4)
    setInput('')
    getOnHoldDrivers(1);
    setDriverSelection(4);
    setPage(1)
  } 

  const Approved = () => {
    setList(3)
    setInput('')
    getApprovedDrivers(1)
    setDriverSelection(3);
    setPage(1)
  }

  const history = useHistory();

  const partnerlist = (selected) => {
    switch (selected) {
      case 1:
        return <List from="driver"  data={sortlistedRequest} onClick={() => history.push('/drivers/promoted')} />;
      case 2:
        return <List from="driver" data={sortlistedRequest} onClick={() => history.push("/drivers/inreview")} />;
      case 3:
        return <List from="driver" status="approved" data={approvedDrivers} onClick={() => history.push("/drivers/inreview")} />;
      case 4:
        return <List from="driver" status="OnHold" data={OnHoldDrivers} onClick={() => history.push("/drivers/inreview")} />;
      default:
        return <List from="driver" data={sortlistedRequest} onClick={() => history.push('/drivers/promoted')} />;
    }
  };


  let loadshimmer = <ContentLoader
  speed={2}
  width={"1000px"}
  height={750}
  backgroundColor="#f3f3f3"
  foregroundColor="#ecebeb"
  style={{marginLeft:-29,marginTop:-25}}
>
    <rect x="51" y="45" rx="3" ry="3" width="1006" height="17" />
    <circle cx="879" cy="123" r="11" />
    <circle cx="914" cy="123" r="11" />
    <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
    <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
    <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="155" rx="3" ry="3" width="997" height="2" />
    <circle cx="880" cy="184" r="11" />
    <circle cx="915" cy="184" r="11" />
    <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
    <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
    <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
    <rect x="56" y="216" rx="3" ry="3" width="997" height="2" />
    <circle cx="881" cy="242" r="11" />
    <circle cx="916" cy="242" r="11" />
    <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="274" rx="3" ry="3" width="997" height="2" />
    <circle cx="882" cy="303" r="11" />
    <circle cx="917" cy="303" r="11" />
    <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
    <rect x="58" y="335" rx="3" ry="3" width="997" height="2" />
    <circle cx="881" cy="363" r="11" />
    <circle cx="916" cy="363" r="11" />
    <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="395" rx="3" ry="3" width="997" height="2" />
    <circle cx="882" cy="424" r="11" />
    <circle cx="917" cy="424" r="11" />
    <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="453" rx="3" ry="3" width="997" height="2" />
    <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
    <rect x="997" y="49" rx="3" ry="3" width="2" height="465" />
    <circle cx="882" cy="484" r="11" />
    <circle cx="917" cy="484" r="11" />
    <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="513" rx="3" ry="3" width="997" height="2" />
    <rect x="52" y="80" rx="3" ry="3" width="1006" height="17" />
    <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
    <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
    <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
    <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
    <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  return (
    <div className="drivers">
      <Header title={translate('drivers')} />
      <div className='drivers-header'>
        <p className='partner-database'>{translate('partnerDatabse')}</p>
        <Button title={translate('addNewDriver')} onClick={() => history.push('/drivers/becomeadriver')} style={{ width: 177, height: 35 }} />
      </div>
      <Titlebar onClick1={shortlisted} onClick2={InReview} onClick3={Approved} onClick5={OnHold} input={input} onClick4={updateInput}
        active={list} title1={translate('shortlisted')} title2={translate('inReview')} title3={translate('approved')} title5={translate('OnHold')}
      />

      {!loading ?
        <div>
          <section className="shortlisted">

            {
              partnerlist(list)
            }
          </section>
          {  count>10 ?<Pagination
             firstPageText={<i class="fa fa-angle-left" aria-hidden="true"></i>}
             prevPageText={<i class="fa fa-angle-double-left" aria-hidden="true"></i>}
             lastPageText={<i class="fa fa-angle-right" aria-hidden="true"></i>}
             nextPageText={<i class="fa fa-angle-double-right" aria-hidden="true"></i>}
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={count}
            onChange={handlePageChange}
            activeClass="active"
            activeLinkClass="active"
            hideDisabled
          /> :''}
        </div>
        : <section className="shortlisted">

        {loadshimmer}
        </section>
      }
    </div>
  )
}

export default Driver