import React, { useState } from "react";
import JustCard from '../../components/justcard/JustCard'
import { resetpassword } from '../../utils/apis/services/api';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetpasswordCard from "./components/resetpasswordCard";
import { useHistory } from 'react-router-dom';

function Resetpassword(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();
    const Id= history.location.state;
console.log(Id)

    const handleReset = async (state) => {
        setLoading(true);
        let { username,password} = state;
        let passwordInBase64 = btoa(password);
        let userData = {email: username.toLowerCase(),password:passwordInBase64,token:Id.token,"is_superadmin": true };
        let result = await resetpassword (userData)
        if(result.data.ResponseCode === 200 && result.data.ErrorCode === null)
        {
            setLoading(false);
            toast.success("Your Password reset successfully use these credentials now to login")
            history.push('/login')
        }
        else {
            setError(result.data.ResponseMessage);
            setLoading(false)
        }
      };
    return (
     
       <div className="flexRow">
        <JustCard />
        <ResetpasswordCard callBack={handleReset} error={error} loading={loading} />
        <ToastContainer/>
       </div>
      );

}
export default withRouter(Resetpassword);