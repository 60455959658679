import React, { useState } from 'react'
import './BecomeDriver.scss'
import Button from '../../../../components/button/Button'
import CheckBox from '../../../../components/checkbox/CheckBox'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import translate  from '../../../../utils/locale/language'
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import Footer from '../../../../components/footer/Footer'


const BecomeDriver = () => {
  let history = useHistory()
 const [checkboxValue, setCheckboxValue]= useState(false)
 const handleChange = (event) => {
  console.log(event.target.checked, event.target.name);
  setCheckboxValue(event.target.checked)
};
const showAlert = () => {
  if(checkboxValue)
  {
    history.push('onboarding/details')
  }
  else
  {
    toast.warning(translate('selectTandC'));
  }
 
} 

  
  return (
    <>
        <div className='header'>
          <p className='just'>Just</p>
          <div className='header-nav'>Become a partner</div>
        </div> 

    <div className='become-driver'>
      {/* <ToastContainer/> */}
      <section className='become-driver-container'>
      <section className='get-started'>
        <div>
                    <div style={{display:'flex',cursor:'pointer'}} onClick={() => history.push({ pathname: "/"})} >
                    <KeyboardBackspaceSharpIcon className='terms-titles' style={{marginTop:15,marginRight:10}}/>
                    <p className='terms-titles'style={{width:"35%",textAlign:"left"}} >{translate('back')}</p>
                    </div>
                   <div className='get-started-heading'>{translate('letsGetStarted')}</div>
        </div>
  <div className='get-started-description'>{translate('hugeleap')}</div>
        </section>
        <section className='info'>
          <p className='info-heading'>{translate('just')} - {translate('becomeADriver')}</p>
          <p>
            {translate('signupprocess')}
          </p>
          <p>{translate('completeonboarding')}</p>
          <p>{translate('documentsRequired')}
               <a style={{ textDecoration: 'none', color: '#03A8EE' }} href="https://just.la/documents"> https://just.la/documents </a>
          </p>
        </section>
        <section className='disclaimer'>
          <p className='d-heading'>{translate('disclaimer')}</p>
          <p className="d-details">
          {translate('duringThisPeriod')}</p>
        </section>
        <section className='t-c'>
          <p className="tc-title">{translate('termsAndConditions')}</p>
          <CheckBox onChange={handleChange} checked={false} type='checkbox'>{translate('agreeTandC')}
          <span style={{ marginLeft: 250, color: '#03A8EE' }} onClick={() => history.push('onboarding/terms')}>
          {translate('readTandC')}  {console.log(checkboxValue)}
          </span>
          </CheckBox>
        </section>
        <section className='next'>
          <Button disabled={checkboxValue} style={{ width: 104, height: 46 }} title={translate('next')} onClick={() => showAlert()} />
          {/* <button className='button'  style={{ width: 104, height: 46 }} type="button"  onClick={() =>  }>Next</button> */}
       
        </section>
      </section>
      <Footer/>
    </div>
    </>
  )
}

export default BecomeDriver