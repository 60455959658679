import React, { useState } from 'react'
import './BecomeADriver.scss'
import Button from '../../../../../components/button/Button'
import CheckBox from '../../../../../components/checkbox/CheckBox'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../../header/Header';
import  translate from '../../../../../utils/locale/language'

const BecomeADriver = () => {
  let history = useHistory()
 const [checkboxValue, setCheckboxValue]= useState(false)

 const handleChange = (event) => {
  console.log(event.target.checked, event.target.name);
  setCheckboxValue(event.target.checked)
};

const showAlert = () => {
  toast.dismiss();
 const popupData =  {
   title: "Terms & conditions",
   message: "Please select terms & conditions."}
  if(checkboxValue)
  {
    history.push('/drivers/adddriver')
  }
  else
  {
    toast.warning(popupData.message);
  }
 
} 
  
  return (
   <>
    <div className="add-new-driver"><Header title="Drivers" /></div> 
     <div>
     <span class="color-grey">{translate('addNewDriver')}</span>
     <span class="step2of3">{translate('step')} 1 Of 3</span>
     </div>
    <div className='become-a-driver'>
      <section className='become-driver-container'>
      <section className='next'>
        <button onClick={() => history.push('/drivers')}  className='back' type="button"   >{translate('back')}</button>
        <div  onClick={() => showAlert()}> <Button disabled={checkboxValue} style={{ width: 104, height: 46, marginLeft: "12px"}}  title={translate('next')}  /></div>
        </section>
        <section className='get-started'>
  <div className='get-started-heading'>{translate('letsGetStarted')}</div>
  <div className='get-started-description'>{translate('hugeleap')}</div>
        </section>
        <section className='info'>
          <p className='info-heading'>{translate('just')} - {translate('becomeADriver')}</p>
          <p>
            {translate('signupprocess')}
          </p>
          <p>{translate('completeonboarding')}</p>
          <p>{translate('documentsRequired')}
               <a style={{ textDecoration: 'none', color: '#03A8EE' }} href="https://just.la/documents"> https://just.la/documents </a>
          </p>
        </section>
        <section className='disclaimer'>
          <p className='d-heading'>{translate('disclaimer')}</p>
          <p className="d-details">
          {translate('duringThisPeriod')}</p>
        </section>
        <section className='t-c'>
          <p className="tc-title">{translate('termsAndConditions')}</p>
          <CheckBox onChange={handleChange} checked={false} type='checkbox'>
            {translate('agreeTandC')}
          <span style={{ float: "right", color: '#03A8EE' }} onClick={() => history.push('/drivers/terms')}>
          {translate('readTandC')}  {console.log(checkboxValue)}
          </span>
          </CheckBox>
        </section>
      </section>
    </div>
    </>
  )
}

export default BecomeADriver