import React, { useEffect, useState } from "react";
import './dashboard.scss'
import { BrowserRouter as Router, NavLink, useHistory,useLocation } from "react-router-dom";
import Panel from "../../../routes/Panel";
import dashboard from '../../../assets/images/panel/dashboard.svg'
import enquiry from '../../../assets/images/panel/enquiry.svg'
import drivers from '../../../assets/images/panel/drivers.svg'
import payment from '../../../assets/images/panel/payment.svg'
import product from '../../../assets/images/panel/product.svg'
import productfill from '../../../assets/images/panel/productfill.svg'
import dashboardfill from '../../../assets/images/panel/dashboardfill.svg'
import enquiryfill from '../../../assets/images/panel/enquiryfill.svg'
import driversfill from '../../../assets/images/panel/driverfill.svg'
import paymentfill from '../../../assets/images/panel/paymentfill.svg'
import ordersfill from '../../../assets/images/panel/ordersfill.svg'
import orders from '../../../assets/images/panel/orders.svg'
import admin from '../../../assets/images/panel/admin.svg'
import adminfill from '../../../assets/images/panel/adminfill.svg'
import logout from '../../../assets/images/panel/logout.svg'
import { removeUserSession } from "../../../utils/common";
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import translate, { getCurrentLocale, setLocale } from "../../../utils/locale/language"
import { ToastContainer } from "react-toastify";
import Button from "../../../components/button/Button";
import Lottie from "react-lottie";
import driverLottie from '../../../assets/lottie/driver_lottie.json';

function Dashboard() {
  // eslint-disable-next-line  no-unused-vars
  const getNavOption = localStorage.getItem("navOption")
  const [aniamtion,setAnimation]=useState("")
  const [link,setLink]=useState(!getNavOption?'/dashboard':'')
 
  const logOut = () => {
    confirmAlert({
      title: "Do you sure want to exit ?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            removeUserSession();
            history.push("/login");
          }},
        { label: 'No' }
      ]
    })
  }

  let [isActive, setisActive] = useState('');
  const history = useHistory();
  const location = useLocation()

  
  useEffect(()=>{
    
    let lang=window.localStorage.getItem('lang')
    console.log(lang)
    setLocale(lang)
    window.scrollTo(0, 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getCurrentLocale()])

  useEffect(()=>{
   
    window.scrollTo(0, 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])

  
  useEffect(()=>{
    if(link){
    localStorage.setItem("navOption",link)
    }
  },[link])


  const animateBack=()=>{
    history.goBack()
  }
  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: driverLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const myFun=(val)=>{
  setLink(val)
  }

  return (
    <Router>
       <ToastContainer autoClose={1800} />
      <div  className="sidebar">
        <div  onClick={(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?()=>animateBack():''} className={(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?'sidebardisabled sidebar-block':"sidebar-block"}>
        <div style={{pointerEvents:(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?"none":'inherit',display:"flex",flexDirection:"column"}}>
        <NavLink style={{textDecoration:"none"}}  to="/dashboard" >
          <p className='user-name' onClick={()=>history.push("/dashboard")}>{translate('just')}</p>
        </NavLink>
          <ul className="panel">
            <li className={isActive.match(/\/dashboard/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/dashboard")}>
              <NavLink activeClassName={isActive.match(/\/dashboard/g) ? "activenav" : ''} isActive={(match, location) => setisActive(location.pathname)}  className='panel-list' to="/dashboard" >
                <img src={isActive.match(/\/dashboard/g) ? dashboardfill : dashboard} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/dashboard/g) ? "activetext" : ''}>{translate('dashboard')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/enquiry/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/enquiry")}>
              <NavLink activeClassName="active" isActive={(match, location) => setisActive(location.pathname)}  className='panel-list' to="/enquiry" >
                <img src={isActive.match(/\/enquiry/g) ? enquiryfill : enquiry} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/enquiry/g) ? "activetext" : ''}>{translate('onlineEnquiry')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/drivers/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/drivers")}>
              <NavLink activeClassName="active" isActive={(match, location) => setisActive(location.pathname)}   className='panel-list' to="/drivers" >
                <img src={isActive.match(/\/drivers/g) ? driversfill : drivers} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/drivers/g) ? "activetext" : ''}>{translate('drivers')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/payments/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/payments")}>
              <NavLink activeClassName="active" isActive={(match, location) => setisActive((location.pathname))}   className='panel-list' to="/payments" >
                <img src={isActive.match(/\/payments/g) ? paymentfill : payment} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/payments/g) ? "activetext" : ''}>{translate('payments')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/orders/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/orders")}>
              <NavLink activeClassName="active" isActive={(match, location) => setisActive(location.pathname)}   className='panel-list' to="/orders" >
                <img src={isActive.match(/\/orders/g) ? ordersfill : orders} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/orders/g) ? "activetext" : ''}>{translate('orders')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/superadmin/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/superadmin")}>
              <NavLink activeClassName="active" className='panel-list' to="/superadmin" >
                <img src={isActive.match(/\/superadmin/g) ? admin : adminfill} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/superadmin/g) ? "activetext" : ''}>{translate('superAdmin')}</p>
              </NavLink>
            </li>
            <li className={isActive.match(/\/products/g) ? "panel-list-title activenav" : 'panel-list-title'} onClick={()=>myFun("/products")}>
              <NavLink activeClassName="active" className='panel-list' to="/products" >
                <img src={isActive.match(/\/products/g) ? product : productfill} alt='icon' className="panel-icon" />
                <p className={isActive.match(/\/products/g) ? "activetext" : ''}>{translate('products')}</p>
              </NavLink>
            </li>

            <li onClick={() => logOut()} className='logout'>
            <img src={logout} alt='icon' className="panel-icon" />
            {translate('logout')}</li>
          </ul>
        
          <p className='version'>
            Version 1.0
          </p>
        </div>
        </div>
        {(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?
        <div className="blurback"></div>:""}
        <section className={(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?'panel-right-section-child'+aniamtion:"panel-right-section"} >
         {(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?
        <div className="driverLottie" ><Lottie  options={defaultOptions} height={300} width={300} className="driverLottie" /></div> :''}
         <div className="headerback">
           {(window.location.pathname==="/orders/details"||window.location.pathname==="/superadmin/details"||window.location.pathname==="/enquiry/details"||window.location.pathname==="/drivers/details"||window.location.pathname==="/products/details")?
           <Button onClick={()=>animateBack()} style={{height:66,marginBottom:10,background:"transparent",color:"#002D44"}} back={true} title={""} />:''}
         </div>
          <Panel />
        </section>
      </div>
    </Router>
  );
}

export default  withRouter(Dashboard)
