import React from 'react'
import './Card.scss'
import Button from '../button/Button'

const Card = (props) => {
  return (
    <div className='card' style={props.style}>
      <img style={props.imgStyle} className="card-img" src={props.src} alt="img" />
      <section className='card-section' style={props.contentStyle} >
        <hr />
        <div>
          <summary>{props.heading}</summary>
          <p className="about">{props.children}</p>
        </div>
        <Button className = 'buttonWidth' title="Know More" />
      </section>
    </div>
  )
}

export default Card