import React, { useState, useEffect, useCallback } from 'react'
import './editDriver.scss'
import Dropzone from 'react-dropzone';
import photo from '../../../../../assets/images/photo.png'
import folder from '../../../../../assets/images/folder.png'
import trash from '../../../../../assets/images/trash.png'
import { useHistory, withRouter } from 'react-router-dom';
import { uploadDocuments,deleteFile } from '../../../../../utils/apis/services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import * as Yup from 'yup'
import { editDriver } from '../../../../../utils/apis/services/api';
import Loader from 'react-loader-spinner';
import  translate from '../../../../../utils/locale/language'
import moment from "moment"
const EditDriver = (props) => {

  const [file, setFiles] = useState();
  const [profilePhotoName, setProfilePhotoName] = useState()
  const [NRICFile, setNRICFiles] = useState([]);
  const [NRICFilenew, setNRICFilesnew] = useState([]);
  const [license, setLicense] = useState([]);
  const [licensenew, setLicensenew] = useState([]);

  const [loader, setLoader] = useState(false)
  const [uploadloader, setUploadLoader] = useState(false)
  // eslint-disable-next-line  no-unused-vars
  const [driverData, setDriverData] = useState({ ...props.docList })
  const [editFormData,setFormData]=useState({})

  const history = useHistory();
  const reader = new FileReader();
  const formData = new FormData();
  const maxFileSize = {
    title: "Document Upload",
    message: "File size greater than 1 MB, please upload less size of 1 MB"
  }

  const uploadImage = async () => {
    setUploadLoader(true)
    let Id = history.location.state;
    console.log(Id)
    formData.append("passport_photo", file&&(file!==undefined||null) ? file :'' );
    formData.append("passport_photo_filename", profilePhotoName && profilePhotoName.length > 0 ? profilePhotoName ? profilePhotoName : '' : '');
    formData.append("nric_front", NRICFilenew.length > 0 ? NRICFilenew[0].image ? NRICFilenew[0].image : '' : '');
    formData.append("nric_front_filename", NRICFilenew.length > 0 ? NRICFilenew[0].name ? NRICFilenew[0].name : '' : '');
    formData.append("nric_back", NRICFilenew.length > 1 ? NRICFilenew[1].image ? NRICFilenew[1].image : '' : '');
    formData.append("nric_back_filename", NRICFilenew.length > 1 ? NRICFilenew[1].name ? NRICFilenew[1].name : '' : '');
    formData.append("licsence_front", licensenew.length > 0 ? licensenew[0].image ? licensenew[0].image : '' : '');
    formData.append("licsence_front_filename", licensenew.length > 0 ? licensenew[0].name ? licensenew[0].name : '' : '');
    formData.append("licsence_back", licensenew.length > 1 ? licensenew[1].image ? licensenew[1].image : '' : '');
    formData.append("licsence_back_filename", licensenew.length > 1 ? licensenew[1].name ? licensenew[1].name : '' : '');
    formData.append("driver_id", Id.driverId && Id.driverId)
    console.log(formData)
    let result = await uploadDocuments(formData);
    console.log(result.data.ResponseData)
    if (result.data.ResponseMessage === "Documents are uploaded.") {
      setUploadLoader(false)
      const popupData = {
        title: "Professional Details",
        message: "Documents are uploaded."
      }
      toast.dismiss()
      toast.success(popupData.message)
      props.resetDriverDetails()
      props.modalclose()
        }
    else {
      setUploadLoader(false)
      const popupData = {
        title: "Professional Details",
        message: result.data.ResponseMessage
      }
      toast.error(popupData.message)
    }
  }

  const onSubmit = async => {
    let Id = history.location.state;
    if(profilePhotoName!==null||NRICFilenew[0]||licensenew[0])
    {
      uploadImage()
    }
    updateDriverDetails(editFormData, Id)
  }

  const DeleteDocument=async (name)=>
  {
    setLoader(true)
    let result = await deleteFile([name]);
    if (result.data.ResponseMessage === "success"&&result.data.ResponseCode===200) {
      setLoader(false)
      toast.success("File SuccessFully deleted.")
      props.resetDriverDetails()}
    else {
      setLoader(false)
      toast.warn(result.data.ResponseMessage)
    }
  }

  const handleRemoveNricItems =( idx,name) => {
    const temp = [...NRICFile];
    temp.splice(idx, 1);
    setNRICFiles(temp);
    const temp2 = [...NRICFilenew];
    temp2.splice(idx, 1);
    setNRICFilesnew(temp2);
    console.log(name)
    console.log(driverData.file_data.nric_work_permit.names_of_files)
      // eslint-disable-next-line  array-callback-return
  driverData.file_data.nric_work_permit.names_of_files.find(str=>
 { if(str===name)
  DeleteDocument(name)}
  );
  }

  const handleRemoveDlItems = (idx,name) => {
    const temp = [...license];
    temp.splice(idx, 1);
    setLicense(temp);
    const temp2 = [...licensenew];
    temp2.splice(idx, 1);
    setLicensenew(temp2);
    // eslint-disable-next-line  array-callback-return
    driverData.file_data.driver_licsence.names_of_files.find(str=>
      { if(str===name)
       DeleteDocument(name)}
       );
  }

  const acceptedFiles = useCallback((acceptedFiles) => {
    reader.onload = () => {
      if (reader.readyState === 2 && (acceptedFiles[0].size)< 1048576) {
        setFiles(reader.result)
        setProfilePhotoName(acceptedFiles[0].path)
      }
      else {
        toast.warning(maxFileSize.message)
      }
    }
    reader.readAsDataURL(acceptedFiles[0])
   // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [maxFileSize][setFiles]);

  const driverLicense = useCallback((acceptedFiles) => {
    reader.onload = () => {
      if (reader.readyState === 2 && (acceptedFiles[0].size)< 1048576) {
        setLicensenew(state => [...state, { image: reader.result, name: acceptedFiles[0].path, size: acceptedFiles[0].size, date: acceptedFiles[0].lastModifiedDate }])
        setLicense(state => [...state, { image: reader.result, name: acceptedFiles[0].path, size: acceptedFiles[0].size, date: acceptedFiles[0].lastModifiedDate }])
      }
      else {
        toast.warning(maxFileSize.message)
      }
    }
    reader.readAsDataURL(acceptedFiles[0]);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [setLicensenew]);

  const driverNRIC = useCallback((acceptedFiles) => {
    reader.onload = () => {
      if (reader.readyState === 2&& (acceptedFiles[0].size)< 1048576) {
        setNRICFilesnew(state => [...state, { image: reader.result, name: acceptedFiles[0].path,size: acceptedFiles[0].size }])
        setNRICFiles(state => [...state, { image: reader.result, name: acceptedFiles[0].path,size: acceptedFiles[0].size }])
      }
      else {
        toast.warning(maxFileSize.message)
      }
    }
    reader.readAsDataURL(acceptedFiles[0]);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [setNRICFiles]);

  const licencelist = license.map((list, index) => (
    <div key={index + 'DL'}>
      <div className="uploded-file"  >
        <div className='file-details'>
          <img src={list.image} style={{ width: 23, height: 28, marginRight: 10 }} alt='' />
          <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              <p style={{ margin: 0 }}>{list.size+' bytes'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
        </div>
        <img onClick={() => handleRemoveDlItems(index,list.name)} src={trash} style={{ width: 22, height: 22 }} alt='' />
      </div>
    </div>
  ));

  const NRIClist = NRICFile.map((list, index) => (
    <div key={index + 'NRIC'}>
      <div className="uploded-file"  >
        <div className='file-details'>
          <img src={list.image} style={{ width: 23, height: 28, marginRight: 10 }} alt='' />
          <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              {console.log(list)}
              <p style={{ margin: 0 }}>{list.size+' bytes'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
        </div>
        <img onClick={() => handleRemoveNricItems(index,list.name)} src={trash} style={{ width: 22, height: 22 }} alt='' />
      </div>
    </div>
  ));

  useEffect(() => {
    setLicense(driverData.file_data.driver_licsence.url[0] ? state=>[...state,{image:driverData.file_data.driver_licsence.url[0],name:driverData.file_data.driver_licsence.names_of_files[0],size:driverData.file_data.driver_licsence.size_of_files[0],date:driverData.file_data.driver_licsence.date_of_file[0]}] : [])
    // setLicense(driverData.file_data.driver_licsence.url[1] ? state=>[...state,{image:driverData.file_data.driver_licsence.url[1],name:driverData.file_data.driver_licsence.names_of_files[1],size:driverData.file_data.driver_licsence.size_of_files[1],date:driverData.file_data.driver_licsence.date_of_files[1]}] : state=>[...state])
    setNRICFiles(driverData.file_data.nric_work_permit.url[0] ? state=>[...state,{image:driverData.file_data.nric_work_permit.url[0],name:driverData.file_data.nric_work_permit.names_of_files[0],size:driverData.file_data.nric_work_permit.size_of_files[0],date:driverData.file_data.nric_work_permit.date_of_file[0]}] : [])
    // setNRICFiles(driverData.file_data.nric_work_permit.url[1] ? state=>[...state,{image:driverData.file_data.nric_work_permit.url[1],name:driverData.file_data.nric_work_permit.names_of_files[1],size:driverData.file_data.nric_work_permit.size_of_files[1],date:driverData.file_data.nric_work_permit.date_of_files[1]}] : state=>[...state])
      // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [driverData.file_data])


  const updateDriverDetails = async (data, Id) => {
   if(Object.keys(data).length === 0 && data.constructor === Object)
   {
    if(profilePhotoName==null&&!NRICFilenew[0]&&!licensenew[0])
    {
    props.modalclose()
   }
   }
else{
  setLoader(true)
  let result = await editDriver(data, Id.driverId);
  console.log(result)
  if (result.data.ResponseMessage === "Updated") {
    setLoader(false)
    const popupData = {
      title: "Update Driver Details",
      message: "Details updated successfully"
    }
    toast.dismiss()
    setTimeout(toast.success(popupData.message),20000)
    props.resetDriverDetails()
    props.modalclose()
  }
  else {
    setLoader(false)
    const popupData = {
      title: "Update Driver Details",
      message: result.data.ResponseMessage
    }
    toast.dismiss()
    toast.error(popupData.message)
  }
}
  }
 
 const handleChange = values => {
   let datas = {
    "user": {
      "phone_number": values.number ? values.number : initialValues.number,
      "email": values.email ? values.email.toLowerCase() : initialValues.email.toLowerCase(),
      "full_name": values.name ? values.name : initialValues.name,
      "address": values.address ? values.address : initialValues.address
    },
    "partner_type": "driver"
  }
  console.log(datas)
  setFormData(datas)
 }

  const initialValues = {
    type: driverData.partner_type ? driverData.partner_type : '',
    name: driverData.full_name ? driverData.full_name : '',
    email: driverData.email ? driverData.email : '',
    number: driverData.phone_number ? driverData.phone_number : '',
    address: driverData.address ? driverData.address : ''
  }
  const validationSchema = Yup.object({
    name: Yup.string().min(2, "Mininum 2 characters").max(30, "Maximum 30 characters").required("required"),
    email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Invalid email address").required("required"),
    number: Yup.string().matches(/^[0-9]{10}$/, 'Phone No. must be a number of 10 digits').required('required'),
    address: Yup.string().required("required").min(8, "Mininum 8 characters").matches(/^[ A-Za-z0-9_./,-]*$/,"Specail characters are not allowed"),
  })

  return (
    <div style={{width:"100%"}}>
      <div>
  <p className="editDriverlabel">{translate('editDriver')}</p>
        <i className="fa fa-close" aria-hidden="true" onClick={props.modalclose}  style={{cursor:"pointer",float: "right", fontSize:25, marginRight: "55px" }}></i>
      </div>
      {
        loader||uploadloader ? <Loader color="#03A8EE" className='loaderedit'></Loader> :
          <div>
            <div className="main">
              <div className="detailsform">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {({ values,errors, touched }) => {
                    return <Form className='form' onBlur={()=>handleChange(values)}>

                      <div className={touched.name && errors.name ? "input-field field-line" : "input-field "}>
                        <label htmlFor="name" className='input-label'>{translate('fullName')}</label>
                        <Field autoComplete="off" className={touched.name && errors.name ? "input line" : "input "}
                          type='text'
                          id="name"
                          name='name'
                          placeholder={translate('fullNamePlaceHolder')}
                        />
                        <ErrorMessage name='name' >
                          {
                            (err) => <div className='err'>{err}</div>
                          }
                        </ErrorMessage>
                      </div>
                      <div className={touched.email && errors.email ? "input-field field-line" : "input-field "}>
                        <label htmlFor="email" className='input-label'>{translate('emailAddress')}</label>
                        <Field autoComplete="off" className={touched.email && errors.email ? "input line" : "input "}
                          type='text'
                          id="email"
                          name='email'
                          placeholder={translate('emailPlaceholder')}
                        />
                        <ErrorMessage name='email' >
                          {
                            (err) => <div className='err'>{err}</div>
                          }
                        </ErrorMessage>
                      </div>

                      <div className={touched.number && errors.number ? "input-field field-line" : "input-field "}>
                        <label htmlFor="email" className='input-label'>{translate('phoneNumber')}</label>
                        <Field autoComplete="off" className={touched.number && errors.number ? "input line" : "input "}
                          type='tel'
                          id="number"
                          name='number'
                          placeholder={translate('phoneNumberPlaceholder')}
                        />
                        <ErrorMessage name='number' >
                          {
                            (err) => <div className='err'>{err}</div>
                          }
                        </ErrorMessage>
                      </div>
                      <div className={touched.address && errors.address ? "input-field field-line" : "input-field "}>
                        <label htmlFor="email" className='input-label'>{translate('currentAddress')}</label>
                        <Field autoComplete="off"
                          className={touched.address && errors.address ? "input line" : "input "}
                          type='text'
                          id="address"
                          name='address'
                          placeholder={translate('currentAddressPlaceholder')} />
                        <ErrorMessage name='address' >
                          {
                            (err) => <div className='err'>{err}</div>
                          }
                        </ErrorMessage>
                      </div>
                      <button type="submit" className='button savebutton'>save</button>
                    </Form>
                  }}
                </Formik>
              </div>

              <div className="p-details">
                <section className='drag-drop-section'>
                  <p className="smallsizep" style={{ color: '#002D44', fontSize: 26, marginBottom: 10 }}>{translate('passportPhoto')}</p>
                  <div className='drag-drop-container-passport' style={{ display: "flex" }}>
                    {
                        driverData.file_data.passport_photo.url&&file==null ? <img src={driverData.file_data.passport_photo.url} className='passport' alt='' />
                          :  <img src={file!==undefined? file : photo} className='passport' alt='' />
                    }
                    <Dropzone onDrop={acceptedFiles} accept=".jpg,.jpeg,.png,">
                      {({ getRootProps, getInputProps}) => 
                      {
                      return (
                        <section className='drag-drop-block-passport'>
                          <div {...getRootProps()} className='drag-drop' >
                            <input {...getInputProps()} />
                            <div className='folder'>
                              <img style={{ width: 32, height: 30, marginRight: 13 }} src={folder} alt='' />
                              <div>
                                <p className='file-name '>{translate('dragAndDrop')} </p>
                                <p className='format'>Formats png, jpg, jpeg, max 1 mb </p>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}}
                    </Dropzone>
                  </div>
                </section>
                <div className='drag-drop-container'>
                  <p className="smallsizep" style={{ color: '#002D44', fontSize: 25, marginBottom: 10 }}>
                  {translate('nricPlaceholder')}</p>
                  <Dropzone onDrop={NRICFile.length >=2 ? ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}:driverNRIC}  multiple={true} maxFiles={2} canRemove={true} accept=".jpg,.jpeg,.png,">
                    {({ getRootProps, getInputProps }) => (
                      <section className='drag-drop-block'>
                        <div {...getRootProps()} className='drag-drop' >
                          {NRICFile.length < 2 ? <input {...getInputProps()} multiple={true} /> : ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}}
                          <div className='folder'>
                            <img style={{ width: 32, height: 30, marginRight: 13 }} src={folder} alt='' />
                            <div>
                              <p className='file-name'>{translate('dragAndDrop')} </p>
                              <p className='format'>Formats png, jpg, jpeg, max 1 mb </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {NRIClist}
                  
                </div>
                <div className='drag-drop-container'>
                  <p className="smallsizep" style={{ color: '#002D44', fontSize: 25, marginBottom: 10 }}>
                  {translate('dlPlaceholder')}</p>
                  <Dropzone onDrop={license.length >=2 ? ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}:driverLicense} multiple accept=".jpg,.jpeg,.png,">
                    {({ getRootProps, getInputProps }) => (
                      <section className='drag-drop-block'>
                        <div {...getRootProps()} className='drag-drop' >
                          {license.length < 2 ? <input {...getInputProps()} /> : ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}}
                          <div className='folder'>
                            <img style={{ width: 32, height: 30, marginRight: 13 }} src={folder} alt='' />
                            <div>
                              <p className='file-name'>{translate('dragAndDrop')} </p>
                              <p className='format'>Formats , png, jpg, jpeg, max 1 mb </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {licencelist}
                </div>
              </div>
            </div>
            <div className="savebutton" >
            </div>
          </div>
      }
    </div>
  )

}

export default withRouter(EditDriver)