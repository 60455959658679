import React, { useState, useEffect, useCallback } from 'react'
import { useHistory} from 'react-router-dom';
import "./uploadDocument.scss"
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import folder from '../../assets/images/folder.png'
import trash from '../../assets/images/trash.png'
import { uploadDocuments,deleteFile } from '../../utils/apis/services/api';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner';
import  translate from '../../utils/locale/language'
import Button from '../button/Button';
import moment from 'moment';

const UploadDocument = props => {
    const history = useHistory();
    const [NRICFile, setNRICFiles] = useState([]);
    const [NRICFilenew, setNRICFilesnew] = useState([]);
    const [license, setLicense] = useState([]);
    const [licensenew, setLicensenew] = useState([]);
    const [loader, setLoader] = useState(false)
    const [uploadloader, setUploadLoader] = useState(false)
    // eslint-disable-next-line  no-unused-vars
    const [driverData, setDriverData] = useState({ ...props.docList })
    const reader = new FileReader();
    const formData = new FormData();
    const maxFileSize = {
      title: "Document Upload",
      message: "File size greater than 10 MB, please upload less size of 10 MB"
    }
    const uploadImage = async () => {
      if(!NRICFilenew[0]&&!licensenew[0])
      {
       toast.warn("You didn't upload any new document")      
     }
     else{
      setUploadLoader(true)
      let Id = history.location.state;
      formData.append("nric_front", NRICFilenew.length > 0 ? NRICFilenew[0].image ? NRICFilenew[0].image : '' : '');
      formData.append("nric_front_filename", NRICFilenew.length > 0 ? NRICFilenew[0].name ? NRICFilenew[0].name : '' : '');
      formData.append("nric_back", NRICFilenew.length > 1 ? NRICFilenew[1].image ? NRICFilenew[1].image : '' : '');
      formData.append("nric_back_filename", NRICFilenew.length > 1 ? NRICFilenew[1].name ? NRICFilenew[1].name : '' : '');
      formData.append("licsence_front", licensenew.length > 0 ? licensenew[0].image ? licensenew[0].image : '' : '');
      formData.append("licsence_front_filename", licensenew.length > 0 ? licensenew[0].name ? licensenew[0].name : '' : '');
      formData.append("licsence_back", licensenew.length > 1 ? licensenew[1].image ? licensenew[1].image : '' : '');
      formData.append("licsence_back_filename", licensenew.length > 1 ? licensenew[1].name ? licensenew[1].name : '' : '');
      formData.append("driver_id", Id.driverId && Id.driverId)

      let result = await uploadDocuments(formData);
      if (result.data.ResponseMessage === "Documents are uploaded.") {
        setUploadLoader(false)
        const popupData = {
          title: "Professional Details",
          message: "Documents are uploaded."
        }
        toast.dismiss()
        toast.success(popupData.message)
        props.resetDriverDetails()
        props.onClick()
        // setTimeout(
        //   window.location.reload(),
        //   40000)
          }
      else {
        // setLoader(false)
        setUploadLoader(false)
        const popupData = {
          title: "Professional Details",
          message: result.data.ResponseMessage
        }
        toast.error(popupData.message)
      }
    }
    }
    
    const DeleteDocument=async (name)=>
    {
      setLoader(true)
      let result = await deleteFile([name]);
      if(result.data.ResponseCode === 200 && result.data.ErrorCode === null )
      {  setLoader(false)
        toast.success("File SuccessFully deleted.")
        props.resetDriverDetails()
      }
      else {
        setLoader(false)
        toast.warn(result.data.ResponseMessage)
      }
    }
  
    const handleRemoveNricItems =( idx,name) => {
      const temp = [...NRICFile];
      temp.splice(idx, 1);
      setNRICFiles(temp);
      const temp2 = [...NRICFilenew];
      temp2.splice(idx, 1);
      setNRICFilesnew(temp2);
      // eslint-disable-next-line  array-callback-return
      driverData.file_data.nric_work_permit.names_of_files.find(str=>
      { if(str===name)
      DeleteDocument(name)}
      );
    }
  
    const handleRemoveDlItems = (idx,name) => {
      const temp = [...license];
      temp.splice(idx, 1);
      setLicense(temp);
      const temp2 = [...licensenew];
      temp2.splice(idx, 1);
      setLicensenew(temp2);
    // eslint-disable-next-line  array-callback-return
      driverData.file_data.driver_licsence.names_of_files.find(str=>
        { if(str===name)
         DeleteDocument(name)}
         );
    }

    const driverLicense = useCallback((acceptedFiles) => {
      reader.onload = () => {
        if (reader.readyState === 2&& (acceptedFiles[0].size)< 1048576)  {
          setLicensenew(state => [...state, { image: reader.result, name: acceptedFiles[0].path, size: acceptedFiles[0].size, date: acceptedFiles[0].lastModifiedDate }])
          setLicense(state => [...state, { image: reader.result, name: acceptedFiles[0].path, size: acceptedFiles[0].size, date: acceptedFiles[0].lastModifiedDate }])
        }
        // elseif(){

        // }
        else {
          toast.warning(maxFileSize.message)
        }
      }
      reader.readAsDataURL(acceptedFiles[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLicensenew]);
  
    const driverNRIC = useCallback((acceptedFiles) => {
      reader.onload = () => {
        if (reader.readyState === 2&& (acceptedFiles[0].size)< 1048576) {
          setNRICFilesnew(state => [...state, { image: reader.result, name: acceptedFiles[0].path,size: acceptedFiles[0].size }])
          setNRICFiles(state => [...state, { image: reader.result, name: acceptedFiles[0].path,size: acceptedFiles[0].size }])
        }
        else {
          toast.warning(maxFileSize.message)
        }
      }
      reader.readAsDataURL(acceptedFiles[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNRICFiles]);
  
    const licencelist = license.map((list, index) => (
      <div key={index + 'DL'}>
        <div className="uploded-file"  >
          {console.log(list)}
          <div className='file-details'>
            <img src={list.image} style={{ width: 23, height: 28, marginRight: 10,marginTop:10,cursor:"pointer"  }} alt='' />
            <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              <p style={{ margin: 0 }}>{list.size+' kb'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
          </div>
          <img onClick={() => handleRemoveDlItems(index,list.name)} src={trash} style={{ width: 22, height: 22,cursor:"pointer" }} alt='' />
        </div>
      </div>
    ));
  
    const NRIClist = NRICFile.map((list, index) => (
      <div key={index + 'NRIC'}>
        <div className="uploded-file"  >
          <div className='file-details'>
            <img src={list.image} style={{ width: 23, height: 28, marginRight: 10,marginTop:10,cursor:"pointer" }} alt='' />
            <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              <p style={{ margin: 0 }}>{list.size+' kb'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
          </div>
          <img onClick={() => handleRemoveNricItems(index,list.name)} src={trash} style={{ width: 22, height: 22,cursor:"pointer"  }} alt='' />
        </div>
      </div>
    ));
  
    useEffect(() => {
      setLicense(driverData.file_data.driver_licsence.url[0] ? state=>[...state,{image:driverData.file_data.driver_licsence.url[0],name:driverData.file_data.driver_licsence.names_of_files[0],size:driverData.file_data.driver_licsence.size_of_files[0],date:driverData.file_data.driver_licsence.date_of_file[0]}] : [])
      // setLicense(driverData.file_data.driver_licsence.url[1] ? state=>[...state,{image:driverData.file_data.driver_licsence.url[1],name:driverData.file_data.driver_licsence.names_of_files[1],size:driverData.file_data.driver_licsence.size_of_files[1],date:driverData.file_data.driver_licsence.date_of_files[1]}] : state=>[...state])
      setNRICFiles(driverData.file_data.nric_work_permit.url[0] ? state=>[...state,{image:driverData.file_data.nric_work_permit.url[0],name:driverData.file_data.nric_work_permit.names_of_files[0],size:driverData.file_data.nric_work_permit.size_of_files[0],date:driverData.file_data.nric_work_permit.date_of_file[0]}] : [])
      // setNRICFiles(driverData.file_data.nric_work_permit.url[1] ? state=>[...state,{image:driverData.file_data.nric_work_permit.url[1],name:driverData.file_data.nric_work_permit.names_of_files[1],size:driverData.file_data.nric_work_permit.size_of_files[1],date:driverData.file_data.nric_work_permit.date_of_files[1]}] : state=>[...state])
    }, [driverData.file_data])
    return (

        <Modal
            isOpen={props.show}
            className="DocumentUploadModal"
            overlayClassName="EditOverlay"
        >
            <div className="preview">
                <span className="preview-title">Upload Documents</span>
                <i className="fa fa-close" aria-hidden="true" style={{cursor:'pointer'}} onClick={props.onClick}></i>
            </div>
          {
              uploadloader||loader ? <div className="ImagePreviewLoader"><Loader type="Bars" color="#03A8EE"></Loader></div>:
              <div style={{padding:"5%",textAlign:"-webkit-center"}}>
              <div className='drag-drop-container'>
              <p className="smallsizep" style={{ color: '#002D44', fontSize: 25, marginBottom: 10,textAlign:"initial" }}>
              {translate(props.placeholder)}</p>
              <Dropzone onDrop={props.placeholder==="dlPlaceholder"&&license.length<2&&driverData.file_data.driver_licsence.number_of_files<2? driverLicense:props.placeholder==="nricPlaceholder"&&NRICFile.length<2 &&driverData.file_data.nric_work_permit.number_of_files<2?driverNRIC:()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}} multiple accept=".jpg,.jpeg,.png,">
                {({ getRootProps, getInputProps }) => (
                  <section className='drag-drop-block' style={{cursor:'pointer'}}>
                    <div {...getRootProps()} className='drag-drop' >
                      {license.length<2&&props.placeholder==="dlPlaceholder"  ? <input {...getInputProps()} /> : ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}}
                      {props.placeholder==="nricPlaceholder"&&NRICFile.length<2 ? <input {...getInputProps()} /> : ()=>{toast.dismiss();toast.warning("You can upload max only 2 documents")}}
                      <div className='folder'>
                        <img style={{ width: 32, height: 30, marginRight: 13 }} src={folder} alt='' />
                        <div>
                          <p className='file-name' style={{textAlign:"initial"}}>{translate('dragAndDrop')} </p>
                          <p className='format'>Formats , png, jpg, jpeg, max 1 mb </p>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
              {props.placeholder==="nricPlaceholder"?NRIClist:licencelist}
            </div>
            <Button onClick={()=>uploadImage()} title="Upload" style={{height:35,marginTop:15}}></Button>
              </div>
          }
        </Modal>
    );
};


export default UploadDocument;