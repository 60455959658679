import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./progressBar.scss"
const StepProgressBar=(props)=> {
    return (
        <ProgressBar percent={props.percentage}>
        <Step>
          {({ accomplished, index }) => (
            <div style={{marginRight:67,color:"darkgray",fontSize:16}} className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
            Ordered
            <p className={`orderfield ${accomplished ? "achieved" : ""}`}></p>
            <p className="paradataorder">{props.ordered}</p>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{marginTop:30,color:"darkgray",fontSize:16}} className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
              <p className={`pickupfield ${accomplished ? "achieved" : ""}`}></p>
              Pickup
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{marginLeft:67,color:"darkgray",fontSize:16}} className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
              Delivery
              <p className={`deliveredfield ${accomplished ? "achieved" : ""}`}></p>
              <p className="paradatadelivered">{props.delivered}</p>
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }

export default StepProgressBar