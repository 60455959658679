import React from 'react';
import Button from '../../../../components/button/Button'
import { useHistory} from 'react-router-dom';
import "../welcomemodal/welcomeModal.scss"
import onway from '../../../../assets/images/panel/On_the_way_re_swjt.png'
import fb from '../../../../assets/images/socialicons/tw.png'
import tw from '../../../../assets/images/socialicons/fb.png'
import  translate from '../../../../utils/locale/language'


const WelcomeModal = props => {
    const history = useHistory();

    const goToDashboard = async () => {
          history.push("/");
          window.scrollTo(0, 0);          
          window.location.reload();
    }
    return (
        <div className="welcomecontainer">
            <p className="welcometojust">{translate('welcomeToJust')}</p>
            <p className="start-your-journey-w">{translate('startyourjourney')}</p>
            <figure className="onway">
                    <img style={{ width: "50%", height: "88%" }} src={onway} alt="img" />
            </figure>
            <Button onClick={()=>goToDashboard()} title="Proceed" style={{width: 240, height: 70 ,margin:"auto" }}></Button>
            <figure className="socialicon">
                    <img style={{ marginLeft:20,width: "8%"}} src={fb} alt="img" />
                    <img style={{ width: "8%" }} src={tw} alt="img" />

            </figure>
        </div>
    );
};


export default WelcomeModal;