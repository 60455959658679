import React, { useState } from 'react';
import './List.scss'
import { useHistory } from 'react-router-dom';
import Button from '../button/Button';
import { assignManager } from '../../utils/apis/services/api';
import NotFound from '../../components/lottie/lottie';
import request from '../../assets/images/panel/request.png'
import ContentLoader from "react-content-loader"
import translate from "../../utils/locale/language"
import driverfill from '../../assets/images/panel/driverfill.svg'
import adminfill from '../../assets/images/panel/adminfill.svg'
import serverfill from '../../assets/images/panel/server-fill.png'
import { toast } from 'react-toastify';

const List = ({ data, onClick, isClose, id, getDetails, from, status }) => {

  const history = useHistory();
  const [showshimmer,setShimmer]=useState(true)
  const backToEnquiryDetails = async (managerId) => {
    console.log("managerId", managerId)
    console.log("driverid", id);
    let result = await assignManager(id, managerId);
    console.log(result)
    if(result.data.ResponseCode===200&&result.data.ErrorCode===null)
    {
      toast.success("Manager Assigned Succesfully")
    }
    isClose()
    from === "drivers" ? history.push({ pathname: "/drivers/details", state: { "driverId": id } }) : history.push({ pathname: "/enquiry/details", state: { "driverId": id } })
    // await  getDriverDetails(id) 
    getDetails(id)
  }
  let shimmer = <ContentLoader
            speed={2}
            width={75}
            height={75}
            // viewBox="0 0 1200 760"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            // {...props}
          >
            <circle cx="35" cy="35" r="25" />
          </ContentLoader> 

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

  return (
   <div>{from==="payments"? 
   <table className='list-container'>
      <thead className='table-head'>
        <tr className='head-row'>
          <th width={"20%"} style={{ paddingLeft: '2%' }}>{translate("sender")}</th>
          <th width={"20%"} >{translate("recipient")}</th>
          <th width={"20%"} style={{ paddingLeft: '2%' }}>{translate('tmode')}</th>
          <th width={"20%"} style={{ paddingLeft: '2%' }}>{translate('amount')}</th>
          <th width="20%">{translate('status')}</th>      
         </tr>
      </thead>
      <tbody>{data.length > 0 && data ? data.map((item, index) => (<tr key={index} className='body-row'>
        <td className="col-1" style={{ paddingLeft: '7%' }}> {console.log(data)}
          {showshimmer ? shimmer:''}
          <img className={showshimmer ?'partner-img':'payment-img'} src={item.sender?(item.sender.role===1?adminfill:driverfill):serverfill} onLoad={()=>setShimmer(false)} alt='' />
          <div>
            <p className='request-name' >{(item.sender?item.sender.full_name:"2C2P")}</p>
          </div>
        </td>
        <td >
          <div style={{display:"flex"}}>
          <img className={showshimmer ?'payment-img':'payment-img'} src={item.receiver?(item.receiver.role===1?adminfill:driverfill):serverfill} onLoad={()=>setShimmer(false)} alt='' />
            <p className='request-name' >{(item.receiver?item.receiver.full_name:"2C2P")}</p>
          </div>
        </td>
        <td style={{ paddingLeft: '3%' }}>{capitalize(item.type)}</td>
        <td style={{ paddingLeft: '3%' }}>{"₭ "+item.amount}</td>
       <td>{capitalize(item.status)}</td>
        {/* <td>{props.children}</td> */}
        {item.role === "Manager" && from !== "admin" ?
          // <td><Button  onClick={() =>   history.push({pathname: "/enquiry/details",state: { "mangerId":item.id }})}
          <td><Button id={"test"} onClick={() => backToEnquiryDetails(item.id)}
            style={{ backgroundColor: '#ffffff', color: "#03A8EE", border: '1px solid #03A8EE', height: 40, width: 95 }}
          title="Assign" /></td>: ''}</tr>
      )) : <td><NotFound /></td>
      }
      </tbody>
    </table>:
    from==="products"? 
    <table className='list-container'>
       <thead className='table-head'>
         <tr className='head-row'>
           <th width={"25%"} style={{ paddingLeft: '5.5%' }}>{translate("name")}</th>
           <th width={"25%"} >{translate("brand")}</th>
           <th width={"25%"} >{translate('status')}</th>
           <th width={"25%"} >{translate('amount')}</th>
          </tr>
       </thead>
       <tbody>{data.length > 0 && data ? data.map((item, index) => (<tr key={index} onClick={()=> history.push({ pathname: "/products/details", state: {"productId": item.id} })} className='body-row'>
         <td className="col-1" style={{ paddingLeft: '7%' }}> {console.log(data)}
           {showshimmer ? shimmer:''}
           <img className={showshimmer ?'partner-img':'payment-img'} src={item.images&&item.images.image_url?item.images.image_url:request} onLoad={()=>setShimmer(false)} alt='' />
           <div>
             <p className='request-name' >{(item.title?item.title:'')}</p>
           </div>
         </td>
         <td >{(item.subtitle?item.subtitle:'')}</td>
         <td >{"Active"}</td>
         <td >{"₭ "+item.price}</td>
        <td>{capitalize(item.status)}</td>
         {/* <td>{props.children}</td> */}
         {item.role === "Manager" && from !== "admin" ?
           // <td><Button  onClick={() =>   history.push({pathname: "/enquiry/details",state: { "mangerId":item.id }})}
           <td><Button id={"test"} onClick={() => backToEnquiryDetails(item.id)}
             style={{ backgroundColor: '#ffffff', color: "#03A8EE", border: '1px solid #03A8EE', height: 40, width: 95 }}
           title="Assign" /></td>: ''}</tr>
       )) : <td><NotFound /></td>
       }
       </tbody>
     </table>:
     
    <table className='list-container'>
      <thead className='table-head'>
        <tr className='head-row'>
          <th width={from === "admin" ? "33%" :"22%"} style={{ paddingLeft: '7%' }}>{  translate("name")}</th>
          <th width={from === "admin" ? "33%" :"22%"} >{ from === "orders" ? translate('useremail'): translate("phone")}</th>
          <th width={from === "admin" ? "34%" :"35%"}>{ from === "orders" ? translate('status'): translate("email")}</th>
          {from === "admin" ? "" :<th width={"21%"}>{ from === "orders" ? translate('amount'): translate("appliedon")}</th>}
         </tr>
      </thead>
      <tbody>{data.length > 0 && data ? data.map((item, index) => (<tr key={index} className='body-row' onClick={() => from === 'enquiry' ? history.push({ pathname: "/enquiry/details", state: { "driverId": item.id, "status": status } }) : from === 'admin' ? history.push({ pathname: "/superadmin/details", state: { "adminId": item.id, "role": item.role } }) : from === 'orders' ? history.push({ pathname: "/orders/details", state: {"OrderId": item.order_id} }): history.push({ pathname: "/drivers/details", state: { "driverId": item.id, "status": status } })}>
        <td className="col-1">
          {showshimmer ? shimmer:''}
          <img className={showshimmer ?'partner-img':'shimmer-img'} src={item.profile_image&&!showshimmer ?item.profile_image :from==="orders"&&!showshimmer&&item.driver_details.driver_profile_image_url ?item.driver_details.driver_profile_image_url:request} onLoad={()=>setShimmer(false)} alt='' />
          <div style={{width:"18vw"}}>
            <p className='request-name name-crop' >{from==="orders" ?item.driver_details.driver_full_name: item.full_name}</p>
            <p className='request-address'>{from==="orders" ?"":item.address}</p>
          </div>
        </td>
        <td>{from==="orders" ?item.created_by:item.phone_number}</td>
        <td>{from==="orders" ?item.status:item.email}</td>
        <td>{from==="orders" ?"₭ "+item.total_amount:item.applied_on}</td>
        {/* <td>{props.children}</td> */}
        {item.role === "Manager" && from !== "admin" ?
          // <td><Button  onClick={() =>   history.push({pathname: "/enquiry/details",state: { "mangerId":item.id }})}
          <td><Button id={"test"} onClick={() => backToEnquiryDetails(item.id)}
            style={{ backgroundColor: '#ffffff', color: "#03A8EE", border: '1px solid #03A8EE', height: 40, width: 95 }}
          title="Assign" /></td>: ''}</tr>
      )) : <td><NotFound /></td>
      }
      </tbody>
    </table>
}
</div>
  )
}

export default List
