import firebase from "firebase"

  var firebaseConfig = {
    apiKey: "AIzaSyBvcfwNPuiDnKO4dkMP0TJyBiX2REj5wYo",
    authDomain: "just-100aa.firebaseapp.com",
    databaseURL: "https://just-100aa.firebaseio.com",
    projectId: "just-100aa",
    storageBucket: "just-100aa.appspot.com",
    messagingSenderId: "802564056601",
    appId: "1:802564056601:web:f4934d2a627a8cd9342c88",
    measurementId: "G-02NBFTY6X0"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;
