import React, { useState,useEffect } from 'react';
import './DriverDetails.scss'
import partnerimg from "../../../../../assets/images/panel/request.png"
import AddManager from '../../../../panel/enquiry/component/addmanager/AddManager';
import Details from '../../../../../components/details/Details';
import { useHistory } from 'react-router-dom';
import { getDriverDetails } from '../../../../../utils/apis/services/api';
import EditDriver from '../../component/editdriver/editDriver'
import Modal from 'react-modal';
import  translate from '../../../../../utils/locale/language'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const DriverDetails = (addmanager) => {
  const [driverDetails, setDriverDetails] = useState([])
  const [DLFiles, setDLFiles] = useState([])
  const [NRICFiles,setNRICFiles] = useState([]) 
  const [passportPhoto, setPassportPhoto] = useState([])
  const [driverId, setDriverId] = useState('');

  const history = useHistory();

  const getDriversDetails = async () => {
  let Id= history.location.state;
    setDriverId(Id.driverId)
    let result = await getDriverDetails(Id.driverId);
    setDriverDetails(result.data.ResponseData !=null ? result.data.ResponseData : '' )
    setNRICFiles(result.data.ResponseData  ? result.data.ResponseData.file_data.nric_work_permit.names_of_files : '')
    setDLFiles(result.data.ResponseData ? result.data.ResponseData.file_data.driver_licsence.names_of_files : '')
    setPassportPhoto(result.data.ResponseData  ? result.data.ResponseData.file_data.passport_photo.url : '')
  }
  
  const details = async (id) => {
     setDriverId(id)
      let result = await getDriverDetails(id);
      setDriverDetails(result.data.ResponseData)
      setNRICFiles(result.data.ResponseData.file_data.nric_work_permit.names_of_files)
      setDLFiles(result.data.ResponseData.file_data.driver_licsence.names_of_files)
      setPassportPhoto(result.data.ResponseData.file_data.passport_photo.url)
    }
    
useEffect(() => {
  getDriversDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  const [isEdit,setEdit] =useState(false)

  const handleSelector =()=>{
    setEdit(true);
    disableBodyScroll(document);
  }

  const handleDeselector=()=>{
    setEdit(false);
    enableBodyScroll(document);
  }

  return (
    <div className={isOpen ? "driver-details active-blur" : "driver-details "}> 
   {
        <Modal
        isOpen={isEdit}
        className="EditModal"
        overlayClassName="EditOverlay"
      >
        <EditDriver modalclose={()=>handleDeselector()} resetDriverDetails={()=>getDriversDetails()} docList={driverDetails} DLFiles={DLFiles} NRICFiles={NRICFiles} passportPhoto={passportPhoto ? passportPhoto : partnerimg} ></EditDriver>
      </Modal>
      }
      <AddManager from={"drivers"} getDetails={details} id={driverId} isOpen={isOpen} onClick={toggle} isClose={() => setOpen(false) } />
      {/* <Header title="Drivers" /> */}
      <Details htitle="Drivers"  resetDriverDetails={()=>getDriversDetails()} from="driver" docList={driverDetails} DLFiles={DLFiles} NRICFiles={NRICFiles} passportPhoto={passportPhoto ? passportPhoto : partnerimg} Button1={translate('approve')}
        Button2={translate('cancel')} header={translate("partnerRequests")} editDriver={()=>handleSelector()}
         toggle={toggle}
      >
      </Details>
    </div>
    
  )
}

export default DriverDetails