import React, { useState, useEffect } from "react";
import "./Enquiry.scss";
import Header from "../header/Header";
import List from "../../../components/list/List";
import Searchbar from "../../../components/searchbar/Searchbar";
import { getDrivers, searchdriver } from "../../../utils/apis/services/api";
import Pagination from "react-js-pagination";
import ContentLoader from "react-content-loader";
import translate from "../../../utils/locale/language";
import {setPageSelection,getPageSelection} from "../../../../src/utils/common"

const Enquiry = () => {
  const [requestList, setRequestList] = useState([]);
  const [requestListTmp, setRequestListTmp] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  var [activePage, setPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setPage(pageNumber);
    getDriversData(pageNumber);
  };

  const getDriversData = async (pno) => {
    setPageSelection(pno)
    setLoading(true);
    let is_promoted = false;
    let status = "S";
    let result = await getDrivers(is_promoted, getPageSelection(), status);
    if (result) {
      setCount(
        result.data.ResponseData.results ? result.data.ResponseData.count : ""
      );
      setRequestList(
        result.data.ResponseData.results !== null
          ? result.data.ResponseData.results
          : ""
      );
      setRequestListTmp(
        result.data.ResponseData.results !== null
          ? result.data.ResponseData
          : ""
      );
      setLoading(false);
      console.log(result);
    } else {
      setLoading(false);
    }
  };
  const updateInput = async (input) => {
    console.log(input);
    setInput(input);
    let is_promoted = false;
    var result = await searchdriver(input, is_promoted);
    if (input.length >= 1) {
      setCount(
        result.data.ResponseData.results ? result.data.ResponseData.count : ""
      );
      setRequestList(
        result.data.ResponseData ? result.data.ResponseData.results : ""
      );
    } else if (
      result.data.ResponseCode === 200 &&
      result.data.ErrorCode === 404
    ) {
      setRequestList(requestListTmp.results);
      setCount(requestListTmp.count);
    } else {
      setRequestList(requestListTmp.results);
      setCount(requestListTmp.count);
    }
  };

  // useEffect(() => {
  //   getDriversData(1);
  // }, []);

  useEffect(()=>{
    const sideTab=localStorage.getItem("navOption");
    if(sideTab!=="/enquiry"){
        setPageSelection(1)
        setPage(1)
        getDriversData(1);
    }
  },[])

  useEffect(() => {
    if(getPageSelection()){
      setPage(getPageSelection())
      getDriversData(getPageSelection());
    }else{
      setPageSelection(1)
      getDriversData(1);
    }
    
  }, [])

  let loadshimmer = (
    <ContentLoader
      speed={2}
      width={"1000px"}
      height="450px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ marginLeft: -29, marginTop: -25 }}
    >
      <rect x="51" y="45" rx="3" ry="3" width="1006" height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width="997" height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width="997" height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="695" />
      <rect x="997" y="49" rx="3" ry="3" width="2" height="695" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="555" r="11" />
      <circle cx="917" cy="555" r="11" />
      <rect x="107" y="537" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="536" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="536" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="574" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="616" r="11" />
      <circle cx="917" cy="616" r="11" />
      <rect x="107" y="598" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="597" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="597" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="635" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="677" r="11" />
      <circle cx="917" cy="677" r="11" />
      <rect x="107" y="659" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="658" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="658" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="696" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="737" r="11" />
      <circle cx="917" cy="737" r="11" />
      <rect x="107" y="720" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="719" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="719" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="757" rx="3" ry="3" width="997" height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="1006" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );
  return (
    <div className="enquiry">
      <Header title={translate("onlineEnquiry")} />
      <div className="search">
        <p className="partner-request">{translate("partnerRequests")}</p>
        <Searchbar keyword={input} setKeyword={updateInput} />
      </div>
      {!loading ? (
        <div>
          <section className="enquiry-container">
            <List data={requestList} from="enquiry" />
          </section>
          <div>
            {count > 10 ? (
              <Pagination
                firstPageText={
                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                }
                prevPageText={
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                }
                lastPageText={
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                }
                nextPageText={
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                }
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={count}
                onChange={handlePageChange}
                activeClass="active"
                activeLinkClass="active"
                hideDisabled
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <section className="enquiry-container" style={{ marginBottom: "6vw" }}>
          {loadshimmer}
        </section>
      )}
    </div>
  );
};

export default Enquiry;
