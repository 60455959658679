import React, { useState, useCallback } from "react";
import "./ProfessionalDetails.scss";
import Dropzone from "react-dropzone";
import photo from "../../../../assets/images/photo.png";
import folder from "../../../../assets/images/folder.png";
import trash from "../../../../assets/images/trash.png";
import { useHistory, withRouter } from "react-router-dom";
import { uploadDocuments } from "../../../../utils/apis/services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader/loader";
import WelcomeModal from "../../components/welcomemodal/welcomeModal";
import Modal from "react-modal";
import translate from "../../../../utils/locale/language";
import moment from "moment"
import Footer from "../../../../components/footer/Footer";

const ProfessionalDetails = (props) => {
  const history = useHistory();
  const reader = new FileReader();
  const formData = new FormData();
  const [file, setFiles] = useState();
  const [profilePhotoName, setProfilePhotoName] = useState();
  const [NRICFile, setNRICFiles] = useState([]);
  const [license, setLicense] = useState([]);
  const [loader, setLoader] = useState(false);

  const maxFileSize = {
    title: "Document Upload",
    message: "File size greater than 10 MB, please upload less size of 10 MB",
  };
  const [welcomemodal, showModal] = useState(false);

  const goToDashboard = async () => {
    const popupData = {
      title: "Document Upload",
      message: "Please upload atleast passport photo.",
    };
    console.log(file !== undefined);
    if (file !== undefined) {
      uploadImage();
    } else {
      toast.warning(popupData.message);
    }
  };

  const uploadImage = async () => {
    setLoader(true);
    let Id = history.location.state;
    console.log(Id);
    formData.append("passport_photo", file && file);
    formData.append(
      "passport_photo_filename",
      profilePhotoName && profilePhotoName.length > 0
        ? profilePhotoName
          ? profilePhotoName
          : ""
        : ""
    );
    formData.append(
      "nric_front",
      NRICFile.length > 0 ? (NRICFile[0].image ? NRICFile[0].image : "") : ""
    );
    formData.append(
      "nric_front_filename",
      NRICFile.length > 0 ? (NRICFile[0].name ? NRICFile[0].name : "") : ""
    );
    formData.append(
      "nric_back",
      NRICFile.length > 1 ? (NRICFile[1].image ? NRICFile[1].image : "") : ""
    );
    formData.append(
      "nric_back_filename",
      NRICFile.length > 1 ? (NRICFile[1].name ? NRICFile[1].name : "") : ""
    );
    formData.append(
      "licsence_front",
      license.length > 0 ? (license[0].image ? license[0].image : "") : ""
    );
    formData.append(
      "licsence_front_filename",
      license.length > 0 ? (license[0].name ? license[0].name : "") : ""
    );
    formData.append(
      "licsence_back",
      license.length > 1 ? (license[1].image ? license[1].image : "") : ""
    );
    formData.append(
      "licsence_back_filename",
      license.length > 1 ? (license[1].name ? license[1].name : "") : ""
    );
    formData.append("driver_id", Id.driverId && Id.driverId);
    console.log(formData);
    let result = await uploadDocuments(formData);
    if (result.data.ResponseMessage === "Documents are uploaded.") {
      setLoader(false);
      const popupData = {
        title: "Professional Details",
        message: "Documents are uploaded.",
      };
      toast.success(popupData.message);
      showModal(true);
    } else {
      setLoader(false);
      toast.error(result.data.ResponseMessage);
    }
  };

  const handleRemoveNricItems = (idx) => {
    const temp = [...NRICFile];
    temp.splice(idx, 1);
    setNRICFiles(temp);
  };

  const handleRemoveDlItems = (idx) => {
    const temp = [...license];
    temp.splice(idx, 1);
    setLicense(temp);
  };

  const acceptedFiles = useCallback((acceptedFiles) => {
    reader.onload = () => {
      if (reader.readyState === 2 && acceptedFiles[0].size < 1048576) {
        setFiles(reader.result);
        setProfilePhotoName(acceptedFiles[0].path);
      } else {
        toast.info(maxFileSize.message);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [maxFileSize][setFiles]);

  const driverLicense = useCallback(
    (acceptedFiles) => {
      reader.onload = () => {
        if (reader.readyState === 2 && acceptedFiles[0].size < 1048576) {
          setLicense((state) => [
            ...state,
            {
              image: reader.result,
              name: acceptedFiles[0].path,
              size: acceptedFiles[0].size,
              date: acceptedFiles[0].lastModifiedDate,
            },
          ]);
        } else {
          toast.info(maxFileSize.message);
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
      // eslint-disable-next-line  react-hooks/exhaustive-deps
    },
          // eslint-disable-next-line  react-hooks/exhaustive-deps
    [setLicense]
  );

  const driverNRIC = useCallback(
    (acceptedFiles) => {
      reader.onload = () => {
        if (reader.readyState === 2 && acceptedFiles[0].size < 1048576) {
          setNRICFiles((state) => [
            ...state,
            { image: reader.result, name: acceptedFiles[0].path },
          ]);
        } else {
          toast.info(maxFileSize.message);
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
      // eslint-disable-next-line  react-hooks/exhaustive-deps
    },
          // eslint-disable-next-line  react-hooks/exhaustive-deps
    [setNRICFiles]
  );

  const licencelist = license.map((list, index) => (
    <div key={index + "DL"}>
      <div className="uploded-file">
        <div className="file-details">
          <img
            src={list.image}
            style={{ width: 23, height: 28, marginRight: 10 }}
            alt=""
          />
              <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              <p style={{ margin: 0 }}>{list.size+' kb'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
        </div>
        <img
          onClick={() => handleRemoveDlItems(index)}
          src={trash}
          style={{ width: 22, height: 22 }}
          alt=""
        />
      </div>
      {/* {
      } */}
    </div>
  ));

  const NRIClist = NRICFile.map((list, index) => (
    <div key={index + "NRIC"}>
      <div className="uploded-file">
        <div className="file-details">
          <img
            src={list.image}
            style={{ width: 23, height: 28, marginRight: 10 }}
            alt=""
          />
             <div style={{textAlign:"left"}} > <p className="file-name">{list.name}</p>
            <div className="file-type-details">
              <p style={{ margin: 0 }}>{list.size+' kb'}</p>
              <p style={{ margin: 0, marginLeft: 13 }}>{moment(list.date).format('LL')}</p>
            </div>
            </div>
        </div>
        <img
          onClick={() => handleRemoveNricItems(index)}
          src={trash}
          style={{ width: 22, height: 22 }}
          alt=""
        />
      </div>
    </div>
  ));

  return loader ? (
    <Loader></Loader>
  ) : (
    <>
        <div className='header'>
          <p className='just'>Just</p>
          <div className='header-nav'>Become a partner</div>
        </div> 

    <div className="p-details">
      {
        <Modal
          isOpen={welcomemodal}
          className="welcomeModal"
          overlayClassName="welcomeOverlay"
        >
          <WelcomeModal></WelcomeModal>
        </Modal>
      }
      <div className="p-form">
        <div>
          <p className="details-label">{translate("professionalDetails")}</p>
          <p className="details-title">{translate("personalDetailsInfo")}</p>
        </div>
        <section className="drag-drop-section">
          <img src={file ? file : photo} className="passport" alt="" />
          <div className="drag-drop-container-passport">
            <p style={{ color: "#002D44", fontSize: 20, marginBottom: 10 }}>
              {translate("passportPhoto")}
            </p>
            <Dropzone onDrop={acceptedFiles} accept=".jpg,.jpeg,.png">
              {({ getRootProps, getInputProps }) => (
                <section className="drag-drop-block-passport">
                  <div {...getRootProps()} className="drag-drop">
                    <input {...getInputProps()} />
                    <div className="folder">
                      <img
                        style={{ width: 32, height: 30, marginRight: 13 }}
                        src={folder}
                        alt=""
                      />
                      <div>
                        <p className="file-name ">
                          {translate("dragAndDrop")}{" "}
                        </p>
                        <p className="format">
                          Formats png, jpg, jpeg, max 1 mb{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </section>
        <div className="drag-drop-container">
          <p style={{ color: "#002D44", fontSize: 20, marginBottom: 10 }}>
            {translate("nricPlaceholder")}
          </p>
          <Dropzone
            maxFiles={2}
            onDrop={
              NRICFile.length >= 2
                ? () => toast.warning("you can upload max only 2 documents")
                : driverNRIC
            }
            multiple={true}
            canRemove={true}
            accept=".jpg,.jpeg,.png,"
          >
            {({ getRootProps, getInputProps }) => (
              <section className="drag-drop-block">
                <div {...getRootProps()} className="drag-drop">
                  {NRICFile.length < 2 ? (
                    <input {...getInputProps()} multiple={true} />
                  ) : (
                    console.log("")
                  )}
                  <div className="folder">
                    <img
                      style={{ width: 32, height: 30, marginRight: 13 }}
                      src={folder}
                      alt=""
                    />
                    <div>
                      <p className="file-name">{translate("dragAndDrop")} </p>
                      <p className="format">
                        Formats png, jpg, jpeg, max 1 mb{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          {NRIClist}
        </div>
        <div className="drag-drop-container">
          <p style={{ color: "#002D44", fontSize: 20, marginBottom: 10 }}>
            {translate("dlPlaceholder")}
          </p>
          <Dropzone
            onDrop={
              license.length >= 2
                ? () => toast.warning("you can upload max only 2 documents")
                : driverLicense
            }
            multiple
            accept=".jpg,.jpeg,.png,"
          >
            {({ getRootProps, getInputProps }) => (
              <section className="drag-drop-block">
                <div {...getRootProps()} className="drag-drop">
                  {license.length < 2 ? (
                    <input {...getInputProps()} />
                  ) : (
                    console.log("")
                  )}
                  <div className="folder">
                    <img
                      style={{ width: 32, height: 30, marginRight: 13 }}
                      src={folder}
                      alt=""
                    />
                    <div>
                      <p className="file-name">{translate("dragAndDrop")} </p>
                      <p className="format">
                        Formats , png, jpg, jpeg, max 1 mb{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          {licencelist}
        </div>

        <div className="form-button">
          <button
            className="back"
            onClick={() => history.push("/onboarding/details")}
            type="button"
          >
            {translate("back")}
          </button>
          <button onClick={() => goToDashboard()} className="next">
            {translate("next")}
          </button>
        </div>
      </div>
    </div>
     <Footer/>
      </>
  );

};

export default withRouter(ProfessionalDetails);
