import { baseUrl } from "../../env.json";
import axios from "axios";
import { getToken,getDeviceToken} from "../../common.js";
import { getCurrentLocale } from "../../locale/language";

const decode = (txt) => {
  let result = atob(txt);
  return result;
};
// const token =  decode(getToken());

const lang=getCurrentLocale()

export const createDriver = async (datas) => {
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "registerdriver/",
      data: datas,
      headers: {
        "content-type": "application/json",
        "language":lang
      }
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const userProfile = async () => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "me/" ,
      headers: { Authorization: "token " + token ,"language":lang},
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const phoneVerify = async (number) => {
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "phoneverify/?phone="+number ,
      headers: {"language":lang},
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const createAdmin = async (datas) => {
  let token = decode(getToken());

  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "registeradmin/",
      data: datas,
      headers: {
        "content-type": "application/json",
       "Authorization": "token " + token ,
       "language":lang
      }
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const createManager = async (datas) => {
  let token = decode(getToken());

  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "registermanager/",
      data: datas,
      headers: {
        "content-type": "application/json",
       "Authorization": "token " + token ,
       "language":lang
      }
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const uploadDocuments = async (datas) => {
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "driveruploaddocuments/",
      data: datas,
      headers: {
        "content-type": "multipart/form-data",
        "language":lang
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getDrivers = async (is_promoted,pageno,status,is_deactivated="",query="") => {
  console.log(query)
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "getdrivers/?is_promoted=" + is_promoted+"&status="+status+"&is_deactivated="+is_deactivated+"&page="+pageno+"&query="+query,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getDriverssearch = async (is_promoted,status,is_deactivated="",query="") => {
  console.log(query)
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "getdrivers/?is_promoted=" + is_promoted+"&status="+status+"&is_deactivated="+is_deactivated+"&query="+query,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const editDriver = async (driverData,id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "editdriver/?driver_id=" + id,
      data:driverData,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchdriver = async (input,is_promoted,status) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "searchdriver/?query=" + input + "&&is_promoted=" + is_promoted+"&status="+status ,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getPromotedDrivers = async (pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "getdrivers/?is_promoted=true&page="+pageno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchpromoteddriver = async (input) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "searchdriver/?query=" + input + "&&is_promoted=true",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getDriverDetails = async (id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "driverdetail/?driver_id=" + id,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getManagers = async (pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: pageno===""?baseUrl + "filteradminmanager/?role=3":baseUrl + "filteradminmanager/?role=3&page="+pageno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getAdmins = async (pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "filteradminmanager/?role=2&page="+pageno,
      headers: { Authorization: "token " + token ,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchAdmin = async (input,role) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "filteradminmanager/?query=" + input + "&role="+role,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getAdminProfile = async (id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "detail/?admin_id="+id,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getManagerProfile = async (id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "managerdetail/?manager_id="+id,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const checkDriverDocument = async (id) => {
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "checkdriverdocumentsurl/?driver_id="+id,
      headers: {"language":lang },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const assignManager = async (driverId, managerId) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "assignmanager/?driver_id=" + driverId + "&manager_id=" + managerId,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const ManagerUpdate = async (managerid,managerData) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "managerupdate/?manager_id=" + managerid ,
      data:managerData,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const AdminUpdate = async (adminid,adminData) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "update/?superadmin_id=" + adminid ,
      data:adminData,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const promoteRequest = async (driverId) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "updatedriver/?driver_id=" + driverId + "&operation=promote",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};
  

export const deleteRequest = async (driverId) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "updatedriver/?driver_id=" + driverId + "&operation=delete",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const approveDriver = async (datas) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "driverupdatestatus/",
      headers: { Authorization: "token " + token ,
      "language":lang
    },
      data : datas
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const OnHoldRequest = async (driverId,operation) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "updatedriver/?driver_id=" + driverId + "&operation=" + operation,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const deleteFile = async (fileName) => {
  let token = decode(getToken());
  let body={
    "file_names":fileName
  }
  try {
    let data = await axios({
      method: "DELETE",
      url: baseUrl + "deletefile/",
      headers: { Authorization: "token " + token,
      "language":lang},
      data:body
    });
    return data;
  } catch (error) {
    return error.response;
  }
};
export const login = async (userData) => {
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "login/",
      headers: {"language":lang },
      data: userData,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const forgot = async (userData) => {
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "forgotpassword/",
      headers: {"language":lang },
      data: userData,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const resetpassword = async (userData) => {
  try {
    let data = await axios({
      method: "PUT",
      url: baseUrl + "resetpassword/",
      headers: {"language":"en" },
      data: userData,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const logout = async () => {
  try{
    let data = await axios({
      method: "POST",
      url: baseUrl + "logout/",
    });
    return data;
  } catch (error){
    return error.response
  }
}

export const getOrders = async (type,pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "orders/?order_type="+ type+"&page="+pageno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getOrderDetails = async (id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "orders/"+ id+"/",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchOrder = async (input,role) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "orders/?order_type="+ role + "&driver_full_name="+input,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const registerNotificationToken = async () => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "POST",
      url: baseUrl + "utils/",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    data:{
      "firebase": {
        "device_token":"token "+getDeviceToken(),
        "device_type": "wb"
      }
    }
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const notificationList = async (pno) => 
{
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "notifications/?page="+pno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const ReadandDeleteNotification = async (operation,id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "notification/"+operation+"/?multiple_notifications="+id,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getPayments = async (type,pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "transactions/?service="+type+"&page="+pageno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchPayments = async (input,role) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "transactions/?service="+role+"&query=" + input,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getProducts = async (type,pageno) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "products/?page="+pageno,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const searchProducts = async (input,role) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl + "products/?query=" + input,
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getProductDetails = async (id) => {
  let token = decode(getToken());
  try {
    let data = await axios({
      method: "GET",
      url: baseUrl +"products/"+id+"/",
      headers: { Authorization: "token " + token,
      "language":lang
    },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};