import React, { useState, useEffect } from "react";
import "./orders.scss";
import Header from "../header/Header";
import List from "../../../components/list/List";
import { useHistory } from "react-router-dom";
import Titlebar from "../../../components/titlebar/Titlebar";
import { getOrders, searchOrder } from "../../../utils/apis/services/api";
import Pagination from "react-js-pagination";
import ContentLoader from "react-content-loader";
import translate from "../../../utils/locale/language";
import { setDriverSelection,getDriverSelection,setPageSelection,getPageSelection} from "../../../../src/utils/common"

const Orders = () => {
  const [list, setList] = useState(1);
  const [title, setTitle] = useState("Database");
  const [justBuyList, setjustBuy] = useState([]);
  const [justSendList, setjustSend] = useState([]);
  const [requestListTmpjustBuy, setRequestListTmpBuy] = useState([]);
  const [requestListTmpjustSend, setRequestListTmpSend] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  var [activePage, setPage] = useState(1);

  const history = useHistory();
  const getjustSendList = async (page_number) => {
    setPageSelection(page_number)
    setLoading(true);
    let result = await getOrders("just-send", getPageSelection());
    setLoading(false);
    console.log(result);
    setCount(
      result.data.ResponseData.results ? result.data.ResponseData.count : ""
    );
    setjustSend(result ? result.data.ResponseData.results : "");
    setRequestListTmpSend(result ? result.data.ResponseData : "");
  };
  const getjustBuyList = async (page_number) => {
    setPageSelection(page_number)
    setLoading(true);
    let result = await getOrders("just-buy", getPageSelection());
    setLoading(false);
    setCount(
      result.data.ResponseData.results ? result.data.ResponseData.count : ""
    );
    setjustBuy(result ? result.data.ResponseData.results : "");
    setRequestListTmpBuy(result ? result.data.ResponseData : "");
  };

  const updateInput = async (input) => {
    console.log(input);
    setInput(input);
    let role = "just-send";
    if (list === 1) {
      role = "just-send";
    } else if (list === 2) {
      role = "just-buy";
    }
    var result = await searchOrder(input, role);
    if (role === "just-buy") {
      if (input.length >= 1) {
        setCount(
          result.data.ResponseData.results ? result.data.ResponseData.count : ""
        );
        setjustBuy(
          result.data.ResponseData ? result.data.ResponseData.results : ""
        );
      } else if (
        result.data.ResponseCode === 200 &&
        result.data.ErrorCode === 404
      ) {
        setjustBuy(requestListTmpjustBuy.results);
        setCount(requestListTmpjustBuy.count);
      } else {
        setjustBuy(requestListTmpjustBuy.results);
        setCount(requestListTmpjustBuy.count);
      }
    } else if (role === "just-send") {
      if (input.length >= 1) {
        setCount(
          result.data.ResponseData.results ? result.data.ResponseData.count : ""
        );
        setjustSend(
          result.data.ResponseData ? result.data.ResponseData.results : ""
        );
      } else if (
        result.data.ResponseCode === 200 &&
        result.data.ErrorCode === 404
      ) {
        setjustSend(requestListTmpjustSend.results);
        setCount(requestListTmpjustSend.count);
      } else {
        setjustSend(requestListTmpjustSend.results);
        setCount(requestListTmpjustSend.count);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setPage(pageNumber);
    if (list === 1) {
      getjustSendList(pageNumber);
    } else if(list==2) {
      getjustBuyList(pageNumber);
    }
  };

  // useEffect(() => {
  //   getjustSendList(1);
  //   getjustBuyList(1);
  // }, []);

  // useEffect(() => {
  //   getjustSendList(1);
  //   getjustBuyList(1);
  // }, [history.location.state]);

  const handleRequestData = (data)=>{
    console.log(data)
    switch (data){
      case 1 :
        getjustSendList(getPageSelection());
        break;
      case 2:
        getjustBuyList(getPageSelection());
        break;   
  }
}

useEffect(()=>{
  const sideTab=localStorage.getItem("navOption");
  if(sideTab!=="/orders"){
      setDriverSelection(1)
      setPageSelection(1)
      setList(1)
      setPage(1)
      getjustSendList(1);
  }
},[])

useEffect(() => {
  if(getPageSelection()){
    setPage(getPageSelection())
  }else{
    setPageSelection(1)
    setList(1)
    getjustSendList(1);
  }
  if(getDriverSelection()){
    setList(getDriverSelection())
    handleRequestData(parseInt(getDriverSelection()))  
  }else{
    setList(1)
    setDriverSelection(1)
  }
  setList(getDriverSelection())
}, [])

  const JustSend = () => {
    setTitle(translate("database"));
    setList(1);
    setInput("");
    getjustSendList(1);
    setDriverSelection(1);
    setPage(1)
  };
  const JustBuy = () => {
    setTitle(translate("database"));
    setList(2);
    setInput("");
    getjustBuyList(1);
    setDriverSelection(2);
    setPage(1)
  };

  const ordersList = (selected) => {
    switch (selected) {
      case 1:
        return (
          <List
            from="orders"
            data={justSendList}
            onClick={() => history.push("/orders/details")}
          />
        );
      case 2:
        return (
          <List
            from="orders"
            data={justBuyList}
            onClick={() => history.push("/orders/details")}
          />
        );
      default:
        return (
          <List
            from="orders"
            data={justSendList}
            onClick={() => history.push("/orders/details")}
          />
        );
    }
  };

  let loadshimmer = (
    <ContentLoader
      speed={2}
      width={"1000px"}
      height={450}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ marginLeft: -29, marginTop: -25 }}
    >
      <rect x="51" y="45" rx="3" ry="3" width="1006" height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width="997" height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width="997" height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width="997" height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width="997" height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="997" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width="997" height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="1006" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );

  return (
    <div className="drivers">
      <Header title={translate("orders")} />
      <div className="drivers-header">
        <p className="partner-database">
          {translate("ordersDatabase")} 
        </p>
      </div>
      <Titlebar
        onClick1={JustSend}
        onClick2={JustBuy}
        input={input}
        onClick4={updateInput}
        active={list}
        title1={translate("justsend")}
        title2={translate("justbuy")}
      />

      {!loading ? (
        <div>
          <section className="shortlisted">{ordersList(list)}</section>
          {count > 10 ? (
            <Pagination
              firstPageText={
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              }
              prevPageText={
                <i class="fa fa-angle-double-left" aria-hidden="true"></i>
              }
              lastPageText={
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              }
              nextPageText={
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              }
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={count}
              onChange={handlePageChange}
              activeClass="active"
              activeLinkClass="active"
              hideDisabled
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <section className="shortlisted">{loadshimmer}</section>
      )}
    </div>
  );
};
export default Orders;
