import React,{useState} from 'react'
import './Onboarding.scss'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Terms from './components/terms/Terms';
import BecomeDriver from './components/becomedriver/BecomeDriver';
import Footer from '../../components/footer/Footer';
import ProfessionalDetails from './components/professionaldetails/ProfessionalDetails';
import Details from './components/details/Details';
import DetailsMain from './components/detailsmain/DetailsMain';
import { ToastContainer } from 'react-toastify';
import Home from '../home/Home';

const Onboarding = () => {

  return (
    <Router>
       
      <div className='on'>
        <ToastContainer/>
        {/* <div className='header'>
          <p className='just'>Just</p>
          <div className='header-nav'>Become a partner</div>
        </div> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path='/onboarding' component={BecomeDriver} />
          <Route exact path='/onboarding/terms' component={Terms} />
          <Route exact path='/onboarding/details' component={Details} />
          <Route exact path='/onboarding/detailsmain' component={DetailsMain} />
          <Route exact path='/onboarding/professional' component={ProfessionalDetails} />
        </Switch>
        {/* <Footer /> */}
      </div>
      
    </Router>
  )
}

export default Onboarding