import React, { useEffect } from "react";
import "./App.scss";
import Routes from "./routes/Routes";
import firebase from "./firebase";
import { setFirebaseDeviceToken } from "./utils/common";

function App() {
  const firebaseFunction = () => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        console.log("token", token);
        setFirebaseDeviceToken(token);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (
      window.location.pathname === "/orders/details" ||
      window.location.pathname === "/superadmin/details" ||
      window.location.pathname === "/enquiry/details" ||
      window.location.pathname === "/drivers/details" ||
      window.location.pathname === "/products/details"
    ) {
      let str = window.location.pathname;
      let loc1 = str.indexOf("/") + 1;
      let loc2 = str.indexOf("/", loc1);
      let mylocation = str.substring(loc1, loc2);
      window.location.replace("/" + mylocation);
    }
  };

  const BackListener = async () => {
    window.addEventListener("popstate", onBackButtonEvent);
    if (firebase.messaging.isSupported()) {
      firebaseFunction();
    }
  };
  useEffect(() => {
    BackListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
