import React from 'react'
import './ServiceCard.scss'


const servicelist = [
    {
        service: 'Wallet',
        color: '#0381EE'

    },
    {
        service: 'Buy & Sell',
        color: '#5FB344'
    },
    {
        service: 'Send',
        color: '#1D5AA7'
    },
    {
        service: 'Order Food',
        color: '#F77125'
    },
    {
        service: 'Entertainment',
        color: '  #FFBF08'
    }
]

const ServiceCard = (props) => {
    return (
        <div className='services'>
            <div className="service-title">
                <p className='service-list'>Services we provide</p>
                <div className='all-services'>
                    <p className='all'>All Services</p>
                    <div><hr className='l' /></div>
                </div>
            </div>
            <div className='service-container'>
                {
                    servicelist.map((list, index) => (

                        <section key={index} className='service-card'>
                            <div className='color-box' style={{ backgroundColor: list.color }}></div>
                            <p className='service-type'>{list.service}</p>
                            <p className='service-details'>Vestibulum accumsan, nulla in imperdiet aliquet,
                               velit erat malesuada odio, vitae mattis justo diam id quam.
             Sed in rhoncus augue. </p>
                            <p className='more' style={{ color: list.color }}>Learn more</p>
                        </section>
                    ))}
            </div>
        </div>
    )
}

export default ServiceCard