import React from 'react'
import './floating.scss'
import request from '../../../../../assets/images/panel/notification.png';

const Floatpopup = (props) => {

const refresh=()=>{
    props.setrefresh(true)
    setTimeout(function(){props.refresh()},1500)
}

let notify=props.data
const notice=[
    "A","B","c"
]
console.log(notify)
  return (
    <div className='floatingpopup  animate-float' onClick={()=>refresh()}>
     <i class={props.refreshsate?"fa fa-refresh floatrefreshrotate":"fa fa-refresh floatrefresh"}  aria-hidden="true"></i>
     <div style={{display:"flex",margin:5,paddingLeft:props.refreshsate?"10px":"0px"}}>
         {notice.map((item,index)=>
            {
               return notify!==[]?
               <img alt=''  src={notify[index]&&notify[index].user_details&&notify[index].user_details.profile_image_url?notify[index].user_details.profile_image_url:notify[index]&&notify[index].driver_details&&notify[index].driver_details.profile_image_url ?notify[index]&&notify[index].driver_details.profile_image_url:request}  className="floatimage"/>
               :
               <img src={request}  className="floatimage" alt=''/>
            }
            )}
     </div>
     <p className="floatText">Just Arrived</p>
    </div>
  )
}

export default Floatpopup