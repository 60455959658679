import React from 'react'
import './ArticleCard.scss'
import img1 from '../../../../assets/images/img1.png'
import img2 from '../../../../assets/images/img2.png'
import img3 from '../../../../assets/images/img3.png'

const servicelist = [
    {
        img: img1
    },
    {
        img: img2
    },
    {
        img: img3
    },
]

const ArticalCard = (props) => {
    return (
        <div className='articles'>
            <p className='article-title'>Latest Articles</p>
            <div className='article-container'>
                {
                    servicelist.map((list, index) => (
                        <section key={index} className='article-card'>
                            <img className="art-img" src={list.img} alt='' />
                            <p className='card-title'>Launching of our new food delivery service soon.</p>
                            <p className='card-details'>Vestibulum tristique placerat odio ac lobortis.
               Ut non dapibus arcu. Fusce imperdiet magna at felis consectetur vulputate. </p>
                            <p className='date' >21 June, 2020</p>
                        </section>
                    ))}
            </div>
            <p className='more'>More Articles</p>
            <hr className='more-bottom-line' />
        </div>
    )
}

export default ArticalCard