import React, { useState, useEffect } from 'react';
import './superAdminProfile.scss'
import Button from "../../../../src/components/button/Button"
import { useHistory } from 'react-router-dom';
import { getAdminProfile, getManagerProfile, userProfile } from '../../../utils/apis/services/api';
import request from '../../../assets/images/panel/request.png';
import Header from "../../../../src/screen/panel/header/Header"
import List from '../../../components/list/List';
import Titlebar from '../../../components/titlebar/Titlebar';
import SuperAdminform from '../superadmin/components/superAdminFroms/superAdminForm'
import ContentLoader from 'react-content-loader';
import  translate from '../../../utils/locale/language'

  const SuperAdminProfile = (props) => {
  const history = useHistory();
  const Id= history.location.state;
  const [loading, setLoading] = useState(false)
  const [adminData, setAdminData] = useState({})
  // eslint-disable-next-line no-unused-vars 
  let [role,setRole]= useState({role:Id?Id.role:'',id:Id?Id.adminId:''})
  const [list, setList] = useState(1);
  const [assignedDriverList, setAssignedDriver] = useState([])
  const [adminModal, setAdminModal] = useState(false)
  const [managerModal, setMangerModal] = useState(false)
  const [showshimmer,setShimmer]=useState(true)

  const getAdminDetails = async () => {
       setLoading(true)
      if(role.role===''){
        let result = await userProfile();
        console.log(result)
        setLoading(false)
        setAdminData(result.data.ResponseData !=null ? result.data.ResponseData : '')
      }
      else if(role.role==="Admin"){
        let result = await getAdminProfile(role.id);
        console.log(result)
        setLoading(false)
        setAdminData(result.data.ResponseData !=null ? result.data.ResponseData : '')
      }
      else if(role.role==="Manager"){
        let result = await getManagerProfile(role.id);
        console.log(result)
        setLoading(false)
        setAdminData(result.data.ResponseData !=null ? result.data.ResponseData : '')
        setAssignedDriver(result.data.ResponseData !=null ? result.data.ResponseData.drivers_assigned : '')
      }
  }

  const animateBack=()=>{
    // setGoBack(true)
    setTimeout(function(){history.goBack()},2000)
  }

  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
    getAdminDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const DriversAssigned = () => {
    setList(1)
  }

  let shimmer =<ContentLoader 
    speed={2}
    width={476}
    height={124}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
    <rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
    <rect x="0" y="56" rx="3" ry="3" width="152" height="10" /> 
    <rect x="0" y="72" rx="3" ry="3" width="200" height="10" /> 
    <rect x="0" y="88" rx="3" ry="3" width="200" height="10" /> 
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>

let loadshimmer = <ContentLoader
speed={2}
width={75}
height={75}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
>
<circle cx="35" cy="35" r="25" />
</ContentLoader> 
  
  return (
    <>

      {
        <div className="" style={{height:role.id!==null&&role.role==="Manager" ?"auto":"38.9vw",padding:"0 3.5%"}}>
        <Header  title={translate("SuperAdminProfile")} back={()=>animateBack()} />
       <div  className='admin-header '>
      <p className='your-profile'>{translate('yourProfile')}</p>
         </div>
        <section className={"details-container"}>
          <div className="">
        <Button onClick={role.id!==null&&role.role==="Manager" ? ()=>setMangerModal(true):()=>setAdminModal(true)} style={{ width: 97, height: 40, float:"right" }} title={translate('edit')} /> 
          </div>
          <table className="manager-table ">
            <thead className="manager-head ">
              <tr>
                <th>{translate('name')}</th>
                <th>{translate('contactDetails')}</th>
              </tr>
            </thead>
            <tbody className="manager-body">
              <tr key={adminData.phone_number} >
               <td className='manager-data'  >
               {!loading ?   <table>
                    <tbody>
                      <tr>
                        {showshimmer ?loadshimmer:''}
                        <td> <img className={showshimmer ?'d-shimmer-img':'m-img'} onLoad={()=>setShimmer(false)} src={adminData.profile_image !=null ? adminData.profile_image : request } alt='' /></td>
                        <td style={{ width: 100, paddingLeft: "4%" }} ><p className='m-name'>{ adminData.full_name}</p></td>
                      </tr>
                    </tbody>
                  </table>:shimmer}
                </td>
                <td className='manager-data' >
                {!loading ?  <table style={{ width: '80%' }}>
                    <tbody>
                      <tr style={{ height: 30 }} >
                        <td><i style={{ fontSize: 18 }} className="fa fa-envelope-o" /></td>
                        <td> <span style={{ margin: "0 2%" }} >{adminData.email}</span></td>
                      </tr>
                      <tr style={{ height: 30 }}  >
                        <td style={{ paddingLeft: '7px' }} > <i style={{ fontSize: 18 }} className="fa fa-mobile" /> </td>
                        <td> <span style={{ marginLeft: "3%" }} >{adminData.phone_number}</span> </td>
                      </tr>
                    </tbody>
                  </table> :shimmer}
                </td> 
                </tr>
                </tbody>
                </table>
                {
                 role.id!==null&&role.role==="Manager" ?  
                 <section className={"superAdminManagerlist"}>
                     <Titlebar onClick1={DriversAssigned} from="adminmanager"
                      active={list} title1={translate('assignedDrivers')} />
                    {
                      <List from="admin" data={assignedDriverList}  />                    }
                   </section> 
                   :''
               }
                </section>
                 { 
                 role.id!==null&&role.role==="Manager" ?  
                   <SuperAdminform Heading={translate('editManagerProfile')} ManagerId={role.id} onClick1={"updatemanager"} data={adminData} show={managerModal} adressfield={true} closeModal={()=>setMangerModal(false)}/>
                  : role.role==="Admin" ? 
                  <SuperAdminform Heading={translate('editAdminProfile')} AdminId={role.id} onClick1={"updateadmin"} data={adminData} show={adminModal} adressfield={false} closeModal={()=>setAdminModal(false)}/> :
                  role.role==='' ?
                  <SuperAdminform Heading={translate('editAdminProfile')} AdminId={adminData.id} onClick1={"updateadmin"} data={adminData} show={adminModal} adressfield={false} closeModal={()=>setAdminModal(false)}/> :''

                   }
             </div>
         }
    </>
  )
}


export default SuperAdminProfile