import React from 'react'
import './JustRestaurant.scss'
import banner from '../../assets/images/restaurant/restaurant.png'
import ProcessCard from '../../components/processcard/ProcessCard'
import information from '../../assets/images/driver/information.png'
import deliver from '../../assets/images/driver/deliver.png'
import approval from '../../assets/images/driver/approval2.png'
import Card from '../../components/card/Card'
import getstarted from '../../assets/images/restaurant/get-started.png'
import heavylifting from '../../assets/images/restaurant/heavy-lifting.png'
import deliveryfood from '../../assets/images/restaurant/delivery-food.png'
import cookserve from '../../assets/images/restaurant/cook-serve.png'
import FooterCard from '../../components/footercard/FooterCard'
import Footer from '../../components/footer/Footer'
import Banner from '../../components/banner/Banner'

const list = [
  {
    img: information,
    title: "Fill in your information",
    alt: 'info'
  },
  {
    img: approval,
    title: "Authentication / Approval",
    alt: 'deliver'
  },
  {
    img: deliver,
    title: "You are ready to deliver",
    alt: 'approval'
  },
]

const JustRestaurant = () => {
  return (
    <div className='driver'>
      <Banner src={banner} heading='Restaurant Partner' name='Become a partner' imgStyle={{ width: 504, height: 307 }} />
      <div className='join-us'>
        <section className='join-header'>
          <hr className='join-top-line' />
          <p className='join-heading'>How can you join us?</p>
          <p className='join-title'>You are only 3 easy steps away from earning and partnering with Just.
             Just follow the steps and you are good to go</p>
        </section>
        <section className='join-container'>
          <div className='process'>
            {
              list.map((items, index) =>
                <ProcessCard key={index} src={items.img} title={items.title} alt={items.alt} />
              )
            }
          </div>
        </section>
      </div>
      <Card src={getstarted} style={{ height: 270 }} heading="Things you need to get started" imgStyle={{ marginLeft: 30 }} >
        You are only 3 easy steps away from earning and partnering with Just.
       Just follow the steps and you are good to go. Morbi maximus ligula a purus porttitor faucibus.
       Morbi nunc sapien, viverra vel suscipit a, blandit a leo. Nulla et viverra risus.
      </Card>

      <Card style={{ flexDirection: 'row-reverse', height: 288 }} src={heavylifting} imgStyle={{ marginRight: 60 }}
        heading='We’ll do the heavy lifting'>
        You are only 3 easy steps away from earning and partnering with Just.
       Just follow the steps and you are good to go.
      Morbi maximus ligula a purus porttitor faucibus.
      </Card>

      <Card style={{ height: 297 }} contentStyle={{ marginLeft: 50, paddingTop: 30 }}
        src={deliveryfood} heading='Our drivers delivery your food'>
        You are only 3 easy steps away from earning and partnering with Just.
        Just follow the steps and you are good to go.
         Morbi maximus ligula a purus porttitor faucibus.
      </Card>

      <FooterCard src={cookserve} heading="Cook and Serve"
        style={{ flexDirection: 'row' }}
        font={{ fontSize: 40 }}
        contentStyle={{ flexDirection: 'column', justifyContent: 'space-evenly' }}>
        Aenean tempus lorem quis magna molestie,
        nec congue diam vulputate. Donec aliquet elit at porta porttitor.
           </FooterCard>
      <Footer />
    </div>
  )
}

export default JustRestaurant