import React from 'react';
import './Admin.scss'
import user from '../../../assets/images/panel/user.png'
import Header from '../header/Header';
import translate, { getCurrentLocale, setLocale } from "../../../utils/locale/language";

const Admin = () => {
    return (
        <div className='admin'>
            <Header title={translate('dashboard')} />
            <section className='admin-container'>
                <p className='app-request'>{translate('appuserrequest')}</p>
                <div className='admin-list-container'>
                    <div className='admin-card'>
                        <div className='admin-date-block'>
                            <p className='just-send' >Just Send</p>
                            <p className='admin-date'>21 Jun, 2020</p>
                        </div>
                        <div className='admin-info'>
                            <img src={user} alt='user' style={{ height: 42, width: 42 }} />
                            <div className='admin-contact'>
                                <p className='admin-name'>Jessica Almi</p>
                                <p>+856 286192864</p>
                            </div>
                        </div>
                        <div className='admin-address'>
                            19B, 10th Avenue, Belkin street, Krik, Laos
                   </div>
                        <div>
                        </div>
                    </div>
                    <div className='admin-card'>
                        <div className='admin-date-block'>
                            <p className='just-food' >Just food</p>
                            <p className='admin-date'>21 Jun, 2020</p>
                        </div>
                        <div className='admin-info'>
                            <img src={user} alt='user' style={{ height: 42, width: 42 }} />
                            <div className='admin-contact'>
                                <p className='admin-name'>Jessica Almi</p>
                                <p>+856 286192864</p>
                            </div>
                        </div>
                        <div className='admin-address'>
                            19B, 10th Avenue, Belkin street, Krik, Laos
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className='admin-card'>
                        <div className='admin-date-block'>
                            <p className='just-buy' >Just Buy & Sell</p>
                            <p className='admin-date'>21 Jun, 2020</p>
                        </div>
                        <div className='admin-info'>
                            <img src={user} alt='user' style={{ height: 42, width: 42 }} />
                            <div className='admin-contact'>
                                <p className='admin-name'>Jessica Almi</p>
                                <p>+856 286192864</p>
                            </div>
                        </div>
                        <div className='admin-address'>
                            19B, 10th Avenue, Belkin street, Krik, Laos
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Admin