import React from 'react'
import Lottie from 'react-lottie';
import loader from '../../../../assets/lottie/loader.json';
import './loader.scss'
export default function Loader(props)
{
    console.log("lottie")
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loader,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
return(
 <div className="loader"><Lottie  options={defaultOptions} height={300} width={250} /></div>   
)
}