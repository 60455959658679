import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss';
import App from './App';
import './assets/fonts/Publica-Sans-Bold.ttf'
import './assets/fonts/Publica-Sans-Medium.ttf'
import './assets/fonts/Publica-Sans-Light.ttf'
import './assets/fonts/PublicaSans.ttf'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
