import React from 'react';
import Button from '../../components/button/Button'
import "./successCard.scss"

import fb from '../../assets/images/socialicons/tw.png'
import tw from '../../assets/images/socialicons/fb.png'


const SuccessCard = props => {
    return (
        <div className="cardouter" style={{padding:props.padding}} >
        <div className='success-header-section'>
        {/* <p className='just' >JUST</p>
        <div className="action"  ><p>{props.header}</p></div> */}
        </div>
            <div className="successcontainer">
            <p className="mainheading">{props.heading}</p>
            <p className="description">{props.description}</p>
            <p className="email">{props.email}</p>
            <p className="s-name">{props.name}</p>
          
            {props.button ? <Button onClick={props.action} title={props.buttonText} style={{width: 200, height: 55 ,margin:"auto", left: "52%",marginTop:55 }}></Button> :""}

             <p className="have-a-question-con">
            Have a question? Contact our customer care
                     <br></br>+856 29100 82777
            </p>

            <figure className="socialicons">
                    <img style={{ marginLeft:20,width: "6%" }} src={tw} alt="img" />
                    <img style={{ marginLeft:20,width: "6%"}} src={fb} alt="img" />
            </figure>
        </div>
        </div>
    );
};


export default SuccessCard;