import React, { useState } from 'react';
import "./imageViewer.scss"
import Modal from 'react-modal';
import { deleteFile } from '../../utils/apis/services/api';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

const ImageViewer = props => {
    const [current, setCurrent] = useState(0)
    const [loading,setLoading] = useState(false)
    console.log(props.images)
    const images = []
    props.images.url[0] ? images.push({ url: props.images.url[0], name: props.images.names_of_files[0] }) : console.log();
    props.images.url[1] ? images.push({ url: props.images.url[1], name: props.images.names_of_files[1] }) : console.log();

    const deleteDocument = async (fileName) => {
        setLoading(true);
        let fileDelete = await deleteFile([fileName])
        console.log(fileDelete);
        toast.dismiss()
        toast.success("Document Deleted Successfully")
        props.resetDriverDetails()
        setLoading(false)
        props.onClick()
    }
    return (

        <Modal
            isOpen={props.show}
            className="previewModal"
            overlayClassName="EditOverlay"
        >
            <div className="preview">
                <span className="preview-title">Preview Documents</span>
                <i className="fa fa-close" aria-hidden="true" style={{cursor:"pointer"}}onClick={props.onClick}></i>
            </div>
          {
              loading ? <div className="ImagePreviewLoader"><Loader type="Bars" color="#03A8EE"></Loader></div>:
              <div className="customImagepreview">
              <div className="ImagePreviewContainer">
              <div className={current === 0 ? 'nodisplay' : 'yesdisplay'}><i className="fa fa-angle-left" aria-hidden="true" onClick={() => setCurrent(current - 1)} style={{ cursor: "pointer", fontSize: 40, fontWeight: "bold" }}></i></div>
                  <div className="PreviewContainer" >
                  <div className="trashcustomdiv"><i className="fa fa-trash trashcustom"  aria-hidden="true" onClick={() => deleteDocument(images[current].name)} ></i></div>
                  <img className="ImagePreview" alt="" src={images[current].url} aly=""></img>
                  </div>
              <div className={images.length - 1 === current ? 'nodisplay' : 'yesdisplay'}><i className="fa fa-angle-right" aria-hidden="true" onClick={() => setCurrent(current + 1)} style={{ cursor: "pointer", fontSize: 40, fontWeight: "bold"}}></i></div>
              </div>
              <p style={{ fontSize: 20 }}>{images[current].name}</p>
          </div>
          }
        </Modal>
    );
};


export default ImageViewer;