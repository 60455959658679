import React,{useState,useEffect} from 'react'
import './Details.scss'
import { Field, ErrorMessage, Formik, Form,useFormik } from 'formik';
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom';
import {createDriver,phoneVerify } from '../../../../utils/apis/services/api';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessCard from '../../../../components/onSuccessCard/successCard';
import firebase from '../../../../firebase'
import Button from '../../../../components/button/Button';
import OtpInput from 'react-otp-input';
import FormikPhoneInput from "../../../panel/driver/component/FormikPhoneInput";
import Modal from 'react-modal';
import  translate from '../../../../utils/locale/language'
import Loader from 'react-loader-spinner';
import Footer from '../../../../components/footer/Footer';

const Details = () => {

  const [loader,setLoader] =useState(false)
  const [submitdocs,setSubmitDocs] =useState(false)
  const [driverid,setDriverId] =useState('')
  const [otp,setOtp]=useState(false)
  const [otpvalue,setOTPvalue]=useState('')
  const [otpvalid,setOtpValid]=useState(false)
  const [verifiedNumber,setVerifiedNumber]=useState('')
  // eslint-disable-next-line no-unused-vars 
  const[captcharefval,setCaptcharef]=useState(null)
  const ref = React.createRef();

  const history = useHistory()

  const formik=useFormik({
    initialValues : {
     type: '',
     name: '',
     email: '',
     number: '',
     address: '',
   }
 })
  const validationSchema = Yup.object({
    // type: Yup.string().required(' '),
    name: Yup.string().min(2, "Mininum 2 characters").max(30, "Maximum 30 characters").required("required"),
    email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Invalid email address").required("required"),
    number: Yup.string().required('required'),
    address: Yup.string().required("required").min(8, "Mininum 8 characters").matches(/^[ A-Za-z0-9_./,-]*$/,"Specail characters are not allowed"),
  })

  const onSubmit = async values => {
  if(otpvalid&&values.number===verifiedNumber)
  {
    let datas = {
      "user": {
        "phone_number":  values.number ? values.number.substring(3) : ''  ,
        "email":  values.email ? values.email.toLowerCase() : '' ,
        "full_name":  values.name ? values.name : '' ,
        "address":  values.address ? values.address : '',
      },
      "partner_type": "driver"
    } 
    setLoader(true)
    let result = await createDriver(datas)
    // console.log(result.data.ResponseData.id)
    if(result.data.ResponseData !== null )
    {
      setLoader(false)
      setSubmitDocs(true)
      setDriverId( result.data.ResponseData.id)
    }
    else {
      setTimeout(toast.error(result.data.ResponseMessage),20000)
      setLoader(false)
      // getCaptcha()
    }
  }
  else{
    toast.dismiss()
    toast.warn("Please verify your number first")
  }
  }

  const SubmitDocs=()=>{
    history.push({
      pathname: "/onboarding/professional",
      state: { "driverId":driverid }
    });
  }

  let captchaRef = React.useRef(captcharefval);
  var appVerifier ;

  const getCaptcha=()=>{
    appVerifier=window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      captchaRef.current,
      {
        size: "invisible",
        callback: function(response) {
          // validate()
        }
      }
    ); 
  }

 useEffect(()=>{
   getCaptcha()
   if(window.sessionStorage.getItem('phno')&&window.sessionStorage.getItem('phno').length>3){
     resend()
   }
// eslint-disable-next-line  react-hooks/exhaustive-deps 
 },[])

 useEffect(()=>{
   getCaptcha()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
 },[loader===false])

 const resend=()=>{
   validate()
 }
  const validate=async ()=>{
  setLoader(true)
  let prevno=window.sessionStorage.getItem('phno')
  let number=prevno
  if(number!=="undefined"&&number!=="")
  {
    let no=number.substring(3)
    let result=await phoneVerify(no)
    console.log(result)
      if(result.data.ErrorCode===null&&result.data.ResponseCode===200){
           validateNumber(number)
      }
      else{
        toast.warn(result.data.ResponseMessage)
        setLoader(false)
        window.sessionStorage.setItem('phno',"")
      }
  }
  else{
    toast.dismiss()
    toast.warn("Please enter number first")
    setLoader(false)
  }
  }
  
  const validateNumber=(number)=>{
          console.log(number)
          setLoader(true)
          firebase.auth().signInWithPhoneNumber(number,appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            setLoader(false)
            setOtp(true)
            setVerifiedNumber(number)
            window.confirmationResult = confirmationResult;
          }).catch(function (error) {
            // Error; SMS not sent
            // ...
            setLoader(false)
            toast.dismiss()
            toast.warning(error.message)
            window.sessionStorage.setItem('phno',"")
            window.setTimeout(()=>{window.location.reload()},5000)
            appVerifier.reset()
          });
  }

  const handleOTP = otp => setOTPvalue(otp);
  const confirmOTP =()=>{
    window.confirmationResult.confirm(otpvalue).then(function (result) {
      // User signed in successfully.
      console.log(result)
      setOtp(false);
      toast.dismiss()
      toast.success("Phone Number validated successfully")
      setOtpValid(true)
      // ...
    }).catch(function (error) {
      // User couldn't sign in (bad verification code?)
      // ...
      toast.dismiss()
      if(error.message==='INVALID_CODE')
      {
        toast.warn("INVALID_CODE")
      }
      else{
        toast.warn("Session Expired or Entered Invalid Code")
        setOtp(false)
        window.sessionStorage.setItem('phno',"")
        window.recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaVerifier.reset(widgetId)});
        window.setTimeout(()=>{window.location.reload()},3500)
      }
    });
  }


  return (
    <>
       <div className='header'>
          <p className='just'>Just</p>
          <div className='header-nav'>Become a partner</div>
        </div> 
        
          <div id='recaptcha-container' ref={captchaRef}></div>
         { submitdocs ?
            <SuccessCard heading={"Complete all the documents for us to approve you as a driver"} 
          description="Please follow the instructions and provide us all the documents that we require so you could become a partner with us. Click the button below to start summiting your documents. Or visit just.com/driver to do the same."
          name={""} email={""} header="Documents Submission" button={true} buttonText="Submit Docs" action={()=>SubmitDocs()}
          ></SuccessCard>
          :
          <div className='details'>
       <Modal
       isOpen={otp}
      className="otpcard"
      overlayClassName="EditOverlay" >
        <div><i className="fa fa-close" aria-hidden="true" onClick={()=>{setOtp(false);window.sessionStorage.setItem('phno',"");window.location.reload()}}  style={{cursor:"pointer",float: "right", fontSize:20, marginRight: "-20px" }}></i></div>
        <div style={{textAlign:"center"}}>
       <p style={{fontWeight:"500",fontSize:17,height:5}}>{translate("pleaseenterotp")}</p>
       <p style={{color:"#00000061",fontSize:14}}>{translate("codesent")}xxxxxx{verifiedNumber.substring(9,13)}</p>
       </div>
        <OtpInput
      containerStyle={{justifyContent:"center"}}
      value={otpvalue}
      onChange={handleOTP}
      numInputs={6}
      separator={<span>-</span>}
      inputStyle="inputStyle"
      isInputNum
      shouldAutoFocus={true}
    />
    <p style={{color:"#00000061",fontSize:14,justifyContent:'center',display:"flex"}}>{translate("didntgetcode")}<p style={{color:"#03A8EE",margin:0}} onClick={()=>{window.location.reload()}}>{translate("resendit")}</p></p>
    <div style={{display:"flex",placeContent:"center",marginTop:15}}>
    <button className='button' style={{width:80,height:30,border:"none",outline:"none"}}  onClick={()=>{setOTPvalue('')}}>{translate("clear")}</button>
    <button className='button' style={{width:80,height:30,marginLeft:10,border:"none",outline:"none"}} disabled={otpvalue.length<6}  onClick={()=>confirmOTP()}>{translate("verify")}</button>
    </div>
      </Modal>
        <Formik
          initialValues={formik.initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit} >
          {({ errors, touched,setFieldValue }) => {
            return <Form className='form'>
            <p className='details-label'>{translate('personalDetails')}</p>
              <p className="details-title">{translate('personalDetailsInfo')}</p>
              <div className={touched.type && errors.type ? "input-field field-line" : "input-field "}>
                <label htmlFor="type" className='input-label'>{translate('typeOfPartner')}
                    <span className='eg'>{translate('typeOfPartneHints')}</span></label>
                {/* <Field as="select" name="type" className={touched.type && errors.type ? "input line" : "input "}>
                  <option value="choose"  >Choose Type</option>
                  <option value="Shop">Shop</option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Driver">Driver</option>
                </Field> */}
                 <Field autoComplete="off" className="input"
                  type='text'
                  id="type"
                  name='type'
                  value="Driver"
                  disabled
                />
                {/* <ErrorMessage name='type' >
                  {
                    (err) => <div className='err'>{err}</div>
                  }
                </ErrorMessage> */}
              </div>

              <div className={touched.name && errors.name ? "input-field field-line" : "input-field "}>
              <label htmlFor="name" className='input-label'>{translate('fullName')}
                  <span className='eg'>{translate('fullNameHints')}</span></label>
                  <Field autoComplete="off" className={touched.name && errors.name ? "input line" : "input "}
                    type='text'
                    id="name"
                    name='name'
                    placeholder={translate('fullNamePlaceholder')}
                  />
                  <ErrorMessage name='name' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
              <div className={touched.email && errors.email ? "input-field field-line" : "input-field "}>
                  <label htmlFor="email" className='input-label'>{translate('emailAddress')}
                  <span className='eg'>{translate('emailAddressHints')}</span></label>
                  <Field autoComplete="off" className={touched.email && errors.email ? "input line" : "input "}
                    type='text'
                    id="email"
                    name='email'
                    placeholder={translate('emailPlaceholder')}
                  />
                  <ErrorMessage name='email' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>

              <div className={touched.number && errors.number ? "input-field field-line" : "input-field "}>
              <label htmlFor="email" className='input-label'>{translate('phoneNumber')}
                  <span className='eg'>{translate('phoneNumberHints')}</span></label>
                   <div style={{display:"flex"}}>
                   <FormikPhoneInput
                    ref={ref}
                    className={touched.number && errors.number ? "input line" : "input "}
                    id="number"
                    name='number'      
                               
                    formik={formik}
                    onChange={e=>{setFieldValue('number',e);window.sessionStorage.setItem('phno',e)}}
                  />
          
          {loader ? <Loader width="100px" color="#03A8EE" height="50px" type="Bars"></Loader>:
          <Button style={{marginLeft:18,height:30,fontSize:14, width: 75,marginTop:19}} title="Validate" onClick={()=>validate()}></Button>}
                 <ErrorMessage name='number' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
              </div>
              <div className={touched.address && errors.address ? "input-field field-line" : "input-field "}>
              <label htmlFor="email" className='input-label'>{translate('currentAddress')}
                  <span className='eg'>{translate('currentAddressHints')}</span></label>
                  <Field autoComplete="off"
                    className={touched.address && errors.address ? "input line" : "input "}
                    type='text'
                    id="address"
                    name='address'
                    placeholder={translate('currentAddressPlaceholder')} />
                  <ErrorMessage name='address' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
              <div className='form-button'>
                <button className='back' type="button" onClick={() => history.push('/onboarding')}  >{translate('back')}</button>
                <button type="submit" className='next'>{translate('next')}</button>
              </div>
            </Form>
          }}
        </Formik>
      </div>}
      )
      <Footer/>
     </>
  )
}

export default Details