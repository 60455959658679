import React, { useState } from 'react'
import './Customers.scss'
import m1 from '../../../../assets/images/m1.png'
import m2 from '../../../../assets/images/m2.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const customerlist = [
    {
        name: 'Catherine Lim',
        img: m1
    },
    {
        name: 'Adam Serine',
        img: m2

    },
    {
        name: 'Catherine Lim',
        img: m1

    },
    {
        name: 'Adam Serine',
        img: m2

    },
]

const Customers = (props) => {
    let [activebtn, setActivebtn] = useState(2);
    const next = () => {
        setActivebtn(2)
        const e = document.getElementById('customer');
        e.scrollLeft += 250;
    };

    const pre = () => {
        setActivebtn(1)
        const e = document.getElementById('customer');
        e.scrollLeft -= 250;

    };

    return (
        <div className='customers'>
            <div className="customer-title">
                <p className='happy'>Happy Customers</p>
                <div className='all'>
                    <i className={activebtn === 1 ? "fa fa-angle-left activebtn" : "fa fa-angle-left"} style={{ marginRight: 14 }} onClick={pre} />
                    <i className={activebtn === 2 ? "fa fa-angle-right activebtn" : "fa fa-angle-right"} onClick={next} />
                </div>
            </div>
            <Swiper className='customer-container' id={'customer'} slidesPerView={3} spaceBetween={15} >
                {
                    customerlist.map((list, index) => (
                        <SwiperSlide key={index} className='customer-card' >
                            <img style={{ width: 75, height: 76 }} src={list.img} alt='' />
                            <div className='c-about' >
                                <p className='name'>{list.name}</p>
                                <p className='customer-details'>
                                    Phasellus ut pulvinar sapien. Pellentesque neque ligula,
                                    pulvinar vel malesuada quis, consequat id metus. In massa nisl,
                                    tempus sit amet tempus quis, semper a felis</p>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    )
}


export default Customers