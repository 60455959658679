import React from 'react'
import './Button.scss'

const Button = (props) => {
    return (
        // eslint-disable-next-line
        <div style={props.style} className={'button' + ' ' +props.className} onClick={props.onClick} onSubmit={props.onSubmit}>{props.back?<i style={{width:85,fontSize:23}} class="fa fa-chevron-left"></i>:''}{props.title}</div>
    )
}

export default Button