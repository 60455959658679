import React, { useState, useEffect } from 'react';
import './productdetail.scss'
import { useHistory } from 'react-router-dom';
import { getProductDetails} from '../../../../utils/apis/services/api';
import request from '../../../../assets/images/panel/request.png';
import 'react-toastify/dist/ReactToastify.css';
import ContentLoader from "react-content-loader"
import  translate from '../../../../utils/locale/language'
import Header from '../../header/Header';
import Map from '../../../../components/map/map'

const ProductDetails = (props) => {
  const [loading, setLoading] = useState(true)
  const [productData, setProductData] = useState()
  const [showshimmer,setShimmer]=useState(true)


  const history = useHistory();

  let product= history.location.state;

  const getproductDetails = async () => {
    setLoading(true);
    let result = await getProductDetails(product.productId)
    console.log("result.ResponseData.results",result.data.ResponseData)
    setProductData(result.data.ResponseData!=null ? result.data.ResponseData : '' )
    setLoading(false)
  }

  const animateBack=()=>{
    setTimeout(function(){history.goBack()},2000)
  }

  useEffect(()=>{
    getproductDetails()
    setShimmer(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  let shimmer =<ContentLoader 
    speed={2}
    width={276}
    height={124}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
    <rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
    <rect x="0" y="56" rx="3" ry="3" width="152" height="10" /> 
    <rect x="0" y="72" rx="3" ry="3" width="200" height="10" /> 
    <rect x="0" y="88" rx="3" ry="3" width="200" height="10" /> 
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>

let singlelineshimmer = <ContentLoader
speed={2}
width={150}
height={25}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
>
<rect x="48" y="8" rx="3" ry="3" width="302" height="10" /> 
</ContentLoader> 

let multilineshimmer =<ContentLoader 
speed={2}
width={276}
height={124}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
{...props}
>
<rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
<rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
<rect x="0" y="56" rx="3" ry="3" width="152" height="10" /> 
<rect x="0" y="72" rx="3" ry="3" width="200" height="10" /> 
</ContentLoader>

let twolineshimmer =<ContentLoader 
speed={2}
width={276}
height={124}
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb"
{...props}
>
<rect x="48" y="8" rx="3" ry="3" width="102" height="10" /> 
<rect x="48" y="26" rx="3" ry="3" width="102" height="10" /> 
<circle cx="20" cy="20" r="20" />
</ContentLoader>

  return (
    <>
        <Header title={translate('productdetails')} back={()=>animateBack()} />
        <div style={{marginBottom:"2%"}}>
      </div>
        {
      <div>
         <div>
        <section style={{marginTop:20,marginBottom:15}} className={"productData-details-container"}>
              <table className="manager-table ">
                <tbody className="manager-body ">
                  <tr>
                 <td className='manager-data '  >
                  <table>
                        <tbody style={{ width: '30%' }}>
                        <tr>
                        <td>
                          <img className="productImage" src={!loading&&productData.images[0]&&productData.images[0].image_url?productData.images[0].image_url:request} alt=""/>
                        </td>
                        <div className="productdetailscontainer">
                          <p className="productdetailsheading">{translate("productdetails")}</p>
                          <div className="productdetails">
                           {!loading?<td style={{fontSize:26,color:"black"}}>{"Product Id : "}<span>{"#"+productData.id}</span></td>:singlelineshimmer}
                          </div>
                          <div className="productdetails" style={{display:"flex",flexDirection:"column"}}>
                          {!loading?<td style={{fontSize:24,color:"black"}}>{productData.title ? productData.title :'No Data'}</td>:singlelineshimmer}
                           {!loading?<td style={{fontSize:20,color:"darkgrey"}}>{productData.subtitle ? "("+productData.subtitle+")":'No Data'}</td>:singlelineshimmer}
                          </div>
                          <div className="productdetails">
                          {!loading? <td style={{fontSize:22,color:"black"}}>{"Price : "}<span style={{fontWeight:"bold",color:"#03A8EE"}}>{"₭ "+productData.price}</span></td>:singlelineshimmer}
                          </div>
                          <td style={{fontSize:20,color:"#909496",padding:"5px 0px 4px 25px"}}><span>{"Description :"}</span></td>
                          <div className="productdetailsdescription">
                           {!loading?<td style={{fontSize:18,color:"black"}}><p>{productData.description}</p></td>:multilineshimmer}
                          </div>
                          <td style={{fontSize:20,color:"#909496",padding:"5px 0px 4px 25px"}}><span>{"Categories:"}</span></td>
                          <div className="productdetails" style={{display:"flex"}}>
                              {
                                  !loading&&productData.categories?productData.categories.map(item=>
                                    {
                                     return <span className="catogery">{item.name}</span>
                                    }):singlelineshimmer
                              }
                          </div>
                        </div>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section> 
            <section style={{marginTop:20,marginBottom:15}} className={"productData-details-container"}>
                <table style={{width:"100%"}} className="document-table ">
                  <tbody className='document-body'>
                    <tr>
                    <td style={{width:"70%"}}>
                        <p style={{fontSize:20,color: "black"}}>Seller Information :-</p>
                          <div>
                          {!loading&&productData.created_by&&showshimmer ? shimmer :""}
                          {!loading&&productData.created_by?
                          <div style={{display:"flex"}}>
                          <img className={showshimmer ?'shimmer-img':'seller-img'} src={productData.created_by&&productData.created_by.profile_image_url?productData.created_by.profile_image_url:request} onLoad={()=>setShimmer(false)} alt='' />
                          <div style={{marginTop:"-24px"}}>
                           <p style={{fontSize:24}} >{productData.created_by.full_name}</p>
                           <p style={{color:"#39b1e3",marginTop:"-24px"}}>See Profile</p>
                          </div>
                          </div>:twolineshimmer}
                          </div>
                    </td>
                    {!loading?<td>
                    <p style={{fontSize:20,color: "black"}}>Rating :-</p>
                    <div class="rating">
                      <span class={productData.created_by.rating>0?"fa fa-star checked":"fa fa-star"}></span>
                      <span class={productData.created_by.rating>1?"fa fa-star checked":"fa fa-star"}></span>
                      <span class={productData.created_by.rating>2?"fa fa-star checked":"fa fa-star"}></span>
                      <span class={productData.created_by.rating>3?"fa fa-star checked":"fa fa-star"}></span>
                      <span class={productData.created_by.rating>4?"fa fa-star checked":"fa fa-star"}></span>
                    </div>
                     <p style={{fontSize:"x-large"}}>{productData.created_by.rating}</p>
                    </td>:singlelineshimmer}
                    </tr>
                    <tr>
                         <td style={{fontSize:20,color:"#909496",padding:"15px 0px 0px 25px"}}>
                             <span>{"Description :"}</span>
                             <div>
                             {!loading?<p style={{fontSize:18,color:"black"}}>{productData.description}</p>:singlelineshimmer}
                           </div>
                        </td>
                  </tr>
                  <tr>
                         <td style={{fontSize:20,color:"#909496",padding:"15px 0px 0px 25px"}}>
                         <span>{"Location :"}</span>
                         <div>{!loading?<p style={{fontSize:18,color:"black"}}>{productData.location&&productData.location.address?productData.location.address:"No Data"}</p>:singlelineshimmer}</div>
                         </td>
                  </tr>
                  </tbody>
                </table>

            </section>
            <section>
            {!loading&&<Map driverdata={productData} mappage="products"/>}
            </section>
         </div>
      </div>
      }
    </>
  )
}

export default ProductDetails