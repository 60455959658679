import React, { useState, useEffect } from 'react';
import './Details.scss'
import CheckBox from '../checkbox/CheckBox';
import Button from "../button/Button"
import fileup from "../../assets/images/panel/fileup.png";
import deleteimg from "../../assets/images/panel/delete.png";
import eyeimg from "../../assets/images/panel/eye.png";
import checked from "../../assets/images/panel/checked.png";
import pending from "../../assets/images/panel/mark.png";
import { useHistory } from 'react-router-dom';
import { promoteRequest, deleteRequest, deleteFile, approveDriver ,OnHoldRequest} from '../../utils/apis/services/api';
import request from '../../assets/images/panel/request.png';
import SuccessCard from '../../components/onSuccessCard/successCard'
import ImageViewer from '../../components/imageViewer/imageViewer'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentLoader from "react-content-loader"
import  translate from '../../utils/locale/language'
import { confirmAlert } from 'react-confirm-alert'; // Import
import UploadDocument from '../uploadDocuments/uploadDocument';
import Header from '../../screen/panel/header/Header';

const Details = (props) => {
  const [driverData, setDriverData] = useState({ ...props.docList })
  const [approval, setApproval] = useState(false)
  const [previewDocument, setPreviewDocument] = useState(false)
  const [uploadDocuments, setUploadDocument] = useState(false)
  const [showshimmer,setShimmer]=useState(true)
  const[PreviewData,setPreviewData]=useState()
  const[placeholder,setPlaceHolder]=useState()
  const[animation,setAnimation]=useState("")

  let loadshimmer = <ContentLoader
            speed={2}
            width={75}
            height={75}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="35" cy="35" r="25" />
          </ContentLoader> 

  const history = useHistory();

  let driver= history.location.state;
  const toastId = React.useRef(null);


  const promote = async () => {
    toast.dismiss(toastId.current);
    let checkManagerAssign = props.docList.manager;
    if(checkManagerAssign !== null)
    {
    let result = await promoteRequest(props.docList.id);
    toast.dismiss()
    toast.success(translate("promotedSuccess"));
    window.setTimeout(() => {
      history.push("/enquiry")
      // history is available by design in this.props when using react-router
   }, 1500)
    }
    else
    {
      toast.dismiss()
      toastId.current =toast.warn(translate("assignManagerWarn"));
    }
    
  }

  const deletePartnerRequest = async () => {
    confirmAlert({
      title: "Are you sure want to Delete ?",
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let result = await deleteRequest(props.docList.id);
           if(result.data.ResponseCode === 200 && result.data.ErrorCode === null )
            {
              toast.success(translate('driverDelete'));
              driver.status==='approved'? history.push("/drivers") : history.push("/enquiry")
            }
          }
        },
        { label: 'No' }
      ]
    })

  }
  const approve = async (status) => {
    let operation = "activate";
   let data = {
    "driver_id" : props.docList.id,
    "status": "approved"
   }
   let docCount=driverData.file_data.driver_licsence.number_of_files+driverData.file_data.nric_work_permit.number_of_files
   if(docCount===4)
   {
    let checkManagerAssign = props.docList.manager;
    if(checkManagerAssign !== null){
     let result;

     {status=="OnHold" ?
      result = await OnHoldRequest(props.docList.id,operation)
      :
      result = await approveDriver(data)
    }
      if(result.data.ResponseCode === 200 && result.data.ErrorCode === null )
      { status=="OnHold"? setTimeout(toast.success(result.data.ResponseMessage),20000 ):setApproval(true)}
    }

    else{
      toast.dismiss()
      toast.warn(translate("assignManagerWarn"));
    }
   }
   else{
    toast.dismiss()
    toast.warn(translate("documentWarn"));
   }
  }

 const moveInHold = async () => {
  let operation = "deactivate";
    // let data = {
    //  "driver_id" : props.docList.id,
    // //  "status": "shortlisted"
    // // "operation" : "deactivate"
    // }
     let result = await OnHoldRequest(props.docList.id,operation)
     if(result.data.ResponseCode === 200 && result.data.ErrorCode === null )
     {
      setTimeout(toast.success(result.data.ResponseMessage),20000 )
      history.push("/drivers")
    }
   }

  const cancel = async () => {
    history.push("/drivers")
  }

  const resetDriverDetails = async () => {
    // let result = await getDriverDetails(props.docList.id)
    // setDriverData(result.data.ResponseData);
    let result=await props.resetDriverDetails()
  }
  
  const animateBack=()=>{
    // setGoBack(true)
    setAnimation("animate-detailsleave")
    setTimeout(function(){history.goBack()},2000)
  }
  useEffect(()=>{
    setShimmer(true)
  },[props.docList])

  let shimmer =<ContentLoader 
    speed={2}
    width={276}
    height={50}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="102" height="8" /> 
    <rect x="48" y="26" rx="3" ry="3" width="102" height="8" /> 
    {/* <rect x="0" y="56" rx="3" ry="3" width="152" height="10" />  */}
    {/* <rect x="0" y="72" rx="3" ry="3" width="200" height="10" />  */}
    {/* <rect x="0" y="88" rx="3" ry="3" width="200" height="10" />  */}
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>

  const reviewDocument=(document)=>{
    if(document==='driverLicense')
    {
      setPreviewData(driverData.file_data.driver_licsence)
      setPreviewDocument(true)
    }
    else if(document==='nricWorkPermit')
    {
      setPreviewData(driverData.file_data.nric_work_permit)
      setPreviewDocument(true)
    }
  }
  const uploadDocument=(document)=>{
    if(document==='driverLicense'){
      setUploadDocument(true)
      setPlaceHolder('dlPlaceholder')
    }
    else if(document==='nricWorkPermit'){
      setUploadDocument(true)
      setPlaceHolder('nricPlaceholder')
    }
  }
  const deleteDocument=(document)=>{
    let deleteFiles=[]
    if(document==='driverLicense')
    { //eslint-disable-next-line array-callback-return
      driverData.file_data.driver_licsence.names_of_files.map((item, index) =>{ deleteFiles.push(item)})
     }
     else{
             // eslint-disable-next-line  array-callback-return
      driverData.file_data.nric_work_permit.names_of_files.map((item, index) =>{ deleteFiles.push(item)})
     }
    confirmAlert({
      title: "Are you sure want to Delete "+translate(document)+" both front and back images?",
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let result = await deleteFile(deleteFiles);
           if(result.data.ResponseCode === 200 && result.data.ErrorCode === null )
            {
              toast.dismiss()
              toast.success("Document Deleted Successfully")
              props.resetDriverDetails()
            }
            else {
              toast.warn(result.data.ResponseMessage)
            }
          }
        },
        { label: 'No' }
      ]
    })
  }

  const Missing =(name)=>{
    return <tr>
    <td className="col-1">
      <img className='checked' src={pending} alt='' />
      {/* <p style={{margin:0,fontSize:17}}>{"0 file uploaded"}</p> */}
    </td>
    <td style={{ fontSize: '24px', color: 'black', verticalAlign: 'middle' }}>{translate(name)}</td>
    <td style={{ fontSize: '15px', verticalAlign: 'middle' }}>No File Uploaded</td>
    <td className="document-action">
    <div onClick={() => uploadDocument(name)} className="eye"> <img style={{ width: 20, height: 20, marginTop: "25%", cursor:'pointer'}} src={fileup} alt='' /></div>
    </td>
  </tr>
  } 

  const customDocument=(name,item,count)=>{
    return    (<tr>
    <td className="col-1" >
      <img className='checked' src={checked} alt='' />
       {/* <p style={{margin:0,fontSize:17}}>{count+"files uploaded"}</p> */}
    </td>
    <td style={{ fontSize: '24px', color: 'black', verticalAlign: 'middle' }}>{translate(name)}</td>
    <td style={{ fontSize: '15px', verticalAlign: 'middle' }}>{count+" files uploaded"}</td>
    <td className="document-action">
      <div onClick={() => uploadDocument(name)} className="eye"> <img style={{ width: 20, height: 20, marginTop: "25%",cursor:'pointer' }} src={fileup} alt='' /></div>
      <div onClick={() => reviewDocument(name)} className="eye"><img style={{ width: 20, height: 20, marginTop: "25%", cursor:'pointer' }} src={eyeimg} alt='' /></div>
      <div onClick={() => deleteDocument(name)} className="eye"> <img style={{ width: 20, height: 20, marginTop: "25%", cursor:'pointer' }} src={deleteimg} alt='' /></div>
    </td>
  </tr>)
  }

  useEffect(() => {
    setDriverData(props.docList);
  }, [props.docList])

  return (
    <>
        {/* <ToastContainer autoClose={3000}  /> */}
        {
          approval ? <SuccessCard heading={"Congratulations !"+driverData.full_name} 
          description="Your documents have been approved and you are now a partner with JUST. Please proceed and login to the Drivers app (appstore//justdriver) with the below given cridentials"
          name={driverData.full_name} email={driverData.email} header="Approved" button={true} buttonText="Close" action={()=>cancel()}
          ></SuccessCard> :
       
      <div className="" style={previewDocument?{position:"fixed",display:"none"}:{display:"inherit"}}>
      <Header title={props.htitle} back={()=>animateBack()} />
      
      {previewDocument ?<ImageViewer resetDriverDetails={()=>resetDriverDetails()} images={PreviewData} show={previewDocument} onClick={()=>setPreviewDocument(false)}></ImageViewer>:''}
      {uploadDocuments ?<UploadDocument resetDriverDetails={()=>resetDriverDetails()} placeholder={placeholder} docList={driverData} images={[]} show={uploadDocuments} onClick={()=>setUploadDocument(false)}></UploadDocument>:''}
      <div className='details-action-section'>
        <p className='partner-request' >{props.header}</p>
        <div className="action-button" >
          {/* <Button style={{ minwidth: 97, height: 40, marginRight: "7%" }} onClick={()=>animateBack()} title="Back"></Button> */}
          {
            props.activebutton ? null : <Button onClick={() => props.Button1 === 'Promote'&&driver.status!=='approved' ? promote() :props.Button1=== 'Approve'&&driver.status!=='approved' ? approve(driver.status) : moveInHold()} style={{ minwidth: 97, height: 40, marginRight: "7%" }} title={driver.status==="approved" ? translate('OnHold'): props.Button1} />
          }
          <Button onClick={() => props.Button2 === 'Delete'||driver.status==='approved' ? deletePartnerRequest() : cancel()} style={{ width: 97, height: 40, backgroundColor: "rgba(241, 241, 241,66%)", color: '#002D44' }} title={driver.status==="approved" ?translate('delete'):props.Button2} />
        </div>
      </div>
    <section className={"details-container "+animation}>
          <div className="">
      {props.from === "driver" ?<Button onClick={props.editDriver} style={{ width: 97, height: 40, float:"right" }} title={translate('edit')} /> : ''}
          </div>
 
          <table className="manager-table">
            <thead className="manager-head">
              <tr>
                <th>{translate('name')}</th>
                <th>{translate('contactdetails') }</th>
                <th>{translate('managerAssigned')}<sup style={{ color: '#FF4D4D', fontSize: 16 }}>*</sup> 
                <i className="fa fa-edit" aria-hidden="true" onClick={props.toggle}  style={{cursor:"pointer",fontSize:20,placeSelf:"start",marginLeft:10,color:"#03A8EE"}}></i>
                </th>
                <th style={{ color: "#03A8EE" }}>{props.action}</th>
              </tr>
            </thead>
            <tbody className="manager-body">
              <tr key={driverData.phone_number} >
             <td className='manager-data'  >
             { driverData.file_data  ? <table>
                    <tbody>
                      <tr>
                        <td>
                         {showshimmer ?loadshimmer:''}
                         <img className={showshimmer ?'d-shimmer-img':'m-img'} onLoad={()=>setShimmer(false)} src={driverData.file_data && driverData.file_data.passport_photo.url ? driverData.file_data.passport_photo.url : request } alt='' /></td>
                        <td style={{ width: 100, paddingLeft: "4%" }} ><p className='m-name'>{props.docList ? props.docList.full_name : ''}</p></td>
                      </tr>
                    </tbody>
                  </table>:shimmer}
                </td>

                <td className='manager-data' >
                { driverData.email && driverData.email ?
                  <table style={{ width: '80%' }}>
                    <tbody>
                      <tr style={{ height: 30 }} >
                        <td><i style={{ fontSize: 18 }} className="fa fa-envelope-o" /></td>
                        <td> <span style={{ margin: "0 2%" }} >{driverData.email}</span></td>
                      </tr>
                      <tr style={{ height: 30 }}  >
                        <td style={{ paddingLeft: '7px' }} > <i style={{ fontSize: 18 }} className="fa fa-mobile" /> </td>
                        <td> <span style={{ marginLeft: "3%" }} >{driverData.phone_number}</span> </td>
                      </tr>
                    </tbody>
                  </table>:shimmer}
                </td>
                {driverData.manager ?
                  <td className='manager-data' style={{ display: 'flex', alignItems: 'center' }} >
                  {showshimmer ?loadshimmer:''}
                  { showshimmer ? <img className='m-img' onLoad={()=>setShimmer(false)} src={request } alt='' />:''}
                   <img className={showshimmer ?'d-shimmer-img':'m-img'} onLoad={()=>setShimmer(false)} src={props.docList.manager !== undefined ? driverData.manager.profile_image : request}  alt='' />
                      <div className='assigned-manager'>
                      <p className="am-name">{driverData.manager !== undefined ? driverData.manager.full_name : ''}</p>
                      <p className="am-id ">{driverData.manager !== undefined ? driverData.manager.email : ''}</p>
                      <p className='am-number'>{driverData.manager !== undefined ? driverData.manager.phone_number : ''}</p>
                    </div>
                  </td> : console.log()}
                <td className='manager-data'  >
                 {driverData.manager ? '': <Button title={"Assign-Manager"} onClick={props.toggle}
                    style={{ backgroundColor: '#ffffff', color: "#03A8EE", border: '1px solid #03A8EE', height: 40 }} />}
                </td>                
              </tr>
            </tbody>
          </table>

          <p className="credential">  <CheckBox style={props.checkbox} type='checkbox' checked="checked" ></CheckBox>{translate('use')} <a href="false"  style={{ color: '#03A8EE' ,pointerEvents:"none"}}> {props.docList.email} </a> {translate('driverLoginCredential')}</p>

          <section className="uploaded-document">
            <p style={{ fontSize: 20, color: 'rgba(0,45,68,0.48)' }} >{translate('documentUploaded')}</p>
            <table className="document-table">
              <tbody className='document-body'>
                {
                  driverData.file_data&&driverData.file_data.driver_licsence.number_of_files>0 ?
                  // driverData.file_data.driver_licsence.names_of_files.map((item, index) =>
                  customDocument('driverLicense',"Driving License Front and Back",driverData.file_data.driver_licsence.number_of_files):
                   Missing('driverLicense')
                }
                {
                  driverData.file_data&&driverData.file_data.nric_work_permit.number_of_files>0 ?
                  customDocument('nricWorkPermit',"NRIC Front and Back",driverData.file_data.nric_work_permit.number_of_files)
                : Missing('nricWorkPermit')
                }
              </tbody>
            </table>
          </section>
        </section> 
      </div>
      }
    </>
  )
}

export default Details