import React, { useState,useEffect } from 'react';
import './EnquiryDetails.scss'
import partnerimg from "../../../../../assets/images/panel/request.png"
import AddManager from '../addmanager/AddManager';
import Details from '../../../../../components/details/Details';
import { useHistory } from 'react-router-dom';
import { getDriverDetails } from '../../../../../utils/apis/services/api';
import  translate from '../../../../../utils/locale/language'

const partner = [
  {
    name: 'Newaan Parker',
    img: partnerimg,
    email: 'nparker@gmail.com',
    number: "+856 91892 09289",

  }
]

const EnquiryDetails = (addmanager) => {
  const [driverDetails, setDriverDetails] = useState([])
  const [DLFiles, setDLFiles] = useState([])
  const [NRICFiles,setNRICFiles] = useState([]) 
  const [passportPhoto, setPassportPhoto] = useState([])
  const [driverId, setDriverId] = useState();
  const [assignedManager,setaAssignedManager] = useState();

  const history = useHistory();
  const getDriversDetails = async () => {
  let Id= history.location.state;
   setDriverId(Id.driverId)
    let result = await getDriverDetails(Id.driverId);
    console.log("result.ResponseData.results",result.data.ResponseData,Id)
    setaAssignedManager(result.data.ResponseData.manager !=null ? result.data.ResponseData.manager : '');
    setDriverDetails(result.data.ResponseData)
    setNRICFiles(result.data.ResponseData.file_data.nric_work_permit.names_of_files)
    setDLFiles(result.data.ResponseData.file_data.driver_licsence.names_of_files)
    setPassportPhoto(result.data.ResponseData.file_data.passport_photo.url)
  }
  const details = async (id) => {
     setDriverId(id)
      let result = await getDriverDetails(id);
      console.log("result.ResponseData.results",result.data.ResponseData)

      setDriverDetails(result.data.ResponseData)
      // setNRICFiles(result.data.ResponseData.file_data.nric_work_permit.names_of_files)
      // setDLFiles(result.data.ResponseData.file_data.driver_licsence.names_of_files)
      // setPassportPhoto(result.data.ResponseData.file_data.passport_photo.url)
    }
useEffect(() => {
  getDriversDetails()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  return (
    <div className={isOpen ? "enquiry-details active-blur" : "enquiry-details "}> 
      <AddManager getDetails={details} id={driverId} isOpen={isOpen} onClick={toggle} isClose={() => setOpen(false) } />
      {/* <Header title="Online Enquiry" /> */}
      <Details htitle="Online Enquiry" resetDriverDetails={()=>getDriversDetails()} docList={driverDetails} DLFiles={DLFiles} NRICFiles={NRICFiles} passportPhoto={passportPhoto ? passportPhoto : partnerimg} Button1={translate('promote')}
        Button2={translate('delete')} header={translate('partnerRequests')}
        partnerdata={partner} manager={assignedManager} toggle={toggle}
      >
      </Details>
    </div>
    
  )
}

export default EnquiryDetails