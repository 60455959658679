import React, { useState } from "react";
import "./Faq.scss";
import just from "../../assets/images/just.png";
import faq from "../../assets/images/faq.png";
import Footer from "../../components/footer/Footer";
import license from "../../assets/images/license.png";

const Faq = () => {
  let [active, setActive] = useState(true);
  return (
    <div className="faq">
      <section className="faq-header">
        <img src={just} alt="logo" />
        <ul className="faq-nav">
          <li>About Us</li>
          <li>Help center</li>
          <li>Driver-Partner Resource</li>
          <li>Blog</li>
          <li>
            {" "}
            <p className="header-nav">Become a partner</p>
          </li>
        </ul>
      </section>
      <img className="faq-banner" src={faq} alt="" />
      <section className="faq-list-container">
        <p className="faq-title">Everything you need!</p>
        <p className="faq-t">
          Below are all the documents required for you to become a driver and
          register with us.{" "}
        </p>
        <div className="faq-list">
          <div className="faq-list-block">
            <div className="faq-list-item">
              <p>1. Front & back of NRIC/Work Permit</p>
              <div className="faq-drop-button">+</div>
            </div>
          </div>
          <div className="faq-list-block">
            <div className="faq-list-item">
              <p>2. Front & back Drivers license</p>
              <div className="faq-drop-button">+</div>
            </div>
          </div>
          <div className="faq-list-block">
            <div className="faq-list-item">
              <div>
                <p style={{ marginBottom: 0 }}>
                  3. Front & back Drivers license hiaawl{" "}
                </p>
                <p style={{ paddingLeft: 26, marginTop: 0 }}>
                  {" "}
                  some else need to be here{" "}
                </p>
              </div>
              <div className="faq-drop-button">+</div>
            </div>
          </div>
          <div className="faq-list-block">
            <div className="faq-list-item">
              <p>4. Front & back Drivers license</p>
              <div
                className={
                  active ? "faq-drop-button fdb-active" : "faq-drop-button "
                }
                onClick={() => setActive(!active)}
              >
                {" "}
                {active ? "-" : "+"}{" "}
              </div>
            </div>
            {active ? (
              <ol type="a" className="faq-drop-list">
                <li>Your IC must either be:</li>
                <ol type="i">
                  <li>Blue (Laos)</li>
                  <li> Black (Permanent address)</li>
                  <li>
                    {" "}
                    or green with an indication of “Chauffeur” as the occupation
                    (Work Permit holders)
                  </li>
                </ol>
                <li style={{ marginTop: 20 }}>
                  {" "}
                  Make sure that the photo is clear, all information on the card
                  is legible, and all four corners of the card are visible.
                </li>
                <img
                  src={license}
                  style={{ width: 220, height: 140, marginTop: 15 }}
                  alt=""
                />
              </ol>
            ) : null}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Faq;
