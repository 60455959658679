import React from 'react'
import './Titlebar.scss'
import Searchbar from '../searchbar/Searchbar'

const Titlebar = (props) => {
  console.log(props,props.active)
  return (
    <section className="title-list-header">
      <ul className='title-list-block'>
        <li onClick={props.onClick1} >
          <div><span style={{ marginLeft: 18 }} className={props.active === 1 ? "short" : ""} >{props.title1}</span>
            <hr className={props.active === 1 ? "bottom-line line-none" : "line-none"} />
          </div>
        </li>
        <li onClick={props.onClick2} >
          <div><span style={{ marginLeft: 18 }} className={props.active === 2 ? "short" : ""} >{props.title2}</span>
            <hr className={props.active === 2 ? "bottom-line line-none" : "line-none"} />
          </div>
        </li>
        <li onClick={props.onClick5} >
          <div> <span style={{ marginLeft: 18 }} className={props.active === 4 ? "short" : ""}>{props.title5}</span>
            <hr className={props.active === 4 ? "bottom-line line-none" : "line-none"} />
          </div>
        </li>
        <li onClick={props.onClick3} >
          <div> <span style={{ marginLeft: 18 }} className={props.active === 3 ? "short" : ""}>{props.title3}</span>
            <hr className={props.active === 3 ? "bottom-line line-none" : "line-none"} />
          </div>
        </li>
       
       { props.from==="adminmanager" ?'': <li ><Searchbar  style={{ width: "100% !important" }} keyword={props.input} setKeyword={props.onClick4} /> </li>}      </ul>
    </section>
  )
}

export default Titlebar