import React from 'react'
import './Banner.scss'
import Button from '../button/Button'

const Banner = (props) => {
    return (
        <div className='driver-banner' >
            <section className='banner-section'>
                <summary>{props.heading}</summary>
                <p className="about">
                    Partner with us to service the masses!
                     Become a driver and earn with the best in the business
                </p>
                <Button className='buttonWidth' title={props.name} />
            </section>
            <img style={props.imgStyle} className='banner-img' src={props.src} alt="img" />
        </div>
    )
}

export default Banner