import React,{useEffect, useState} from 'react';
import { useHistory} from 'react-router-dom';
import "./superAdminForm.scss"
import Modal from 'react-modal';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import photo from '../../../../../assets/images/photo.png'
import * as Yup from 'yup'
import {createAdmin,createManager, ManagerUpdate,AdminUpdate } from '../../../../../utils/apis/services/api';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  translate from '../../../../../utils/locale/language'

const SuperAdminForm = props => {
  console.log(props)
    const history = useHistory();

    // const [data, setData] = useState({})
    const [fileImage, setFiles] = useState();
    const [profilePhotoName, setProfilePhotoName] = useState('')

    let initialValues = {
      name: props.data ? props.data.full_name :'',
      email: props.data ? props.data.email :'',
      number: props.data ? props.data.phone_number:'',
      address: props.data ? props.data.address:'',
      file:fileImage,
      filename:profilePhotoName
      }

      const validationSchema = Yup.object({
        name: Yup.string().min(2, "Mininum 2 characters").max(30, "Maximum 30 characters").required("required"),
        email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Invalid email address").required("required"),
        number: Yup.string().matches(/^[0-9]{10}$/, 'Phone No. must be a number of 10 digits').required('required'),
        address: Yup.string().required('').min(8, "Mininum 8 characters").matches(/^[ A-Za-z0-9_./,-]*$/, "Specail characters are not allowed").required(props.adressfield?"required":'')
      })
      const addAdmin = async(data) =>{
         if(Object.keys(data).length === 0 && data.constructor === Object){
            toast.dismiss();
            toast.warn("All fields are required ! ")
         }
         else{
          let result = await createAdmin(data)
          if(result.data.ResponseData === null&&result.data.ResponseMessage==="Admin Created")
          {
            const popupData =  {
              title: "Add New Admin",
              message: translate('adminAddSuccess')
            }
            setTimeout(toast.success(popupData.message),20000)
            props.closeModal()
            // props.settab()
            history.push({pathname:"/superadmin",state:{list:''}});
          }
          else {
            const popupData =  {
              title: "Add New Admin",
              message: result.data.ResponseMessage
            }
            toast.dismiss()
              toast.error(popupData.message)
          }
         }
        
      }

      const addManager = async(data) =>{
        if(Object.keys(data).length === 0 && data.constructor === Object){
          toast.dismiss();
          toast.warn("All fields are required ! ")
       }
       else{
        let result = await createManager(data)
        if(result.data.ResponseData === null&&result.data.ResponseMessage==="Manager Created")
        {
          const popupData =  {
            title: "Add New Manager",
            message: translate('managerAddSuccess')
          }
          toast.dismiss()
          setTimeout(toast.success(popupData.message),20000)
          props.closeModal()
         
      //     history.push({pathname: "/superadmin",state:{list:2}});
               props.settab()
        }
        else {
          const popupData =  {
            title: "Add New Manager",
            message: result.data.ResponseMessage
          }
            toast.dismiss()
            toast.error(popupData.message)
        }
      }
      }


      const updateManager = async(data) =>{
        if(Object.keys(data).length === 0 && data.constructor === Object){
          props.closeModal()
        }
        else{
        let result = await ManagerUpdate(props.ManagerId,data)
        if(result.data.ResponseData != null ){
          const popupData =  {
            title: "Update Manager",
            message: "Manager Updated Successfully"
          }
          props.closeModal()
          window.setTimeout(function(){window.location.reload(true)}, 300);    
          window.setTimeout(toast.success(popupData.message), 500); 
          // history.push({pathname: "/superadmin/details",state:{'role':'manager'}});
        }else {
          const popupData =  {
            title: "Update Manager",
            message: result.data.ResponseMessage
          }
            toast.dismiss()
            toast.warn(popupData.message)
        }
        
      }
      
    }
   

    const updateAdmin = async(data) =>{
      if(Object.keys(data).length === 0 && data.constructor === Object){
        props.closeModal()
      }
      else{
        let result = await AdminUpdate(props.AdminId,data)
      if(result.data.ResponseData != null )
      {
        const popupData =  {
          title: "Update Admin",
          message: "Admin Updated Successfully"
        }
        props.closeModal()
        window.setTimeout(function(){window.location.reload(true)}, 300);    
        window.setTimeout(toast.success(popupData.message), 500);
        // history.push({pathname: "/superadmin/details",state:{'role':'manager'}});
      }
      else {
        const popupData =  {
          title: "Update Manager",
          message: result.data.ResponseMessage
        }
          toast.dismiss()
          toast.warn(popupData.message)
      }
    }
  }


    //   const updateAdmin = async(data) =>{
    //      console.log(data)
    //     if(Object.keys(data).length === 0 && data.constructor === Object){
    //      props.closeModal()
    //    }
    //    else{
    //     let result = await AdminUpdate(props.AdminId,data)
    //     console.log("else",result)
    //     if(result.data.ResponseData != null ){
    //       console.log("else if",result.data.ResponseData)
    //       setTimeout(toast.success("Admin Updated Successfully"),20000)
    //       props.closeModal()
    //       window.location.reload()
    //     //  history.push({pathname: "/superadmin/details",state:{'role':'admin'}});
    //     }
    //     else {
    //       console.log("44%%")
    //       const popupData =  {
    //         title: "Update Admin",
    //         message: result.data.ResponseMessage
    //       }
    //       toast.dismiss()
    //       setTimeout(toast.error(popupData.message),20000)
    //     }
    //   }
    // }


      function handleImageChange(e){
        // e.preventDefault();    
        let reader = new FileReader();
        let file = e.target.files[0];
        setProfilePhotoName(e.target.files[0].name)
        reader.onloadend = () => {
          setFiles(reader.result)
        }
        reader.readAsDataURL(file)
      }

      const onSubmit =async values => {  
        console.log(values.name)
        let datas = {
          "phone_number":  values.number ? values.number : initialValues.number  ,
          "email":  values.email ? values.email.toLowerCase() : initialValues.email.toLowerCase() ,
          "full_name":  values.name ? values.name : initialValues.name ,
          "address":  values.address ? values.address : initialValues.address
        }
        if(fileImage!==null&&fileImage!==undefined&&fileImage!=="undefined"){
          datas = {
            "phone_number":  values.number ? values.number : initialValues.number  ,
            "email":  values.email ? values.email.toLowerCase() : initialValues.email.toLowerCase() ,
            "full_name":  values.name ? values.name : initialValues.name ,
            "address":  values.address ? values.address : initialValues.address,
            "profile_image": fileImage,
            "profile_image_filename": profilePhotoName,
          }
        }
        // eslint-disable-next-line
        {props.onClick1==="admin" ? addAdmin(datas): 
         props.onClick1==="updateadmin" ? updateAdmin(datas) : 
         props.onClick1==="updatemanager" ? updateManager(datas):
         addManager(datas)}

      }

    return (
      <div>
         {/* <ToastContainer/>       */}
        <Modal
        isOpen={props.show}
        className="adminModal"
        overlayClassName="EditOverlay"
      >
         <div className="admin-header">
          <span className="admin-title">{props.Heading}</span>
          <i className="fa fa-close" aria-hidden="true" onClick={props.closeModal}></i>
        </div>  

        <div className='admin-details'>
        <div style={{textAlign:"center"}}>
                    <img src={ props.data&&fileImage===null ? props.data.profile_image: fileImage!=null ?fileImage : photo} style={{width:125 ,height:125}} className='passport' alt='' />
                    <div style={{marginTop:20}}>
                    <label className='upload'><input  type="file" name="file" id="file" onChange={e=>handleImageChange(e)}></input>{translate('chooseImage')}</label>
                    </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit} >
         {({errors,touched}) => {
                    return <Form className='form'  >

              <div className={touched.name && errors.name ? "input-field field-line" : "input-field "}>
                  <Field  autoComplete="off" className={touched.name && errors.name ? "input line" : "input "}
                    type='text'
                    id="name"
                    name='name'
                    placeholder={translate('fullNamePlaceholder')}
                    
                  />
                  <ErrorMessage name='name' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
              <div className={touched.email && errors.email ? "input-field field-line" : "input-field "}>
                  <Field autoComplete="off" className={touched.email && errors.email ? "input line" : "input "}
                    type='text'
                    id="email"
                    name='email'
                    placeholder={translate('emailPlaceholder')}
                  />
                  <ErrorMessage name='email' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
              <div className={touched.number && errors.number ? "input-field field-line" : "input-field "}>
                  <Field autoComplete="off" className={touched.number && errors.number ? "input line" : "input "}
                    type='tel'
                    id="number"
                    name='number'
                    placeholder={translate('phoneNumberPlaceholder')}
                  />
                  <ErrorMessage name='number' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div>
             { props.adressfield ? <div className={touched.address && errors.address ? "input-field field-line" : "input-field "}>
                  <Field autoComplete="off"
                    className={touched.address && errors.address ? "input line" : "input "}
                    type='text'
                    id="address"
                    name='address'
                    placeholder={translate('currentAddressPlaceholder')} />
                  <ErrorMessage name='address' >
                    {
                      (err) => <div className='err'>{err}</div>
                    }
                  </ErrorMessage>
              </div> :''}
              <div className='form-button'>
                <button className='save' type="submit">{translate('save')} </button>
              </div>
            </Form>
          }}
        </Formik>
        </div>   
        </Modal>
        </div>
    );
};


export default SuperAdminForm;